import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import ReceiptSubmissionPopup from '../components/ReceiptSubmissionPopup';
import './PCardReceipts.css';

function PCardReceipts() {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    fetchPCardReceipts();
  }, [user]);

  const fetchPCardReceipts = async () => {
    if (!user || !user.productionId) return;

    setLoading(true);
    try {
      const receiptsRef = collection(db, 'receipts');
      const q = query(
        receiptsRef, 
        where('productionId', '==', user.productionId),
        where('type', '==', 'P-Card')
      );
      const querySnapshot = await getDocs(q);
      
      const receiptData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setReceipts(receiptData);
    } catch (err) {
      console.error("Error fetching P-Card receipts:", err);
      setError("Failed to load P-Card receipts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitReceipt = async (receiptData) => {
    // Implement the logic to submit a new receipt
    console.log("Submitting P-Card receipt:", receiptData);
    // After submission, refresh the receipts list
    await fetchPCardReceipts();
  };

  if (loading) return <div>Loading P-Card receipts...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="pcard-receipts">
      <h2>P-Card Receipts</h2>
      <button onClick={() => setShowSubmitPopup(true)}>Submit New P-Card Receipt</button>
      {receipts.length === 0 ? (
        <p>No P-Card receipts submitted yet.</p>
      ) : (
        <div className="receipt-list">
          {receipts.map(receipt => (
            <div key={receipt.id} className="receipt-item">
              <img src={receipt.imageUrl} alt="Receipt" className="receipt-image" />
              <p><strong>Description:</strong> {receipt.description}</p>
              <p><strong>Submitted by:</strong> {receipt.submittedBy}</p>
              <p><strong>Date:</strong> {receipt.submissionDate.toDate().toLocaleString()}</p>
            </div>
          ))}
        </div>
      )}
      {showSubmitPopup && (
        <ReceiptSubmissionPopup
          type="P-Card"
          onClose={() => setShowSubmitPopup(false)}
          onSubmit={handleSubmitReceipt}
        />
      )}
    </div>
  );
}

export default PCardReceipts;
