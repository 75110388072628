import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import './ReceiptViewer.css';
import Sidebar from '../components/Sidebar';
import '../styles/SharedStyles.css';
import Toast from '../components/Toast';

function ReceiptViewer() {
  const [receipts, setReceipts] = useState({
    fuel: [],
    meal: [],
    pcard: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setLoading(false);
      setError('Please log in to view receipts.');
      return;
    }

    if (user.role !== 'supervisor' && user.role !== 'Supervisor') {
      setLoading(false);
      setError('Only supervisors can view department receipts.');
      return;
    }

    fetchReceipts();
  }, [user]);

  const fetchReceipts = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Current user:', user);
      console.log('User department:', user.department);
      console.log('User production:', user.production);

      // Get the latest user data to ensure we have current info
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      console.log('Latest user data:', userData);

      // Query receipts for the specific department and production
      const receiptsRef = collection(db, 'receipts');
      const receiptsQuery = query(
        receiptsRef,
        where('department', '==', userData.department),
        where('production', '==', userData.production)
      );

      console.log('Fetching receipts with query:', {
        department: userData.department,
        production: userData.production
      });

      const receiptsSnapshot = await getDocs(receiptsQuery);
      console.log('Total receipts found:', receiptsSnapshot.size);
      
      // Log each receipt for debugging
      receiptsSnapshot.docs.forEach(doc => {
        console.log('Receipt:', {
          id: doc.id,
          ...doc.data()
        });
      });

      // Process receipts and organize by type
      const processedReceipts = {
        fuel: [],
        meal: [],
        pcard: []
      };

      for (const doc of receiptsSnapshot.docs) {
        const receiptData = doc.data();
        console.log('Processing receipt:', receiptData);

        const receipt = {
          id: doc.id,
          ...receiptData,
          submitterName: receiptData.submitterName || 'Unknown User'
        };

        // Sort receipt by type (case-insensitive)
        const receiptType = receipt.type?.toLowerCase() || '';
        console.log('Receipt type:', receiptType);

        switch (receiptType) {
          case 'fuel':
            processedReceipts.fuel.push(receipt);
            break;
          case 'meal':
            processedReceipts.meal.push(receipt);
            break;
          case 'pcard':
          case 'p-card':
          case 'p card':
            console.log('Adding PCard receipt:', receipt);
            processedReceipts.pcard.push(receipt);
            break;
          default:
            console.log('Unknown receipt type:', receipt.type);
            break;
        }
      }

      console.log('Final processed receipts:', processedReceipts);
      setReceipts(processedReceipts);
      setLoading(false);
      setError(null);

    } catch (err) {
      console.error("Error fetching receipts:", err);
      setError("No receipts found for your department.");
      setLoading(false);
    }
  };

  const ReceiptSection = ({ title, receipts }) => (
    <section className="receipt-section">
      <h2>{title}</h2>
      {receipts.length === 0 ? (
        <p className="no-receipts-message">No {title.toLowerCase()} found.</p>
      ) : (
        <div className="receipt-grid">
          {receipts.map(receipt => (
            <div key={receipt.id} className="receipt-card">
              <div className="receipt-header">
                <h3>{receipt.type} Receipt</h3>
                <span className="receipt-date">
                  {receipt.submissionDate.toDate().toLocaleDateString()}
                </span>
              </div>
              <img src={receipt.imageUrl} alt="Receipt" className="receipt-image" />
              <div className="receipt-details">
                <p><strong>Submitted By:</strong> {receipt.submitterName}</p>
                <p><strong>Description:</strong> {receipt.description}</p>
                <p><strong>Amount:</strong> ${receipt.amount || 'N/A'}</p>
                <p><strong>Status:</strong> {receipt.status || 'Pending'}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );

  return (
    <div className="page-container">
      <Sidebar />
      <div className="receipt-viewer">
        <header className="page-header">
          <h1>Receipts</h1>
          <p>View and manage department receipts</p>
        </header>

        {loading && <div className="loading-message">Loading receipts...</div>}
        {error && <div className="error-message">{error}</div>}
        
        {!loading && !error && (
          <div className="receipt-sections">
            <ReceiptSection title="Fuel Receipts" receipts={receipts.fuel} />
            <ReceiptSection title="Meal Receipts" receipts={receipts.meal} />
            <ReceiptSection title="P-Card Receipts" receipts={receipts.pcard} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReceiptViewer;
