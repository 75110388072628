import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './Navbar.css';
import logo from '../Assets/Slate-Logo.png';

function Navbar({ toggleSidebar }) {
  const { user } = useContext(AuthContext);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-left">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="Slate Fusion Logo" />
          </Link>
          <h1 className="navbar-title">Slate Fusion</h1>
        </div>
        <div className="navbar-right">
          {user && (
            <button className="hamburger-menu" onClick={toggleSidebar} aria-label="Toggle menu">
              ☰
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;