import React, { useState, useEffect, useContext, useMemo } from 'react';
import { collection, addDoc, getDocs, query, where, Timestamp, serverTimestamp, updateDoc, doc, orderBy, getDoc, writeBatch } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Dispatch.css';
import '../styles/SharedStyles.css';
import { AuthContext } from '../contexts/AuthContext';
import Toast from '../components/Toast';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAccessControl } from '../hooks/useAccessControl';
import RunRequests from '../components/RunRequests';

function Dispatch() {
  const { user } = useContext(AuthContext);
  const { hasAccess, isLoading: accessLoading, error: accessError, productionAccess, departmentAccess } = useAccessControl();
  const [isLoading, setIsLoading] = useState(true);
  const [newEntry, setNewEntry] = useState({
    crewMember: '',
    callTime: '',
    wrapTime: '',
    date: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('crew');
  const [selectedCrewMembers, setSelectedCrewMembers] = useState([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [assignedTimes, setAssignedTimes] = useState([]);
  const [activeTab, setActiveTab] = useState('dispatchTime');
  const [dispatchedRuns, setDispatchedRuns] = useState([]);
  const [runRequests, setRunRequests] = useState([]);
  const [runRequestsLoading, setRunRequestsLoading] = useState(true);
  const [runRequestsError, setRunRequestsError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newRun, setNewRun] = useState({
    title: '',
    date: new Date(),
    status: 'Pending',
    runType: 'pickup',
    address: '',
    itemDescription: '',
    picture: null,
    department: departmentAccess,
    production: productionAccess
  });
  const [error, setError] = useState(null);
  const [unassignedCrewMembers, setUnassignedCrewMembers] = useState([]);
  const [assignmentConfirmation, setAssignmentConfirmation] = useState(null);
  const [selectedCrewMembersForRun, setSelectedCrewMembersForRun] = useState([]);
  const [futureRuns, setFutureRuns] = useState([]);
  const [toast, setToast] = useState(null);
  const [expandedRun, setExpandedRun] = useState(null);
  const [selectedRunRequest, setSelectedRunRequest] = useState(null);
  const [assignmentStatus, setAssignmentStatus] = useState({});

  useEffect(() => {
    if (user) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchCrewMembers(),
        fetchAssignedTimes(),
        fetchDispatchedRuns(),
        checkUnassignedCrewMembers()
      ]);
    } catch (error) {
      handleFirestoreError(error, 'fetching data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFirestoreError = (error, operation) => {
    console.error(`Error during ${operation}:`, error);
    if (error.code === 'permission-denied') {
      setError(`You don't have permission to ${operation}. Please contact an administrator.`);
    } else {
      setError(`An error occurred while ${operation}. Please try again.`);
    }
  };

  useEffect(() => {
    filterCrewMembers();
  }, [searchTerm, crewMembers]);

  const getWeekDates = (date) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(date);
      day.setDate(date.getDate() - date.getDay() + i);
      week.push(day);
    }
    return week;
  };

  const fetchCrewMembers = async () => {
    if (!user) {
      setCrewMembers([]);
      setSupervisors([]);
      return;
    }

    try {
      console.log('Fetching crew members and supervisors...'); // Debug log

      // First get the current user's data to ensure we have production and department
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      if (!userData?.production) {
        console.error('No production found for user');
        return;
      }

      const usersCollection = collection(db, 'users');
      let usersQuery;

      // Check for both lowercase and uppercase role variations
      const isProductionAdmin = userData.role?.toLowerCase().includes('admin');
      const isSupervisor = userData.role?.toLowerCase().includes('supervisor');

      // For production admins and supervisors
      if (isProductionAdmin) {
        // Production admins can see all crew members and supervisors in their production
        usersQuery = query(usersCollection,
          where('production', '==', userData.production)
        );
      } else if (isSupervisor || userData.department) {
        // Supervisors or users with department access can see crew members in their department and production
        usersQuery = query(usersCollection,
          where('production', '==', userData.production),
          where('department', '==', userData.department)
        );
      } else {
        // For other roles with production access, show all crew members in their production
        usersQuery = query(usersCollection,
          where('production', '==', userData.production)
        );
      }

      const querySnapshot = await getDocs(usersQuery);
      const allUsers = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Split users into crew members and supervisors
      const crewList = allUsers.filter(user => 
        user.role?.toLowerCase().includes('crew') || 
        user.role?.toLowerCase().includes('member')
      );
      
      const supervisorList = allUsers.filter(user => 
        user.role?.toLowerCase().includes('supervisor')
      );

      console.log('Fetched crew members:', crewList.length);
      console.log('Fetched supervisors:', supervisorList.length);

      setCrewMembers(crewList);
      setSupervisors(supervisorList);
    } catch (error) {
      console.error('Error fetching crew members:', error);
      handleFirestoreError(error, 'fetching crew members');
    }
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const handleMemberSelect = (memberId, mode) => {
    if (mode === 'crew') {
      setSelectedCrewMembers(prev => {
        if (prev.includes(memberId)) {
          return prev.filter(id => id !== memberId);
        }
        return [...prev, memberId];
      });
    } else {
      setSelectedSupervisors(prev => {
        if (prev.includes(memberId)) {
          return prev.filter(id => id !== memberId);
        }
        return [...prev, memberId];
      });
    }
  };

  const handleSelectAllMembers = (mode) => {
    if (mode === 'crew') {
      const allIds = filteredCrewMembers.map(member => member.id);
      setSelectedCrewMembers(allIds);
    } else {
      const allIds = filteredSupervisors.map(supervisor => supervisor.id);
      setSelectedSupervisors(allIds);
    }
  };

  const handleDeselectAllMembers = (mode) => {
    if (mode === 'crew') {
      setSelectedCrewMembers([]);
    } else {
      setSelectedSupervisors([]);
    }
  };

  const handleSelectAllForRun = () => {
    // Select all crew members from the production
    const allIds = crewMembers.filter(member => 
      member.production === productionAccess && 
      (departmentAccess === 'all' || member.department === departmentAccess)
    ).map(member => member.id);
    setSelectedCrewMembersForRun(allIds);
  };

  const handleDeselectAllForRun = () => {
    setSelectedCrewMembersForRun([]);
  };

  const fetchAssignedTimes = async () => {
    if (!user) {
      console.log('No user found');
      return;
    }

    // Get current user's data
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const userData = userDoc.data();

    if (!userData?.production) {
      console.log('No production found for user');
      return;
    }

    // Create UTC date range for the selected date
    const startOfDay = new Date(Date.UTC(
      selectedDate.getUTCFullYear(),
      selectedDate.getUTCMonth(),
      selectedDate.getUTCDate(),
      0, 0, 0, 0
    ));
    const endOfDay = new Date(Date.UTC(
      selectedDate.getUTCFullYear(),
      selectedDate.getUTCMonth(),
      selectedDate.getUTCDate(),
      23, 59, 59, 999
    ));

    try {
      console.log('Fetching time entries for:', startOfDay, 'to', endOfDay);
      console.log('Production:', userData.production, 'Department:', userData.department);
      
      // Build the query conditions array
      const queryConditions = [
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay)),
        where('production', '==', userData.production)
      ];

      // Add department condition if it exists
      if (userData.department) {
        queryConditions.push(where('department', '==', userData.department));
      }

      // Create the query with all conditions
      const timeEntriesQuery = query(
        collection(db, 'timeEntries'),
        ...queryConditions
      );

      const querySnapshot = await getDocs(timeEntriesQuery);
      const times = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          userId: data.userId,
          startTime: data.callTime || data.startTime || null,
          endTime: data.wrapTime || data.endTime || null,
          date: data.date.toDate(),
          production: data.production,
          department: data.department
        };
      });

      console.log('Fetched time entries:', times);
      
      // Get crew member names
      const userIds = times.map(time => time.userId).filter(id => id);
      const usersQuery = query(
        collection(db, 'users'),
        where('production', '==', userData.production)
      );
      
      const usersSnapshot = await getDocs(usersQuery);
      const userMap = {};
      usersSnapshot.docs.forEach(doc => {
        userMap[doc.id] = doc.data().name;
      });

      // Add crew member names to time entries
      const timesWithNames = times.map(time => ({
        ...time,
        crewMemberName: userMap[time.userId] || 'Unknown'
      }));

      console.log('Time entries with names:', timesWithNames);
      setAssignedTimes(timesWithNames);
    } catch (error) {
      console.error('Error fetching time entries:', error);
      setError('An error occurred while fetching time entries. Please try again.');
    }
  };

  const groupAssignedTimes = () => {
    const grouped = {};
    assignedTimes.forEach(time => {
      if (!grouped[time.crewMember]) {
        grouped[time.crewMember] = time;
      }
    });
    return grouped;
  };

  const fetchDispatchedRuns = async () => {
    try {
      // Get crew members first
      const crewQuery = query(
        collection(db, 'users'),
        where('department', '==', user.department),
        where('production', '==', user.production)
      );
      const crewSnapshot = await getDocs(crewQuery);
      const crewList = crewSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Calculate date range for the selected date
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      // Query dispatched runs for the selected date - simplified to match fetchAssignedTimes
      const runsQuery = query(
        collection(db, 'dispatchedRuns'),
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay))
      );

      const querySnapshot = await getDocs(runsQuery);
      const runs = querySnapshot.docs
        .map(doc => {
          const data = doc.data();
          // Only include runs for current department and production
          if (data.department === user.department && data.production === user.production) {
            return {
              id: doc.id,
              ...data,
              dateObj: data.date?.toDate() || new Date(),
              assignedToName: crewList.find(cm => cm.id === data.assignedTo)?.name || 'Not assigned'
            };
          }
          return null;
        })
        .filter(run => run !== null); // Remove any null entries

      console.log('Fetched runs:', runs);
      setDispatchedRuns(runs);

      // Fetch future runs - also simplified
      const tomorrow = new Date(startOfDay);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const futureRunsQuery = query(
        collection(db, 'dispatchedRuns'),
        where('date', '>', Timestamp.fromDate(tomorrow))
      );

      const futureSnapshot = await getDocs(futureRunsQuery);
      const futureRuns = futureSnapshot.docs
        .map(doc => {
          const data = doc.data();
          // Only include runs for current department and production
          if (data.department === user.department && data.production === user.production) {
            return {
              id: doc.id,
              ...data,
              dateObj: data.date?.toDate() || new Date(),
              assignedToName: crewList.find(cm => cm.id === data.assignedTo)?.name || 'Unknown'
            };
          }
          return null;
        })
        .filter(run => run !== null) // Remove any null entries
        .sort((a, b) => a.dateObj - b.dateObj);

      setFutureRuns(futureRuns);

    } catch (error) {
      console.error('Error fetching dispatched runs:', error);
      setError('An error occurred while fetching dispatched runs. Please try again.');
    }
  };

  const filterCrewMembers = () => {
    let filtered = crewMembers;
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(member => 
        member.name.toLowerCase().includes(lowerSearchTerm) ||
        (member.position && member.position.toLowerCase().includes(lowerSearchTerm))
      );
    }
    // Removed setFilteredCrewMembers
  };

  const filteredCrewMembers = useMemo(() => {
    return crewMembers.filter(member => {
      const searchLower = searchTerm.toLowerCase();
      return (
        member.name?.toLowerCase().includes(searchLower) ||
        member.position?.toLowerCase().includes(searchLower) ||
        member.department?.toLowerCase().includes(searchLower)
      );
    });
  }, [crewMembers, searchTerm]);

  const filteredSupervisors = useMemo(() => {
    return supervisors.filter(supervisor => {
      const searchLower = searchTerm.toLowerCase();
      return (
        supervisor.name?.toLowerCase().includes(searchLower) ||
        supervisor.position?.toLowerCase().includes(searchLower) ||
        supervisor.department?.toLowerCase().includes(searchLower)
      );
    });
  }, [supervisors, searchTerm]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handleSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const handleCrewMemberCheckbox = (crewMemberId) => {
    setSelectedCrewMembers(prev => {
      if (prev.includes(crewMemberId)) {
        return prev.filter(id => id !== crewMemberId);
      } else {
        return [...prev, crewMemberId];
      }
    });
  };

  const checkUnassignedCrewMembers = () => {
    // Get the appropriate list based on viewMode
    const membersToCheck = viewMode === 'crew' ? crewMembers : supervisors;
    
    // Filter members who don't have a time entry for the selected date
    const unassigned = membersToCheck.filter(member => {
      const hasTimeEntry = assignedTimes.some(time => 
        // Check if this time entry belongs to this member
        time.userId === member.id &&
        // Check if they have a call time or wrap time
        (time.startTime || time.endTime)
      );
      
      // Return true for members who don't have a time entry
      return !hasTimeEntry;
    });

    console.log(`Checking unassigned ${viewMode}:`, {
      totalMembers: membersToCheck.length,
      assignedTimes: assignedTimes.length,
      unassignedCount: unassigned.length
    });

    setUnassignedCrewMembers(unassigned);
  };

  useEffect(() => {
    // Re-check unassigned members when viewMode changes
    if (assignedTimes.length > 0 && (crewMembers.length > 0 || supervisors.length > 0)) {
      checkUnassignedCrewMembers();
    }
  }, [assignedTimes, crewMembers, supervisors, viewMode]);

  const handleDateChange = (date) => {
    // Ensure the date is set to noon UTC
    const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 0, 0));
    setSelectedDate(utcDate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setAssignmentConfirmation(null);
    
    console.log('Dispatch.js - Submit button clicked');
    console.log('Dispatch.js - Selected Date:', selectedDate);
    console.log('Dispatch.js - New Entry:', newEntry);
    console.log('Dispatch.js - Selected Crew Members:', selectedCrewMembers);
    console.log('Dispatch.js - Selected Supervisors:', selectedSupervisors);

    const selectedMembers = [...selectedCrewMembers, ...selectedSupervisors];

    if (selectedMembers.length === 0 || (!newEntry.callTime && !newEntry.wrapTime)) {
      setError('Please select at least one crew member or supervisor and provide either a call time or a wrap time');
      return;
    }

    try {
      // Get current user's data for production and department
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      if (!userData?.production) {
        setError('No production found for user');
        return;
      }

      for (const memberId of selectedMembers) {
        // Get crew member's data for their department
        const crewDoc = await getDoc(doc(db, 'users', memberId));
        const crewData = crewDoc.data();

        // Create a new Date object set to 7am UTC on the selected date
        const entryDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 7, 0, 0));

        const timeEntryData = {
          userId: memberId,
          date: Timestamp.fromDate(entryDate),
          createdAt: serverTimestamp(),
          createdBy: user.uid,
          status: 'Pending',
          production: userData.production,
          department: crewData.department,
          log: {
            selectedDate: selectedDate.toISOString().split('T')[0],
            clientTime: new Date().toISOString()
          }
        };

        if (newEntry.callTime !== undefined && newEntry.callTime !== '') {
          timeEntryData.startTime = parseFloat(newEntry.callTime);
        }

        if (newEntry.wrapTime !== undefined && newEntry.wrapTime !== '') {
          timeEntryData.endTime = parseFloat(newEntry.wrapTime);
        }

        console.log('Dispatch.js - Time Entry Data:', timeEntryData);

        // Check if an entry already exists for this user and date
        const existingEntryQuery = query(
          collection(db, 'timeEntries'),
          where('userId', '==', memberId),
          where('date', '==', timeEntryData.date)
        );
        const existingEntrySnapshot = await getDocs(existingEntryQuery);

        if (!existingEntrySnapshot.empty) {
          // Update existing entry
          const existingEntryDoc = existingEntrySnapshot.docs[0];
          await updateDoc(doc(db, 'timeEntries', existingEntryDoc.id), timeEntryData);
        } else {
          // Add new entry
          await addDoc(collection(db, 'timeEntries'), timeEntryData);
        }
      }

      console.log('Dispatch.js - All time entries updated successfully');
      setNewEntry({
        callTime: '',
        wrapTime: '',
        date: ''
      });
      setSelectedCrewMembers([]);
      setSelectedSupervisors([]);
      await fetchAssignedTimes();
      checkUnassignedCrewMembers();
      setAssignmentConfirmation('Times assigned successfully!');
      setTimeout(() => setAssignmentConfirmation(null), 3000);
    } catch (error) {
      console.error('Dispatch.js - Detailed error:', error);
      setError(`An error occurred while assigning times: ${error.message}. Please try again.`);
    }
  };

  const handleNewRunChange = (e) => {
    const { name, value } = e.target;
    setNewRun(prev => ({ ...prev, [name]: value }));
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    setNewRun(prev => ({ ...prev, picture: file }));
  };

  const handleCrewMemberCheckboxForRun = (crewMemberId) => {
    setSelectedCrewMembersForRun(prev => {
      if (prev.includes(crewMemberId)) {
        return prev.filter(id => id !== crewMemberId);
      } else {
        return [...prev, crewMemberId];
      }
    });
  };

  const handleDispatchRun = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate required fields
    if (!newRun.title) {
      setToast({ message: 'Please enter a run title', type: 'error' });
      return;
    }
    if (!newRun.date) {
      setToast({ message: 'Please select a date', type: 'error' });
      return;
    }
    if (selectedCrewMembersForRun.length === 0) {
      setToast({ message: 'Please select at least one crew member', type: 'error' });
      return;
    }
    if (!newRun.address) {
      setToast({ message: 'Please enter an address', type: 'error' });
      return;
    }
    if (!newRun.itemDescription) {
      setToast({ message: 'Please enter an item description', type: 'error' });
      return;
    }

    try {
      console.log('Starting dispatch run process with data:', {
        ...newRun,
        selectedCrewMembers: selectedCrewMembersForRun,
        department: departmentAccess,
        production: productionAccess
      });
      
      // Handle image upload if a picture is selected
      let pictureUrl = null;
      if (newRun.picture) {
        try {
          const storage = getStorage();
          const storageRef = ref(storage, `runs/${Date.now()}_${newRun.picture.name}`);
          await uploadBytes(storageRef, newRun.picture);
          pictureUrl = await getDownloadURL(storageRef);
        } catch (uploadError) {
          console.error('Image upload error:', uploadError);
          setToast({ message: `Image upload failed: ${uploadError.message}`, type: 'error' });
          return;
        }
      }

      // Create the run data
      const baseRunData = {
        title: newRun.title,
        date: Timestamp.fromDate(new Date(newRun.date)),
        status: 'Pending',
        runType: newRun.runType,
        address: newRun.address,
        itemDescription: newRun.itemDescription,
        pictureUrl,
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        production: productionAccess,
        department: departmentAccess === 'all' ? newRun.department : departmentAccess,
        assignedAt: serverTimestamp()
      };

      // Save run for each selected crew member
      const savedRuns = await Promise.all(
        selectedCrewMembersForRun.map(async (crewMemberId) => {
          const runData = {
            ...baseRunData,
            assignedTo: crewMemberId
          };
          
          console.log('Saving run for crew member:', {
            crewMemberId,
            runData
          });

          const docRef = await addDoc(collection(db, 'dispatchedRuns'), runData);
          return docRef.id;
        })
      );

      console.log('Successfully created runs:', savedRuns);

      // Reset form
      setNewRun({
        title: '',
        date: new Date(),
        status: 'Pending',
        runType: 'pickup',
        address: '',
        itemDescription: '',
        picture: null,
        department: departmentAccess,
        production: productionAccess
      });
      setSelectedCrewMembersForRun([]);
      
      // Refresh the runs list
      await fetchAllRuns();
      
      setToast({ 
        message: `Successfully dispatched ${savedRuns.length} run(s)!`, 
        type: 'success' 
      });
    } catch (error) {
      console.error('Dispatch run error:', error);
      setToast({ 
        message: `Failed to dispatch run: ${error.message}`, 
        type: 'error' 
      });
    }
  };

  const fetchDispatchData = async () => {
    try {
      const dispatchCollection = collection(db, 'dispatches');
      const snapshot = await getDocs(dispatchCollection);
      const dispatchData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Set state with dispatchData
    } catch (error) {
      if (error.code === 'permission-denied') {
        console.error('Permission denied:', error.message);
        // Handle the permission error (e.g., show a message to the user)
        setError('You do not have permission to access dispatch data.');
      } else {
        console.error('Firestore error:', error);
        // Handle other types of errors
        setError('An error occurred while fetching dispatch data. Please try again.');
      }
    }
  };

  const handleAddDispatch = async (dispatchData) => {
    try {
      const dispatchCollection = collection(db, 'dispatches');
      await addDoc(dispatchCollection, dispatchData);
      // Update state or refetch data
    } catch (error) {
      if (error.code === 'permission-denied') {
        console.error('Permission denied:', error.message);
        // Handle the permission error (e.g., show a message to the user)
        setError('You do not have permission to add dispatch data.');
      } else {
        console.error('Firestore error:', error);
        // Handle other types of errors
        setError('An error occurred while adding dispatch data. Please try again.');
      }
    }
  };

  const handleUpdateDispatch = async (dispatchId, updatedData) => {
    try {
      const dispatchDoc = doc(db, 'dispatches', dispatchId);
      await updateDoc(dispatchDoc, updatedData);
      // Update state or refetch data
    } catch (error) {
      if (error.code === 'permission-denied') {
        console.error('Permission denied:', error.message);
        // Handle the permission error (e.g., show a message to the user)
        setError('You do not have permission to update dispatch data.');
      } else {
        console.error('Firestore error:', error);
        // Handle other types of errors
        setError('An error occurred while updating dispatch data. Please try again.');
      }
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) return 'N/A';
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    let numericValue = parseFloat(value);

    if (isNaN(numericValue)) return;

    if (name === 'callTime') {
      if (numericValue === -1) {
        setNewEntry(prev => ({ ...prev, callTime: -1, wrapTime: -2 }));
        return;
      }
      numericValue = Math.max(0, Math.min(23.5, numericValue));
    } else if (name === 'wrapTime') {
      numericValue = Math.max(0, Math.min(48, numericValue));
    }

    // Round to nearest 0.5
    numericValue = Math.round(numericValue * 2) / 2;

    setNewEntry(prev => ({ ...prev, [name]: numericValue }));
  };

  const getDisplayTime = (time, isWrapTime = false) => {
    if (time === null || time === undefined) return '';
    
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    
    if (isWrapTime && time >= 24) {
      return `${formattedTime} (Next Day)`;
    }
    return formattedTime;
  };

  useEffect(() => {
    if (user) {
      fetchAssignedTimes();
    }
  }, [user, selectedDate]);

  useEffect(() => {
    console.log('Updated assignedTimes:', assignedTimes);
  }, [assignedTimes]);

  const handleNewRunDateChange = (date) => {
    setNewRun(prev => ({ ...prev, date: date }));
  };

  const handleRunClick = (runId) => {
    if (expandedRun === runId) {
      setExpandedRun(null); // Collapse if already expanded
    } else {
      setExpandedRun(runId); // Expand the clicked notification
    }
  };

  const fetchAllRuns = async () => {
    try {
      console.log('Fetching runs with:', {
        department: departmentAccess,
        production: productionAccess,
        selectedDate: selectedDate
      });

      // Get crew members first
      const crewQuery = query(
        collection(db, 'users'),
        where('department', '==', departmentAccess),
        where('production', '==', productionAccess)
      );
      const crewSnapshot = await getDocs(crewQuery);
      const crewList = crewSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Fetched crew list:', crewList);

      // Query for dispatched runs
      const runsQuery = query(
        collection(db, 'dispatchedRuns'),
        where('department', '==', departmentAccess),
        where('production', '==', productionAccess),
        orderBy('date', 'desc')
      );
      
      const querySnapshot = await getDocs(runsQuery);
      const allRuns = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const runDate = data.date?.toDate() || new Date();
        console.log('Processing run:', {
          id: doc.id,
          date: runDate,
          title: data.title,
          assignedTo: data.assignedTo
        });
        
        return {
          id: doc.id,
          ...data,
          dateObj: runDate,
          assignedToName: crewList.find(cm => cm.id === data.assignedTo)?.name || 'Unknown'
        };
      });

      console.log('All fetched runs:', allRuns);

      // Filter for current day runs
      const today = new Date(selectedDate);
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const currentDayRuns = allRuns.filter(run => {
        const runDate = new Date(run.dateObj);
        runDate.setHours(0, 0, 0, 0);
        const isCurrentDay = runDate.getTime() === today.getTime();
        console.log('Checking run for current day:', {
          runDate,
          today,
          isCurrentDay,
          runTitle: run.title
        });
        return isCurrentDay;
      }).sort((a, b) => b.dateObj - a.dateObj);

      console.log('Filtered current day runs:', currentDayRuns);

      const futureRunsList = allRuns
        .filter(run => {
          const runDate = new Date(run.dateObj);
          return runDate > tomorrow;
        })
        .sort((a, b) => a.dateObj - b.dateObj);

      console.log('Future runs:', futureRunsList);

      setDispatchedRuns(currentDayRuns);
      setFutureRuns(futureRunsList);

    } catch (error) {
      console.error('Fetch error:', error);
      handleFirestoreError(error, 'fetching runs');
    }
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if (user) {
      fetchAllRuns();
    }
  }, [selectedDate, user]);

  useEffect(() => {
    const fetchMembers = async () => {
      if (!user?.production) return;
      
      try {
        const crewQuery = query(
          collection(db, 'users'),
          where('production', '==', user.production),
          where('role', '==', 'crew')
        );
        const supervisorQuery = query(
          collection(db, 'users'),
          where('production', '==', user.production),
          where('role', '==', 'supervisor')
        );

        const [crewSnapshot, supervisorSnapshot] = await Promise.all([
          getDocs(crewQuery),
          getDocs(supervisorQuery)
        ]);

        setCrewMembers(crewSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setSupervisors(supervisorSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, [user?.production]);

  const handleRunRequestSelect = (request) => {
    setSelectedRunRequest(request);
    // Clear any previous crew selections when a new request is selected
    setSelectedCrewMembers([]);
    setSelectedSupervisors([]);
  };

  const fetchRunRequests = async () => {
    setRunRequestsLoading(true);
    try {
      if (!productionAccess) {
        console.log('No production access available');
        setRunRequestsError('No production selected');
        return;
      }

      console.log('Fetching run requests for production:', productionAccess);
      const runRequestsRef = collection(db, 'runRequests');
      const q = query(
        runRequestsRef,
        where('production', '==', productionAccess),
        where('status', '==', 'pending') // Only fetch unassigned (pending) requests
      );
      
      const querySnapshot = await getDocs(q);
      const requests = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      console.log('Fetched run requests:', requests);
      setRunRequests(requests);
      setRunRequestsError(null);
    } catch (error) {
      console.error('Error fetching run requests:', error);
      console.error('Error details:', {
        code: error.code,
        message: error.message,
        stack: error.stack
      });
      setRunRequestsError('Failed to fetch run requests: ' + error.message);
    } finally {
      setRunRequestsLoading(false);
    }
  };

  useEffect(() => {
    if (user && productionAccess) {
      fetchData();
      fetchRunRequests();
    } else {
      setIsLoading(false);
    }
  }, [user, productionAccess]);

  const handleStatusChange = async (requestId, newStatus) => {
    try {
      // Validate inputs
      if (!requestId || !selectedRunRequest) {
        console.error('Missing required data:', { requestId, selectedRunRequest });
        setToast({
          message: 'Missing required request data',
          type: 'error'
        });
        return;
      }

      const selectedMembers = [...selectedCrewMembers, ...selectedSupervisors];
      if (!selectedMembers || selectedMembers.length === 0) {
        console.error('No crew members selected');
        setToast({
          message: 'Please select at least one crew member',
          type: 'error'
        });
        return;
      }

      if (!user?.uid || !productionAccess || !departmentAccess) {
        console.error('Missing user context:', { 
          userId: user?.uid, 
          production: productionAccess, 
          department: departmentAccess 
        });
        setToast({
          message: 'Missing user context data',
          type: 'error'
        });
        return;
      }

      setAssignmentStatus(prev => ({ ...prev, [requestId]: 'processing' }));
      
      // Create a batch for all updates
      const batch = writeBatch(db);

      console.log('Updating run request:', requestId);
      const requestRef = doc(db, 'runRequests', requestId);
      const runRequestUpdate = {
        status: 'assigned',
        assignedTo: selectedMembers,
        assignedAt: serverTimestamp(),
        assignedBy: user.uid,
        notificationSent: true
      };
      console.log('Run request update data:', runRequestUpdate);
      batch.update(requestRef, runRequestUpdate);

      // Immediately remove the assigned request from the local state
      setRunRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));

      // Create dispatched run entries for each assigned member
      const dispatchedRunsRef = collection(db, 'dispatchedRuns');
      selectedMembers.forEach(memberId => {
        // Create base run data
        const dispatchedRunData = {
          title: selectedRunRequest.name || `${selectedRunRequest.requestType.toUpperCase()} Run`,
          date: selectedRunRequest.date ? Timestamp.fromDate(new Date(selectedRunRequest.date)) : serverTimestamp(),
          status: 'Pending',
          runType: selectedRunRequest.requestType,
          address: selectedRunRequest[selectedRunRequest.requestType]?.address ? 
            `${selectedRunRequest[selectedRunRequest.requestType].address.streetNumber} ${selectedRunRequest[selectedRunRequest.requestType].address.streetName}${selectedRunRequest[selectedRunRequest.requestType].address.suite ? ` Suite ${selectedRunRequest[selectedRunRequest.requestType].address.suite}` : ''}, ${selectedRunRequest[selectedRunRequest.requestType].address.city}, ${selectedRunRequest[selectedRunRequest.requestType].address.state} ${selectedRunRequest[selectedRunRequest.requestType].address.zipCode}` :
            '',
          itemDescription: selectedRunRequest.description || '',
          pictureUrl: selectedRunRequest.photoUrls?.[0] || null,
          createdAt: serverTimestamp(),
          createdBy: user.uid,
          production: productionAccess,
          department: departmentAccess,
          assignedTo: memberId,
          assignedAt: serverTimestamp(),
          originalRequestId: requestId,
          acknowledged: false
        };

        // Add dispatched run to batch
        const dispatchedRunRef = doc(dispatchedRunsRef);
        batch.set(dispatchedRunRef, dispatchedRunData);
      });

      // Create notifications for each assigned crew member
      const notificationsRef = collection(db, 'notifications');
      selectedMembers.forEach(memberId => {
        console.log('Creating notification for member:', memberId);
        
        const notificationData = {
          forUserId: memberId,
          type: 'runRequest',
          title: 'New Run Assignment',
          message: `You have been assigned a new run request`,
          runRequestId: requestId,
          createdAt: serverTimestamp(),
          createdBy: user.uid,
          read: false,
          acknowledged: false,
          status: 'unread',
          production: productionAccess,
          department: departmentAccess,
          metadata: {
            requestType: selectedRunRequest.requestType || 'pickup',
            date: selectedRunRequest.date ? Timestamp.fromDate(new Date(selectedRunRequest.date)) : serverTimestamp(),
            time: selectedRunRequest.time || '',
            requesterId: selectedRunRequest.createdBy,
            requestId: requestId,
            address: selectedRunRequest.address || '',
            description: selectedRunRequest.description || '',
            name: selectedRunRequest.name || ''
          }
        };

        console.log('Notification data:', notificationData);
        const notificationRef = doc(notificationsRef);
        batch.set(notificationRef, notificationData);
      });

      console.log('Committing batch with notifications and dispatched runs');
      
      await batch.commit();

      console.log('Batch committed successfully');

      setAssignmentStatus(prev => ({ ...prev, [requestId]: 'success' }));
      
      // Clear selections after successful assignment
      setSelectedCrewMembers([]);
      setSelectedSupervisors([]);
      setSelectedRunRequest(null);

      // Show success toast
      setToast({
        message: 'Run request assigned successfully',
        type: 'success'
      });

      // Refresh run requests to show updated status
      await fetchRunRequests();

      setTimeout(() => {
        setAssignmentStatus(prev => {
          const updated = { ...prev };
          delete updated[requestId];
          return updated;
        });
      }, 2000);

    } catch (error) {
      console.error('Error in handleStatusChange:', error);
      setAssignmentStatus(prev => ({ ...prev, [requestId]: 'error' }));
      setToast({
        message: 'Failed to assign run request: ' + error.message,
        type: 'error'
      });
    }
  };

  if (accessLoading) {
    return <div>Loading access permissions...</div>;
  }

  if (accessError) {
    return <div>Error: {accessError}</div>;
  }

  if (!hasAccess) {
    return <div>You don't have permission to view this page.</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <div className="dispatch-container">
      <Sidebar />
      <div className="dispatch-content">
        <div className="dispatch-tabs">
          <button
            className={activeTab === 'dispatchTime' ? 'active' : ''}
            onClick={() => setActiveTab('dispatchTime')}
          >
            Dispatch Time
          </button>
          <button
            className={activeTab === 'dispatchRun' ? 'active' : ''}
            onClick={() => setActiveTab('dispatchRun')}
          >
            Dispatch Run
          </button>
          <button
            className={activeTab === 'runRequests' ? 'active' : ''}
            onClick={() => setActiveTab('runRequests')}
          >
            Run Requests
          </button>
        </div>

        {activeTab === 'dispatchTime' && (
          <div className="dispatch-time-content">
            <section className="add-time-entry">
              <h2>Dispatch Times</h2>
              {error && <div className="error-message">{error}</div>}
              {assignmentConfirmation && <div className="assignment-confirmation">{assignmentConfirmation}</div>}
              <form onSubmit={handleSubmit}>
                <div className="time-entry-inputs">
                  <div className="date-time-group">
                    <div className="form-group">
                      <label htmlFor="date">Date:</label>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                    <div className="time-input-container">
                      <input
                        type="number"
                        id="callTime"
                        name="callTime"
                        value={newEntry.callTime || ''}
                        onChange={handleTimeChange}
                        min="0"
                        max="23.5"
                        step="0.5"
                        placeholder="Call Time (0-23.5)"
                      />
                      <span>{getDisplayTime(newEntry.callTime)}</span>
                    </div>
                  </div>
                  <div className="time-input-container">
                    <input
                      type="number"
                      id="wrapTime"
                      name="wrapTime"
                      value={newEntry.wrapTime || ''}
                      onChange={handleTimeChange}
                      min="0"
                      max="48"
                      step="0.5"
                      placeholder="Wrap Time (0-48)"
                    />
                    <span>{getDisplayTime(newEntry.wrapTime, true)}</span>
                  </div>
                </div>
                <button type="submit">Assign Time to Selected Crew Members</button>
              </form>
            </section>
            <section className="crew-selection">
              <div className="view-toggle" style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
                <button 
                  className={`toggle-btn ${viewMode === 'crew' ? 'active' : ''}`}
                  onClick={() => setViewMode('crew')}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: viewMode === 'crew' ? '#007bff' : '#f8f9fa',
                    color: viewMode === 'crew' ? 'white' : 'black',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Crew Members
                </button>
                <button 
                  className={`toggle-btn ${viewMode === 'supervisors' ? 'active' : ''}`}
                  onClick={() => setViewMode('supervisors')}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: viewMode === 'supervisors' ? '#007bff' : '#f8f9fa',
                    color: viewMode === 'supervisors' ? 'white' : 'black',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Supervisors
                </button>
              </div>

              {viewMode === 'crew' ? (
                <div className="crew-members-list">
                  <h3>Crew Members</h3>
                  <div className="crew-members-grid">
                    {filteredCrewMembers.map(member => (
                      <div 
                        key={member.id} 
                        className={`crew-member-tile ${selectedCrewMembers.includes(member.id) ? 'selected' : ''}`}
                        onClick={() => handleMemberSelect(member.id, 'crew')}
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedCrewMembers.includes(member.id)}
                          onChange={() => {}} // Empty onChange to avoid React warning
                          onClick={(e) => e.stopPropagation()} // Prevent double-triggering when clicking checkbox
                        />
                        <h4>{member.name}</h4>
                        <p>Position: {member.position || 'Not specified'}</p>
                        <p>Vehicle: {member.vehicle || 'Not assigned'}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="crew-members-list">
                  <h3>Supervisors</h3>
                  <div className="crew-members-grid">
                    {filteredSupervisors.map(supervisor => (
                      <div 
                        key={supervisor.id} 
                        className={`crew-member-tile ${selectedSupervisors.includes(supervisor.id) ? 'selected' : ''}`}
                        onClick={() => handleMemberSelect(supervisor.id, 'supervisors')}
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedSupervisors.includes(supervisor.id)}
                          onChange={() => {}} // Empty onChange to avoid React warning
                          onClick={(e) => e.stopPropagation()} // Prevent double-triggering when clicking checkbox
                        />
                        <h4>{supervisor.name}</h4>
                        <p>Position: {supervisor.position || 'Supervisor'}</p>
                        <p>Department: {supervisor.department || 'Not specified'}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </section>
            <section className="assigned-times">
              <h3>Assigned Times for {selectedDate.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</h3>
              <div className="date-navigation">
                <button onClick={() => handleDateChange(new Date(selectedDate.getTime() - 86400000))}>Previous Day</button>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                />
                <button onClick={() => handleDateChange(new Date(selectedDate.getTime() + 86400000))}>Next Day</button>
              </div>
              <div className="time-entries-table">
                <h3>Time Entries for {selectedDate.toLocaleDateString()}</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Crew Member</th>
                      <th>Call Time</th>
                      <th>Wrap Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedTimes.map((entry) => (
                      <tr key={entry.id}>
                        <td>{entry.crewMemberName}</td>
                        <td>{entry.startTime !== null ? formatTime(entry.startTime) : 'Not Set'}</td>
                        <td>{entry.endTime !== null ? formatTime(entry.endTime) : 'Not Set'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
            {unassignedCrewMembers.length > 0 && (
              <div className="unassigned-crew-members">
                <h3>Unassigned Crew Members:</h3>
                <ul>
                  {unassignedCrewMembers.map(member => (
                    <li key={member.id}>{member.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {activeTab === 'dispatchRun' && (
          <div className="dispatch-run-content">
            <div className="dispatch-run-tile">
              <h2>Dispatch New Run</h2>
              {error && <div className="error-message">{error}</div>}
              {assignmentConfirmation && <div className="assignment-confirmation">{assignmentConfirmation}</div>}
              <form onSubmit={handleDispatchRun} className="dispatch-run-form">
                <div className="form-group">
                  <label htmlFor="date">Date:</label>
                  <DatePicker
                    selected={newRun.date ? new Date(newRun.date) : null}
                    onChange={handleNewRunDateChange}
                    dateFormat="yyyy-MM-dd"
                    id="date"
                    name="date"
                    required
                    className="run-date-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="title">Run Title:</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={newRun.title}
                    onChange={handleNewRunChange}
                    required
                    className="run-title-input"
                  />
                </div>
                <div className="form-group run-type">
                  <label>Run Type:</label>
                  <div className="run-type-buttons">
                    <button
                      type="button"
                      className={newRun.runType === 'pickup' ? 'active' : ''}
                      onClick={() => handleNewRunChange({ target: { name: 'runType', value: 'pickup' } })}
                    >
                      Pickup
                    </button>
                    <button
                      type="button"
                      className={newRun.runType === 'dropoff' ? 'active' : ''}
                      onClick={() => handleNewRunChange({ target: { name: 'runType', value: 'dropoff' } })}
                    >
                      Drop-off
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    {newRun.runType === 'pickup' ? 'Pickup Address:' : 'Drop-off Address:'}
                  </label>
                  <textarea
                    id="address"
                    name="address"
                    value={newRun.address}
                    onChange={handleNewRunChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="itemDescription">
                    {newRun.runType === 'pickup' ? 'Item to be Picked Up:' : 'Item to be Dropped-off:'}
                  </label>
                  <textarea
                    id="itemDescription"
                    name="itemDescription"
                    value={newRun.itemDescription}
                    onChange={handleNewRunChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="picture">Add Picture:</label>
                  <input
                    type="file"
                    id="picture"
                    name="picture"
                    onChange={handlePictureChange}
                    accept="image/*"
                  />
                </div>
                <button type="submit" className="dispatch-run-button">Dispatch Run</button>
              </form>
            </div>
            <div className="dispatch-run-tile crew-selection">
              <h3>Crew Members</h3>
              <div className="form-group">
                <label htmlFor="crewMember">Search Crew Member:</label>
                <input
                  type="text"
                  id="crewMember"
                  name="crewMember"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by name or position"
                />
                <div className="selection-buttons">
                  <button 
                    type="button" 
                    onClick={handleSelectAllForRun}
                    className="select-all-button"
                  >
                    Check All
                  </button>
                  <button 
                    type="button" 
                    onClick={handleDeselectAllForRun}
                    className="deselect-all-button"
                  >
                    Deselect All
                  </button>
                </div>
              </div>
              <div className="crew-members-list">
                <div className="crew-members-grid">
                  {filteredCrewMembers.map(member => (
                    <div 
                      key={member.id} 
                      className={`crew-member-tile ${selectedCrewMembersForRun.includes(member.id) ? 'selected' : ''}`}
                      onClick={() => handleCrewMemberCheckboxForRun(member.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedCrewMembersForRun.includes(member.id)}
                        onChange={() => {}} // Empty onChange to avoid React warning
                        onClick={(e) => e.stopPropagation()} // Prevent double-triggering when clicking checkbox
                      />
                      <h4>{member.name}</h4>
                      <p>Position: {member.position || 'Not specified'}</p>
                      <p>Vehicle: {member.vehicle || 'Not assigned'}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="supervisors-list">
                <h3>Supervisors</h3>
                <div className="crew-members-grid">
                  {filteredSupervisors.map(supervisor => (
                    <div 
                      key={supervisor.id} 
                      className={`crew-member-tile ${selectedCrewMembersForRun.includes(supervisor.id) ? 'selected' : ''}`}
                      onClick={() => handleCrewMemberCheckboxForRun(supervisor.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedCrewMembersForRun.includes(supervisor.id)}
                        onChange={() => {}} // Empty onChange to avoid React warning
                        onClick={(e) => e.stopPropagation()} // Prevent double-triggering when clicking checkbox
                      />
                      <h4>{supervisor.name}</h4>
                      <p>Position: {supervisor.position || 'Not specified'}</p>
                      <p>Vehicle: {supervisor.vehicle || 'Not assigned'}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="dispatched-runs-tile">
              <h2>Dispatched Runs for {selectedDate.toLocaleDateString()}</h2>
              <div className="date-navigation">
                <button onClick={handlePreviousDay}>&lt; Previous Day</button>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  className="date-picker"
                />
                <button onClick={handleNextDay}>Next Day &gt;</button>
              </div>
              {dispatchedRuns.length === 0 ? (
                <div className="no-runs-message">
                  No runs dispatched for this date
                </div>
              ) : (
                <table className="dispatched-runs-table">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Title</th>
                      <th>Assigned To</th>
                      <th>Status</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                <tbody>
                  {dispatchedRuns.map(run => (
                    <React.Fragment key={run.id}>
                      <tr 
                        onClick={() => handleRunClick(run.id)}
                        className={`run-row ${expandedRun === run.id ? 'expanded' : ''}`}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{run.dateObj.toLocaleTimeString()}</td>
                        <td>{run.title}</td>
                        <td>{run.assignedToName}</td>
                        <td>{run.status}</td>
                        <td>{run.runType}</td>
                      </tr>
                      {expandedRun === run.id && (
                        <tr className="expanded-details">
                          <td colSpan="5">
                            <div className="run-details">
                              <p><strong>Address:</strong> {run.address}</p>
                              <p><strong>Description:</strong> {run.itemDescription}</p>
                              {run.pictureUrl && (
                                <div className="run-image">
                                  <img 
                                    src={run.pictureUrl} 
                                    alt="Run Details" 
                                    style={{ maxWidth: '200px' }}
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              )}
            </div>
            <div className="future-runs-tile">
              <h2>Future Runs</h2>
              <table className="dispatched-runs-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Assigned To</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {futureRuns.map(run => (
                    <React.Fragment key={run.id}>
                      <tr 
                        onClick={() => handleRunClick(run.id)}
                        className={`run-row ${expandedRun === run.id ? 'expanded' : ''}`}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{run.dateObj.toLocaleDateString()}</td>
                        <td>{run.title}</td>
                        <td>{run.assignedToName}</td>
                        <td>{run.status}</td>
                      </tr>
                      {expandedRun === run.id && (
                        <tr className="expanded-details">
                          <td colSpan="4">
                            <div className="run-details">
                              <p><strong>Run Type:</strong> {run.runType}</p>
                              <p><strong>Address:</strong> {run.address}</p>
                              <p><strong>Description:</strong> {run.itemDescription}</p>
                              {run.pictureUrl && (
                                <div className="run-image">
                                  <img src={run.pictureUrl} alt="Run" />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {activeTab === 'runRequests' && (
          <div className="run-requests-wrapper">
            <div className="run-requests-list">
              {runRequestsLoading ? (
                <div className="loading-state">Loading run requests...</div>
              ) : runRequestsError ? (
                <div className="error-state">{runRequestsError}</div>
              ) : (
                <RunRequests 
                  onSelectRequest={handleRunRequestSelect} 
                  selectedRequest={selectedRunRequest}
                />
              )}
            </div>
            <div className="crew-members-section">
              <div className="crew-members-header">
                <h2>Crew Members</h2>
                <div className="header-actions">
                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder="Search Crew Member..."
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="search-input"
                    />
                  </div>
                  <button 
                    className={`assign-button ${selectedRunRequest ? assignmentStatus[selectedRunRequest.id] || '' : 'disabled'}`}
                    onClick={() => {
                      if (selectedRunRequest) {
                        handleStatusChange(selectedRunRequest.id, 'assigned');
                      }
                    }}
                    disabled={!selectedRunRequest || assignmentStatus[selectedRunRequest?.id] === 'processing'}
                  >
                    {selectedRunRequest ? (
                      assignmentStatus[selectedRunRequest.id] === 'processing' ? 'Assigning...' :
                      assignmentStatus[selectedRunRequest.id] === 'success' ? 'Assigned!' :
                      assignmentStatus[selectedRunRequest.id] === 'error' ? 'Error - Try Again' :
                      'Assign & Send'
                    ) : 'Select a Run Request'}
                  </button>
                </div>
              </div>

              <div className="view-toggle">
                <button
                  className={viewMode === 'crew' ? 'active' : ''}
                  onClick={() => setViewMode('crew')}
                >
                  Crew Members
                </button>
                <button
                  className={viewMode === 'supervisors' ? 'active' : ''}
                  onClick={() => setViewMode('supervisors')}
                >
                  Supervisors
                </button>
              </div>

              <div className="select-actions">
                <button onClick={() => handleSelectAllMembers(viewMode)}>Select All</button>
                <button onClick={() => handleDeselectAllMembers(viewMode)}>Deselect All</button>
              </div>

              <div className="crew-members-grid">
                {viewMode === 'crew' ? (
                  filteredCrewMembers.map(member => (
                    <div
                      key={member.id}
                      className={`crew-member-tile ${selectedCrewMembers.includes(member.id) ? 'selected' : ''}`}
                      onClick={() => handleMemberSelect(member.id, 'crew')}
                    >
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={selectedCrewMembers.includes(member.id)}
                          onChange={() => {}}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                      <div className="member-info">
                        <h3>{member.name || 'No name'}</h3>
                        <p>Position: {member.position || 'Not specified'}</p>
                        <p>Vehicle: {member.vehicle || 'Not assigned'}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  filteredSupervisors.map(supervisor => (
                    <div
                      key={supervisor.id}
                      className={`crew-member-tile ${selectedSupervisors.includes(supervisor.id) ? 'selected' : ''}`}
                      onClick={() => handleMemberSelect(supervisor.id, 'supervisors')}
                    >
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={selectedSupervisors.includes(supervisor.id)}
                          onChange={() => {}}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                      <div className="member-info">
                        <h3>{supervisor.name || 'No name'}</h3>
                        <p>Position: {supervisor.position || 'Not specified'}</p>
                        <p>Vehicle: {supervisor.vehicle || 'Not assigned'}</p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="assigned-runs-section">
              <h3>Assigned Run Requests</h3>
              <table className="assigned-runs-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Run Type</th>
                    <th>Title</th>
                    <th>Assigned To</th>
                    <th>Status</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {dispatchedRuns
                    .filter(run => run.originalRequestId) // Only show runs that came from run requests
                    .map(run => (
                      <tr key={run.id}>
                        <td>{run.date ? new Date(run.date.toDate()).toLocaleDateString() : 'N/A'}</td>
                        <td>{run.runType || 'N/A'}</td>
                        <td>{run.title || 'N/A'}</td>
                        <td>{run.assignedToName || 'N/A'}</td>
                        <td>{run.status || 'Pending'}</td>
                        <td>{run.address || 'N/A'}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {toast && (
          <Toast 
            message={toast.message} 
            onClose={() => setToast(null)}
            duration={3000}
            type={toast.type}
          />
        )}
      </div>
    </div>
  );
}

export default Dispatch;
