import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserProductions, getProductionStats, createProduction } from '../utils/productionDB';
import { canCreateProduction } from '../utils/permissions';
import { updateUserProductionAccess } from '../utils/userUtils';
import Toast from '../components/Toast';
import Sidebar from '../components/Sidebar';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './Productions.css';

const Productions = () => {
  const [productions, setProductions] = useState([]);
  const [productionStats, setProductionStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newProduction, setNewProduction] = useState({
    name: '',
    status: 'pre-production',
    startDate: '',
    endDate: '',
    budget: '',
    description: ''
  });
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  // Separate function to fetch stats
  const fetchStats = async (productionList) => {
    try {
      const stats = {};
      for (const prod of productionList) {
        const prodStats = await getProductionStats(prod.id);
        stats[prod.id] = prodStats;
        // Update stats immediately for each production
        setProductionStats(prevStats => ({
          ...prevStats,
          [prod.id]: prodStats
        }));
      }
      return stats;
    } catch (error) {
      console.error("Error fetching stats:", error);
      return {};
    }
  };

  useEffect(() => {
    if (user) {
      console.log('Current user:', user);
      fetchProductions();
    }
  }, [user]);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Not set';
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString();
    }
    return new Date(timestamp).toLocaleDateString();
  };

  const fetchProductions = async () => {
    try {
      setLoading(true);
      const productionList = await getUserProductions(user.uid, user.role);
      
      // Format dates for each production
      const formattedProductions = productionList.map(prod => ({
        ...prod,
        startDate: formatDate(prod.startDate),
        endDate: formatDate(prod.endDate),
        createdAt: formatDate(prod.createdAt),
        updatedAt: formatDate(prod.updatedAt)
      }));
      
      setProductions(formattedProductions);
      
      // Fetch stats after setting productions
      await fetchStats(productionList);
    } catch (error) {
      console.error("Error fetching productions:", error);
      setError("Failed to fetch productions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduction(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      setError('');
      const productionData = {
        ...newProduction,
        budget: parseFloat(newProduction.budget) || 0,
        createdBy: user.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: newProduction.status || 'pre-production'
      };

      console.log('Creating production with data:', productionData);
      await createProduction(productionData);
      console.log('Production created successfully');

      // Reset form
      setShowCreateForm(false);
      setNewProduction({
        name: '',
        status: 'pre-production',
        startDate: '',
        endDate: '',
        budget: '',
        description: ''
      });

      // Refresh productions list
      await fetchProductions();
    } catch (error) {
      console.error("Error creating production:", error);
      setError(error.message || "Failed to create production. Please try again.");
    }
  };

  const handleAccessProduction = async (productionId) => {
    try {
      setLoading(true);
      await updateUserProductionAccess(user.uid, productionId);
      
      // Update the user context with the new production
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.exists() ? userDoc.data() : null;
      
      if (userData) {
        // Update the user context with the new data
        await setUser({
          ...user,
          ...userData,
          production: productionId
        });
        
        // Small delay to ensure context is updated
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Navigate to the vehicles page with the correct path
        navigate('/vehicles-page');
      }
    } catch (error) {
      console.error("Error accessing production:", error);
      setError("Failed to access production. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="productions-content">
        <header className="page-header">
          <h1>Productions</h1>
          <p>Manage and track your productions</p>
        </header>

        {error && <Toast message={error} type="error" />}
        
        <main>
          <div className="actions-bar">
            {canCreateProduction(user?.role) && (
              <button 
                className="button button-primary"
                onClick={() => setShowCreateForm(!showCreateForm)}
              >
                {showCreateForm ? 'Cancel' : 'Create New Production'}
              </button>
            )}
          </div>

          {showCreateForm && (
            <div className="create-production-form">
              <h2>Create New Production</h2>
              <form onSubmit={handleCreateSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Production Name</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={newProduction.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    value={newProduction.status}
                    onChange={handleInputChange}
                  >
                    <option value="pre-production">Pre-Production</option>
                    <option value="production">Production</option>
                    <option value="post-production">Post-Production</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="startDate">Start Date</label>
                    <input
                      id="startDate"
                      type="date"
                      name="startDate"
                      value={newProduction.startDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="endDate">End Date</label>
                    <input
                      id="endDate"
                      type="date"
                      name="endDate"
                      value={newProduction.endDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="budget">Budget</label>
                  <input
                    id="budget"
                    type="number"
                    name="budget"
                    value={newProduction.budget}
                    onChange={handleInputChange}
                    placeholder="Enter budget amount"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    name="description"
                    value={newProduction.description}
                    onChange={handleInputChange}
                    rows="4"
                  />
                </div>

                <div className="form-actions">
                  <button type="submit" className="submit-btn">
                    Create Production
                  </button>
                  <button 
                    type="button" 
                    className="cancel-btn"
                    onClick={() => setShowCreateForm(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
          
          {loading ? (
            <div className="loading">Loading productions...</div>
          ) : (
            !showCreateForm && (
              <div className="productions-grid">
                {productions.map((production) => (
                  <div 
                    key={production.id} 
                    className="production-card"
                    onClick={() => navigate(`/productions/${production.id}`)}
                  >
                    <div className="production-header">
                      <h2>{production.name}</h2>
                      <span className={`status-badge ${production.status}`}>
                        {production.status}
                      </span>
                    </div>
                    <div className="production-info">
                      <p><strong>Start Date:</strong> {production.startDate}</p>
                      <p><strong>End Date:</strong> {production.endDate}</p>
                      <p><strong>Budget:</strong> ${production.budget}</p>
                    </div>
                    {productionStats[production.id] && (
                      <div className="production-stats">
                        <div className="stat">
                          <span className="stat-label">Departments</span>
                          <span className="stat-value">{productionStats[production.id].departmentsCount}</span>
                        </div>
                        <div className="stat">
                          <span className="stat-label">Total Personnel</span>
                          <span className="stat-value">{productionStats[production.id].crewCount}</span>
                        </div>
                      </div>
                    )}
                    <div className="production-actions">
                      {user?.role === 'admin' && (
                        <button
                          className="button button-secondary"
                          onClick={() => handleAccessProduction(production.id)}
                          disabled={loading}
                        >
                          {loading ? 'Accessing...' : 'Access Production'}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </main>
      </div>
    </div>
  );
};

export default Productions;