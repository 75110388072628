import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import './ProfileCompletion.css';

function ProfileCompletion() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [editedProfile, setEditedProfile] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    lastFiveSSN: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    emergencyContacts: [{ name: '', phone: '', email: '', address: '' }],
    vehicles: [{ make: '', color: '', plateNumber: '', plateState: '' }],
  });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setEditedProfile(prevProfile => ({
        ...prevProfile,
        email: user.email || '',
      }));
      setLoading(false);

      // Redirect if profile is already completed or login count is not 0
      if (user.profileCompleted || user.loginCount > 0) {
        navigate('/');
      }
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleEmergencyContactChange = (index, field, value) => {
    const updatedContacts = editedProfile.emergencyContacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, [field]: value };
      }
      return contact;
    });
    setEditedProfile(prev => ({ ...prev, emergencyContacts: updatedContacts }));
  };

  const handleVehicleChange = (index, field, value) => {
    const updatedVehicles = editedProfile.vehicles.map((vehicle, i) => {
      if (i === index) {
        return { ...vehicle, [field]: value };
      }
      return vehicle;
    });
    setEditedProfile(prev => ({ ...prev, vehicles: updatedVehicles }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      if (newPassword) {
        await updatePassword(auth.currentUser, newPassword);
      }

      await updateDoc(doc(db, 'users', user.uid), {
        ...editedProfile,
        name: `${editedProfile.firstName} ${editedProfile.lastName}`.trim(),
        profileCompleted: true,
        passwordChanged: true
      });

      navigate('/');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || user.profileCompleted || user.loginCount > 0) {
    return null;
  }

  return (
    <div className="profile-completion">
      <div className="profile-completion-content">
        <h2>Complete Your Profile</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="personal-info-group">
            <div className="name-birth-ssn-group">
              <div className="form-group">
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={editedProfile.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={editedProfile.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="birthDate">Birth Date:</label>
                <input
                  type="date"
                  id="birthDate"
                  name="birthDate"
                  value={editedProfile.birthDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastFiveSSN">Last 5 of SSN:</label>
                <input
                  type="text"
                  id="lastFiveSSN"
                  name="lastFiveSSN"
                  value={editedProfile.lastFiveSSN}
                  onChange={handleChange}
                  maxLength="5"
                  required
                />
              </div>
            </div>
            <div className="contact-group">
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={editedProfile.email}
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={editedProfile.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="address-group">
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                id="address"
                name="address"
                value={editedProfile.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                id="city"
                name="city"
                value={editedProfile.city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <input
                type="text"
                id="state"
                name="state"
                value={editedProfile.state}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="zipcode">Zipcode:</label>
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                value={editedProfile.zipcode}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="emergency-contacts">
            <h3>Emergency Contact</h3>
            {editedProfile.emergencyContacts.map((contact, index) => (
              <div key={index} className="emergency-contact">
                <input
                  type="text"
                  placeholder="Name"
                  value={contact.name}
                  onChange={(e) => handleEmergencyContactChange(index, 'name', e.target.value)}
                  required
                />
                <input
                  type="tel"
                  placeholder="Phone"
                  value={contact.phone}
                  onChange={(e) => handleEmergencyContactChange(index, 'phone', e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={contact.email}
                  onChange={(e) => handleEmergencyContactChange(index, 'email', e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={contact.address}
                  onChange={(e) => handleEmergencyContactChange(index, 'address', e.target.value)}
                  required
                />
              </div>
            ))}
          </div>
          <div className="vehicle-info">
            <h3>Vehicle Information</h3>
            {editedProfile.vehicles.map((vehicle, index) => (
              <div key={index} className="vehicle-fields-group">
                <input
                  type="text"
                  placeholder="Make"
                  value={vehicle.make}
                  onChange={(e) => handleVehicleChange(index, 'make', e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Color"
                  value={vehicle.color}
                  onChange={(e) => handleVehicleChange(index, 'color', e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Plate Number"
                  value={vehicle.plateNumber}
                  onChange={(e) => handleVehicleChange(index, 'plateNumber', e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Plate State"
                  value={vehicle.plateState}
                  onChange={(e) => handleVehicleChange(index, 'plateState', e.target.value)}
                  required
                />
              </div>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Complete Profile</button>
        </form>
      </div>
    </div>
  );
}

export default ProfileCompletion;