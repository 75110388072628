import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, deleteField, query, where, deleteDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import { useAccessControl } from '../hooks/useAccessControl';
import Sidebar from '../components/Sidebar';
import './Vehicles.css';
import '../styles/SharedStyles.css';
import { useLocation } from 'react-router-dom';
import Toast from '../components/Toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Timestamp } from 'firebase/firestore';
import updateVehicleDepartments from '../scripts/updateVehicleDepartments';
import { db } from '../firebase';
import { isAdmin, isProductionAdmin, hasAdminAccess } from '../utils/roleUtils';

// Add this near the top of your file, after the imports
const stateAbbreviations = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

// Add this function near your other utility functions
const uploadImage = async (image) => {
  if (!image) return null;
  
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `vehicle-images/${Date.now()}-${image.name}`);
    
    const snapshot = await uploadBytes(storageRef, image);
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

function VehiclesPage() {
  const location = useLocation();
  const { user } = useAuth();
  const { productionAccess, departmentAccess, hasAccess, isLoading: accessLoading, error: accessError } = useAccessControl();
  
  // Initialize vehicles state with proper structure
  const [vehicles, setVehicles] = useState({
    totalVehicles: 0,
    inProduction: 0,
    available: 0,
    productionAccess: '',
    departmentAccess: '',
    availableVehicles: [],
    vehiclesInProduction: []
  });
  
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddVendorForm, setShowAddVendorForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [toast, setToast] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [images, setImages] = useState([]);
  const [editFormImages, setEditFormImages] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState('');
  const [expandedVehicle, setExpandedVehicle] = useState(null);
  const [damageImages, setDamageImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  
  // Add vehicle form state
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    vin: '',
    licensePlate: '',
    licensePlateState: '',
    registration: '',
    insurance: '',
    maintenance: '',
    status: '',
    notes: '',
    department: '',
    vendor: '',
    unitNumber: ''
  });

  // Add vendor form state
  const [newVendor, setNewVendor] = useState({
    name: '',
    address: '',
    phone: '',
    email: ''
  });
  
  // Initialize edit form with null dates
  const [editForm, setEditForm] = useState({
    licensePlate: '',
    licensePlateState: '',
    unitNumber: '',
    vendorId: '',
    assignedTo: '',
    rentalRate: '',
    rentalPeriod: 'daily',
    rentalStart: null,
    rentalEnd: null,
    status: '',
    production: '',
    notes: '',
    imageUrls: []
  });

  const handleEditFormChange = (e) => {
    const { name, value, type, files } = e.target;
    
    if (type === 'file') {
      if (name === 'newImages') {
        setEditFormImages(Array.from(files));
      }
    } else {
      setEditForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date, field) => {
    setEditForm(prev => ({
      ...prev,
      [field]: date ? new Date(date) : null
    }));
  };

  const handleEditClick = (vehicle) => {
    // Convert dates to proper format when editing
    const formattedVehicle = {
      ...vehicle,
      rentalStart: vehicle.rentalStart ? (vehicle.rentalStart.toDate ? vehicle.rentalStart.toDate() : new Date(vehicle.rentalStart)) : null,
      rentalEnd: vehicle.rentalEnd ? (vehicle.rentalEnd.toDate ? vehicle.rentalEnd.toDate() : new Date(vehicle.rentalEnd)) : null
    };
    
    setEditingVehicle(formattedVehicle);
    setEditForm(formattedVehicle);
    setShowEditForm(true);
  };

  // Move fetchData outside of useEffect
  const fetchData = async () => {
    if (!user) {
      console.log('No user, skipping vehicle fetch');
      return;
    }

    // Allow access for admins even without production access
    if (!hasAccess && !hasAdminAccess(user?.role)) {
      console.log('No access and not admin, skipping vehicle fetch');
      return;
    }

    try {
      setIsLoading(true);
      const db = getFirestore();
      
      // Re-fetch user data to ensure we have the latest production access
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.exists() ? userDoc.data() : null;
      
      if (!userData || (!userData.production && !hasAdminAccess(user?.role))) {
        console.log('No production access found in user data');
        setError('No production access. Please select a production first.');
        setIsLoading(false);
        return;
      }

      const currentProduction = userData.production;
      
      // Fetch productions first
      const productionsCollection = collection(db, 'productions');
      const productionsSnapshot = await getDocs(productionsCollection);
      const productionsData = productionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProductions(productionsData);

      // Fetch vendors
      const vendorsCollection = collection(db, 'vendors');
      const vendorsSnapshot = await getDocs(vendorsCollection);
      const vendorsData = vendorsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorsData);
      
      // Fetch crew members
      const crewMembersCollection = collection(db, 'users');
      const crewMembersSnapshot = await getDocs(crewMembersCollection);
      const crewMembersData = crewMembersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCrewMembers(crewMembersData);
      
      // Fetch vehicles
      const vehiclesRef = collection(db, 'vehicles');
      const vehiclesSnapshot = await getDocs(vehiclesRef);
      
      const fetchedVehicles = [];
      vehiclesSnapshot.forEach((doc) => {
        const vehicleData = doc.data();
        
        // Convert Firestore Timestamps to JavaScript Dates
        const processedData = {
          ...vehicleData,
          rentalStart: vehicleData.rentalStart ? vehicleData.rentalStart.toDate() : null,
          rentalEnd: vehicleData.rentalEnd ? vehicleData.rentalEnd.toDate() : null,
          createdAt: vehicleData.createdAt ? vehicleData.createdAt.toDate() : null
        };
        
        fetchedVehicles.push({
          id: doc.id,
          ...processedData
        });
      });

      // Filter available vehicles
      const availableVehicles = fetchedVehicles.filter(vehicle => {
        const isAvailable = (!vehicle.productionId || vehicle.productionId === '') && 
                          (!vehicle.production || vehicle.production === '') &&
                          (!vehicle.vehicleProduction || vehicle.vehicleProduction === '');

        return isAvailable;
      });

      // Filter vehicles in production
      const vehiclesInProduction = fetchedVehicles.filter(vehicle => {
        const isInProduction = vehicle.production === currentProduction || 
                             vehicle.productionId === currentProduction ||
                             vehicle.vehicleProduction === currentProduction;

        return isInProduction;
      });

      setVehicles({
        totalVehicles: fetchedVehicles.length,
        inProduction: vehiclesInProduction.length,
        available: availableVehicles.length,
        productionAccess: currentProduction,
        departmentAccess,
        availableVehicles,
        vehiclesInProduction
      });

    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // All useEffect hooks must be before conditional returns
  useEffect(() => {
    fetchData();
  }, [hasAccess, productionAccess, departmentAccess, user]);

  // Early return if user is not logged in
  if (!user) {
    return <div>Please log in to view vehicles.</div>;
  }

  // Check user role after confirming user exists
  const isCrewMember = (role) => role === 'crewMember' || role === 'Crew Member';
  const isSupervisor = (role) => role === 'supervisor' || role === 'Supervisor';
  const isAdmin = (role) => role === 'admin' || role === 'Admin';
  const isProductionAdmin = (role) => role === 'productionAdmin' || role === 'Production Admin';

  if (accessLoading) {
    return (
      <div className="page-container">
        <Sidebar />
        <div className="content-container">
          <div className="loading-message">Loading access control...</div>
        </div>
      </div>
    );
  }

  if (accessError || error) {
    return (
      <div className="page-container">
        <Sidebar />
        <div className="content-container">
          <div className="error-message">
            {accessError || error}
          </div>
        </div>
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div className="page-container">
        <Sidebar />
        <div className="content-container">
          <div className="error-message">
            You don't have access to view vehicles. Please contact your administrator.
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="page-container">
        <Sidebar />
        <div className="content-container">
          <div className="loading-message">Loading vehicles...</div>
        </div>
      </div>
    );
  }

  const fetchVendors = async () => {
    try {
      const db = getFirestore();
      const vendorsCollection = collection(db, 'vendors');
      const querySnapshot = await getDocs(vendorsCollection);
      const vendorsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorsData);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError(error.message);
    }
  };

  const fetchCrewMembers = async () => {
    try {
      const db = getFirestore();
      const crewMembersCollection = collection(db, 'users');
      console.log("Attempting to fetch crew members. User role:", user.role);
      const querySnapshot = await getDocs(crewMembersCollection);
      const crewMembersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCrewMembers(crewMembersData);
      console.log("Successfully fetched crew members. Count:", crewMembersData.length);
    } catch (error) {
      console.error('Detailed error fetching crew members:', error);
      let errorMessage = "Error fetching crew members: ";
      if (error.code === 'permission-denied') {
        errorMessage += "Permission denied. Your role may not have access to this data.";
      } else if (error.code === 'unauthenticated') {
        errorMessage += "User is not authenticated. Please log in again.";
      } else {
        errorMessage += error.message || "Unknown error occurred";
      }
      errorMessage += ` (User role: ${user.role}, Error code: ${error.code})`;
      setError(errorMessage);
    }
  };

  const fetchProductions = async () => {
    try {
      const db = getFirestore();
      const productionsCollection = collection(db, 'productions');
      const querySnapshot = await getDocs(productionsCollection);
      const productionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProductions(productionsData);
    } catch (error) {
      console.error('Error fetching productions:', error);
      setError(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle({ ...newVehicle, [name]: value });
  };

  const handleVendorInputChange = (e) => {
    const { name, value } = e.target;
    setNewVendor({ ...newVendor, [name]: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImages(Array.from(e.target.files));
    }
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    setDocuments(prevDocuments => [...prevDocuments, ...files]);
  };

  const handleDamageImageChange = (e) => {
    const files = Array.from(e.target.files);
    setDamageImages(prevImages => [...prevImages, ...files]);
  };

  const handleAddVehicle = async (e) => {
    e.preventDefault();
    if (!hasAccess || !user) return;

    try {
      setIsLoading(true);
      const db = getFirestore();
      const storage = getStorage();

      // Upload images first
      const uploadFiles = async (files, folder) => {
        if (!files || files.length === 0) return [];
        const urls = [];
        for (const file of files) {
          const timestamp = Date.now();
          const filename = `${timestamp}-${file.name}`;
          const imageRef = ref(storage, `${folder}/${user.uid}/${filename}`);
          await uploadBytes(imageRef, file);
          const url = await getDownloadURL(imageRef);
          urls.push(url);
        }
        return urls;
      };

      // Upload all types of images
      const [imageUrls, damageImageUrls, documentUrls] = await Promise.all([
        uploadFiles(images.filter(img => img.type.startsWith('image/')), 'vehicles'),
        uploadFiles(damageImages.filter(img => img.type.startsWith('image/')), 'damage'),
        uploadFiles(documents.filter(doc => doc.type.startsWith('image/')), 'documents')
      ]);

      const vehicleData = {
        ...newVehicle,
        production: productionAccess || '',
        department: 'Transportation',  // Always set department to Transportation
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        status: 'available',
        imageUrls,
        damageImageUrls,
        documentUrls,
        licensePlateState: newVehicle.licensePlateState,
        unitNumber: newVehicle.unitNumber || '',
        notes: newVehicle.notes || ''
      };

      // Create the vehicle document
      const docRef = await addDoc(collection(db, 'vehicles'), vehicleData);

      // Reset form
      setShowAddForm(false);
      setNewVehicle({
        make: '',
        model: '',
        year: '',
        vin: '',
        licensePlate: '',
        licensePlateState: '',
        registration: '',
        insurance: '',
        maintenance: '',
        status: '',
        notes: '',
        department: '',
        vendor: '',
        unitNumber: ''
      });
      setImages([]);
      setDamageImages([]);
      setDocuments([]);

      // Update local state
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        totalVehicles: prevVehicles.totalVehicles + 1,
        available: prevVehicles.available + 1,
        availableVehicles: [...prevVehicles.availableVehicles, { 
          id: docRef.id, 
          ...vehicleData,
          createdAt: new Date() // Convert serverTimestamp to Date for local state
        }]
      }));

      setToast({
        message: 'Vehicle added successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error adding vehicle:', error);
      setToast({
        message: 'Error adding vehicle: ' + error.message,
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddVendor = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      await addDoc(collection(db, 'vendors'), newVendor);
      setNewVendor({
        name: '',
        address: '',
        phone: '',
        email: ''
      });
      fetchVendors();
      setShowAddVendorForm(false);
    } catch (error) {
      console.error("Error adding vendor: ", error);
      setError(error.message);
    }
  };

  const handleUpdateVehicle = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', editingVehicle.id);
      
      // Convert string dates to Date objects before creating Timestamps
      const rentalStartDate = editForm.rentalStart ? new Date(editForm.rentalStart) : null;
      const rentalEndDate = editForm.rentalEnd ? new Date(editForm.rentalEnd) : null;
      
      const updatedData = {
        ...editForm,
        rentalStart: rentalStartDate ? Timestamp.fromDate(rentalStartDate) : null,
        rentalEnd: rentalEndDate ? Timestamp.fromDate(rentalEndDate) : null,
        department: 'Transportation'  // Always set department to Transportation when updating
      };

      // If assigning to a production, ensure department is set
      if (editForm.production && editForm.production !== '') {
        updatedData.department = 'Transportation';
      }

      console.log('Updating vehicle with:', updatedData);
      
      await updateDoc(vehicleRef, updatedData);

      // Update local state
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === editingVehicle.id 
            ? { ...vehicle, ...updatedData }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === editingVehicle.id 
            ? { ...vehicle, ...updatedData }
            : vehicle
        )
      }));

      // Reset form state
      setEditingVehicle(null);
      setEditForm({});
      setEditFormImages([]);
      setShowEditForm(false);

      setToast({
        message: 'Vehicle updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error("Error updating vehicle: ", error);
      setToast({
        message: 'Error updating vehicle: ' + error.message,
        type: 'error'
      });
    }
  };

  const handleRemoveImage = async (vehicleId, imageUrl, imageIndex) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      const updatedImageUrls = vehicleDoc.data().imageUrls.filter((_, index) => index !== imageIndex);
      await updateDoc(vehicleRef, { imageUrls: updatedImageUrls });

      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, imageUrls: updatedImageUrls }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, imageUrls: updatedImageUrls }
            : vehicle
        )
      }));
    } catch (error) {
      console.error("Error removing image: ", error);
      setError(error.message);
    }
  };

  const handleAssignVehicle = async (vehicleId, crewMemberId, productionId) => {
    if (!(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role))) {
      setError("You don't have permission to assign vehicles.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: crewMemberId,
        productionId: productionId,
        status: 'in_use'
      });
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.filter(vehicle => vehicle.id !== vehicleId),
        vehiclesInProduction: [...prevVehicles.vehiclesInProduction, { id: vehicleId, assignedTo: crewMemberId, productionId: productionId, status: 'in_use' }]
      }));
    } catch (error) {
      console.error("Error assigning vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveFromProduction = async (vehicleId) => {
    if (!(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role))) {
      setError("Only supervisors, production admins, or admins can remove vehicles from production.");
      return;
    }
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);

      // Clear ALL production-related fields
      await updateDoc(vehicleRef, {
        production: "",
        productionId: "",
        vehicleProduction: "",
        status: "available",
        assignedTo: "",
        rentalStart: null,
        rentalEnd: null,
        rentalRate: "",
        rentalPeriod: "daily"
      });

      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: [...prevVehicles.availableVehicles, { 
          ...prevVehicles.vehiclesInProduction.find(v => v.id === vehicleId),
          production: "",
          productionId: "",
          vehicleProduction: "",
          status: "available",
          assignedTo: "",
          rentalStart: null,
          rentalEnd: null,
          rentalRate: "",
          rentalPeriod: "daily"
        }],
        vehiclesInProduction: prevVehicles.vehiclesInProduction.filter(vehicle => vehicle.id !== vehicleId)
      }));

      setToast({
        message: 'Vehicle removed from production successfully',
        type: 'success'
      });
    } catch (error) {
      console.error("Error removing vehicle from production: ", error);
      setToast({
        message: 'Failed to remove vehicle from production: ' + error.message,
        type: 'error'
      });
    }
  };

  const handleRemoveDocument = async (vehicleId, documentUrl, documentIndex) => {
    try {
      const storage = getStorage();
      const documentRef = ref(storage, documentUrl);
      await deleteObject(documentRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      const updatedDocumentUrls = vehicleDoc.data().documentUrls.filter((_, index) => index !== documentIndex);
      await updateDoc(vehicleRef, { documentUrls: updatedDocumentUrls });

      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, documentUrls: updatedDocumentUrls }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, documentUrls: updatedDocumentUrls }
            : vehicle
        )
      }));
    } catch (error) {
      console.error("Error removing document: ", error);
      setError(error.message);
    }
  };

  const handleVendorAssignment = async (vehicleId, vendorId) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        vendorId: vendorId
      });
      
      // Update local state
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, vendorId: vendorId }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, vendorId: vendorId }
            : vehicle
        )
      }));
      
      setToast({ 
        message: 'Vendor assigned successfully', 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error assigning vendor:', error);
      setToast({ 
        message: 'Failed to assign vendor', 
        type: 'error' 
      });
    }
  };

  const handleRentalDateChange = async (vehicleId, field, date) => {
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);

      // Convert date to Firestore Timestamp
      const timestamp = date ? Timestamp.fromDate(date) : null;

      await updateDoc(vehicleRef, {
        [field]: timestamp
      });

      // Update local state
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, [field]: timestamp }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, [field]: timestamp }
            : vehicle
        )
      }));

      setToast({
        message: 'Rental date updated successfully',
        type: 'success'
      });

    } catch (error) {
      console.error('Error updating rental date:', error);
      setToast({
        message: 'Failed to update rental date',
        type: 'error'
      });
    }
  };

  const handleEditSubmit = async () => {
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', editingVehicle.id);
      
      // Convert string dates to Date objects before creating Timestamps
      const rentalStartDate = editForm.rentalStart ? new Date(editForm.rentalStart) : null;
      const rentalEndDate = editForm.rentalEnd ? new Date(editForm.rentalEnd) : null;
      
      const updatedData = {
        licensePlate: editForm.licensePlate,
        licensePlateState: editForm.licensePlateState,
        unitNumber: editForm.unitNumber,
        vendorId: editForm.vendorId,
        assignedTo: editForm.assignedTo,
        rentalRate: editForm.rentalRate,
        rentalPeriod: editForm.rentalPeriod,
        rentalStart: rentalStartDate ? Timestamp.fromDate(rentalStartDate) : null,
        rentalEnd: rentalEndDate ? Timestamp.fromDate(rentalEndDate) : null,
        status: editForm.status,
        production: editForm.production || 'the big test show',
        department: 'Transportation',
      };

      console.log('Updating vehicle with:', updatedData);
      
      await updateDoc(vehicleRef, updatedData);

      setToast({
        message: 'Vehicle updated successfully',
        type: 'success'
      });
      
      setEditingVehicle(null);
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === editingVehicle.id 
            ? { ...vehicle, ...updatedData }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === editingVehicle.id 
            ? { ...vehicle, ...updatedData }
            : vehicle
        )
      }));

    } catch (error) {
      console.error('Error updating vehicle:', error);
      setError(error.message);
    }
  };

  const handleRentalRateChange = async (vehicleId, rate, period) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        rentalRate: rate,
        rentalPeriod: period
      });

      // Update local state
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, rentalRate: rate, rentalPeriod: period }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, rentalRate: rate, rentalPeriod: period }
            : vehicle
        )
      }));

      setToast({
        message: 'Rental rate updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating rental rate:', error);
      setToast({
        message: 'Failed to update rental rate',
        type: 'error'
      });
    }
  };

  // Add this function to VehiclesPage.js
  const updateAllVehiclesWithDepartment = async () => {
    try {
      const db = getFirestore();
      
      // Get all vehicles without department field
      const vehiclesQuery = query(
        collection(db, 'vehicles'),
        where('production', '==', 'the big test show')
      );
      
      const querySnapshot = await getDocs(vehiclesQuery);
      
      // Update each vehicle
      const updatePromises = querySnapshot.docs.map(async (doc) => {
        const vehicleRef = doc.ref;
        await updateDoc(vehicleRef, {
          department: 'Transportation'
        });
        console.log(`Updated vehicle ${doc.id} with department`);
      });
      
      await Promise.all(updatePromises);
      console.log('All vehicles updated with department field');
      
      // Refresh the vehicles list
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => ({ ...vehicle, department: 'Transportation' })),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => ({ ...vehicle, department: 'Transportation' }))
      }));
      
    } catch (error) {
      console.error('Error updating vehicles with department:', error);
    }
  };

  const handleUpdateDepartments = async () => {
    try {
      setIsLoading(true);
      await updateVehicleDepartments();
      await fetchData(); // Refresh the vehicles list
      setToast({
        message: 'Successfully updated all vehicles to Transportation department',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating departments:', error);
      setToast({
        message: 'Error updating departments: ' + error.message,
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add this before the filter
  console.log('Current user:', user);

  // Modify the filters with null checks
  console.log('All fetched vehicles:', vehicles.availableVehicles.map(v => ({
    id: v.id,
    make: v.make,
    model: v.model,
    production: v.production,
    department: v.department,
    status: v.status
  })));

  const vehiclesInProduction = vehicles.vehiclesInProduction.filter(vehicle => 
    vehicle.productionId === productionAccess || 
    vehicle.production === productionAccess || 
    vehicle.vehicleProduction === productionAccess
  );

  const availableVehicles = vehicles.availableVehicles.filter(vehicle => {
    // A vehicle is available ONLY if it has no production assigned
    const hasNoProduction = !vehicle.productionId && (!vehicle.production || vehicle.production === '') && (!vehicle.vehicleProduction || vehicle.vehicleProduction === '');

    console.log('Checking vehicle availability:', {
      id: vehicle.id,
      make: vehicle.make,
      model: vehicle.model,
      productionId: vehicle.productionId,
      production: vehicle.production,
      hasNoProduction,
      willShow: hasNoProduction
    });
    
    return hasNoProduction;
  });

  console.log('Filtered results:', {
    totalVehicles: vehicles.totalVehicles,
    inProduction: vehiclesInProduction.length,
    available: availableVehicles.length,
    productionAccess,
    departmentAccess,
    availableVehicles: availableVehicles.map(v => ({
      id: v.id,
      make: v.make,
      model: v.model,
      department: v.department,
      production: v.production
    }))
  });

  const handleVehicleClick = (vehicle, e) => {
    // If clicking on the card itself, toggle expansion
    if (e.currentTarget === e.target || e.target.closest('.vehicle-info')) {
      setExpandedVehicle(expandedVehicle?.id === vehicle.id ? null : vehicle);
    }
  };

  // Add a click handler for the document
  

  // Add these functions to handle drag and drop
  const handleDragStart = (e, index, type) => {
    e.dataTransfer.setData('index', index);
    e.dataTransfer.setData('type', type); // 'vehicle', 'damage', or 'document'
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, newIndex, type) => {
    e.preventDefault();
    const oldIndex = parseInt(e.dataTransfer.getData('index'));
    const dragType = e.dataTransfer.getData('type');
    
    // Only allow reordering within the same type of images
    if (dragType !== type) return;

    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', editingVehicle.id);
      
      let updatedUrls;

      if (type === 'vehicle') {
        const urls = [...editingVehicle.imageUrls];
        const [movedItem] = urls.splice(oldIndex, 1);
        urls.splice(newIndex, 0, movedItem);
        updatedUrls = { imageUrls: urls };
      } else if (type === 'damage') {
        const urls = [...editingVehicle.damageImageUrls];
        const [movedItem] = urls.splice(oldIndex, 1);
        urls.splice(newIndex, 0, movedItem);
        updatedUrls = { damageImageUrls: urls };
      }

      // Update Firestore
      await updateDoc(vehicleRef, updatedUrls);

      // Update local state
      setEditingVehicle(prev => ({
        ...prev,
        ...(type === 'vehicle' ? { imageUrls: updatedUrls.imageUrls } : { damageImageUrls: updatedUrls.damageImageUrls })
      }));

    } catch (error) {
      console.error('Error reordering images:', error);
      setToast({
        message: 'Failed to reorder images',
        type: 'error'
      });
    }
  };

  const handleRemoveDamageImage = async (vehicleId, imageUrl, imageIndex) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      
      // Get current damage images and filter out the one being removed
      const currentDamageImages = vehicleDoc.data().damageImageUrls || [];
      const updatedDamageImages = currentDamageImages.filter((_, index) => index !== imageIndex);
      
      // Update the document
      await updateDoc(vehicleRef, { 
        damageImageUrls: updatedDamageImages 
      });

      // Update local state
      setEditingVehicle(prev => ({
        ...prev,
        damageImageUrls: updatedDamageImages
      }));

      setToast({
        message: 'Damage image removed successfully',
        type: 'success'
      });

      // Refresh vehicles list
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, damageImageUrls: updatedDamageImages }
            : vehicle
        ),
        vehiclesInProduction: prevVehicles.vehiclesInProduction.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, damageImageUrls: updatedDamageImages }
            : vehicle
        )
      }));

    } catch (error) {
      console.error("Error removing damage image: ", error);
      setToast({
        message: 'Failed to remove damage image',
        type: 'error'
      });
    }
  };

  // Add this handler function
  const handleModalClick = (e) => {
    // Check if the click is on the modal backdrop (outside the form)
    if (e.target.className === 'edit-vehicle-modal') {
      setEditingVehicle(null);
    }
  };

  const handleAttachToProduction = async () => {
    if (!selectedVehicle) {
      setToast({
        message: 'Please select a vehicle first',
        type: 'error'
      });
      return;
    }

    if (!productionAccess) {
      setToast({
        message: 'No production selected. Please select a production first.',
        type: 'error'
      });
      return;
    }

    try {
      const db = getFirestore();
      
      // First, get the production ID for the selected production
      const productionsRef = collection(db, 'productions');
      const q = query(productionsRef, where("name", "==", productionAccess));
      const querySnapshot = await getDocs(q);
      
      let productionId = '';
      if (!querySnapshot.empty) {
        productionId = querySnapshot.docs[0].id;
      }

      console.log('Found production ID:', {
        productionName: productionAccess,
        productionId
      });

      const vehicleRef = doc(db, 'vehicles', selectedVehicle.id);

      console.log('Attaching vehicle to production:', {
        vehicleId: selectedVehicle.id,
        production: productionAccess,
        productionId,
        vehicleProduction: productionAccess,
        department: departmentAccess
      });

      // Update ALL production-related fields
      await updateDoc(vehicleRef, {
        production: productionAccess,
        productionId: productionId,
        vehicleProduction: productionAccess,
        department: 'Transportation',  // Always set department to Transportation
        status: 'assigned'
      });

      // Clear selection and show success message
      setSelectedVehicle(null);
      setToast({
        message: 'Vehicle successfully added to production',
        type: 'success'
      });

      // Update local state
      setVehicles(prevVehicles => ({
        ...prevVehicles,
        availableVehicles: prevVehicles.availableVehicles.filter(vehicle => vehicle.id !== selectedVehicle.id),
        vehiclesInProduction: [...prevVehicles.vehiclesInProduction, { 
          ...selectedVehicle,
          production: productionAccess,
          productionId: productionId,
          vehicleProduction: productionAccess,
          department: 'Transportation',  // Always set department to Transportation
          status: 'assigned'
        }]
      }));

    } catch (error) {
      console.error('Error attaching vehicle to production:', error);
      setToast({
        message: 'Failed to add vehicle to production: ' + error.message,
        type: 'error'
      });
    }
  };

  if (!productionAccess && !hasAdminAccess(user?.role)) {
    return (
      <div className="no-production-message">
        <h2>No production assigned</h2>
        <p>Please contact an administrator to get assigned to a production.</p>
      </div>
    );
  }

  const UpdateDepartmentsButton = () => (
    <div style={{ marginBottom: '20px', padding: '20px' }}>
      <button 
        className="action-button update-departments-button"
        onClick={handleUpdateDepartments}
        disabled={isLoading}
        style={{
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        {isLoading ? 'Updating...' : 'Update All Vehicles to Transportation Department'}
      </button>
    </div>
  );

  return (
    <div className="vehicles-container">
      <Sidebar />
      <div className="vehicles-content">
        <header className="page-header">
          <h1>Production Vehicles</h1>
          <p>Manage your productions vehicles</p>
        </header>
        <main>
          <button className="add-vehicle-btn" onClick={() => setShowAddForm(true)}>
            Add New Vehicle
          </button>
          {showAddForm && (
            <section className="add-vehicle">
              <h2>Add New Vehicle</h2>
              <form onSubmit={handleAddVehicle}>
                <div className="form-group">
                  <label htmlFor="make">Make:</label>
                  <input
                    type="text"
                    id="make"
                    name="make"
                    value={newVehicle.make}
                    onChange={handleInputChange}
                    placeholder="Make"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="model">Model:</label>
                  <input
                    type="text"
                    id="model"
                    name="model"
                    value={newVehicle.model}
                    onChange={handleInputChange}
                    placeholder="Model"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="year">Year:</label>
                  <input
                    type="number"
                    id="year"
                    name="year"
                    value={newVehicle.year}
                    onChange={handleInputChange}
                    placeholder="Year"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="licensePlate">License Plate:</label>
                  <input
                    type="text"
                    id="licensePlate"
                    name="licensePlate"
                    value={newVehicle.licensePlate}
                    onChange={handleInputChange}
                    placeholder="License Plate"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="licensePlateState">License Plate State:</label>
                  <select
                    id="licensePlateState"
                    name="licensePlateState"
                    value={newVehicle.licensePlateState}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select State</option>
                    {stateAbbreviations.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="unitNumber">Unit Number:</label>
                  <input
                    type="text"
                    id="unitNumber"
                    name="unitNumber"
                    value={newVehicle.unitNumber}
                    onChange={handleInputChange}
                    placeholder="Unit Number"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vehicleImages">Vehicle Pictures:</label>
                  <input
                    type="file"
                    id="vehicleImages"
                    onChange={handleImageChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="damageImages">Damage Pictures:</label>
                  <input
                    type="file"
                    id="damageImages"
                    onChange={handleDamageImageChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="documents">Document Pictures:</label>
                  <input
                    type="file"
                    id="documents"
                    onChange={handleDocumentChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="notes">Notes:</label>
                  <textarea
                    id="notes"
                    name="notes"
                    value={newVehicle.notes}
                    onChange={handleInputChange}
                    placeholder="Additional notes..."
                  />
                </div>
                <div className="button-group">
                  <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Adding Vehicle...' : 'Add Vehicle'}
                  </button>
                  <button type="button" onClick={() => setShowAddForm(false)} disabled={isLoading}>
                    Cancel
                  </button>
                </div>
              </form>
            </section>
          )}
          {hasAdminAccess(user?.role) && <UpdateDepartmentsButton />}
          <section className="vehicles-list">
            <h2>Vehicles in Current Production</h2>
            <ul>
              {vehiclesInProduction.map(vehicle => (
                <li 
                  key={vehicle.id}
                  className={`vehicle-card ${expandedVehicle?.id === vehicle.id ? 'expanded' : ''}`}
                  onClick={(e) => handleVehicleClick(vehicle, e)}
                >
                  <div className="vehicle-info">
                    {vehicle.imageUrls && vehicle.imageUrls.length > 0 && (
                      <div className="vehicle-preview-image">
                        <img src={vehicle.imageUrls[0]} alt={`${vehicle.make} ${vehicle.model}`} />
                      </div>
                    )}
                    <h3>{vehicle.make} {vehicle.model} ({vehicle.year})</h3>
                    <p>Unit Number: {vehicle.unitNumber}</p>
                    <p>License Plate: {vehicle.licensePlate}</p>
                    <p>Driver: {crewMembers.find(crew => crew.id === vehicle.assignedTo)?.name || 'Not assigned'}</p>
                    {vehicle.vendorId && (
                      <p>Vendor: {vendors.find(v => v.id === vehicle.vendorId)?.name || 'Unknown'}</p>
                    )}
                    {vehicle.rentalRate && (
                      <p>Rental Rate: ${vehicle.rentalRate}/{vehicle.rentalPeriod}</p>
                    )}
                    {vehicle.rentalStart && (
                      <p>Rental Start: {new Date(vehicle.rentalStart.seconds * 1000).toLocaleDateString()}</p>
                    )}
                    {vehicle.rentalEnd && (
                      <p>Rental End: {new Date(vehicle.rentalEnd.seconds * 1000).toLocaleDateString()}</p>
                    )}
                    <p>Status: {vehicle.status || 'Not set'}</p>
                    
                    <div className="vehicle-actions" onClick={e => e.stopPropagation()}>
                      <button 
                        className="edit-btn"
                        onClick={() => handleEditClick(vehicle)}
                      >
                        Edit Vehicle
                      </button>
                      <button 
                        className="remove-production-button"
                        onClick={() => handleRemoveFromProduction(vehicle.id)}
                      >
                        Remove from Production
                      </button>
                    </div>
                  </div>
                  
                  <div className={`vehicle-details ${expandedVehicle?.id === vehicle.id ? 'visible' : ''}`}>
                    <h4>Vehicle Details</h4>
                    <div className="vehicle-images-grid">
                      {vehicle.imageUrls?.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`${vehicle.make} ${vehicle.model} - Image ${index + 1}`}
                          className="vehicle-image"
                        />
                      ))}
                    </div>
                    {(!vehicle.imageUrls || vehicle.imageUrls.length === 0) && (
                      <p>No images available for this vehicle</p>
                    )}
                    
                    <div className="additional-details">
                      <h4>Additional Information</h4>
                      <p>Make: {vehicle.make}</p>
                      <p>Model: {vehicle.model}</p>
                      <p>Year: {vehicle.year}</p>
                      <p>License Plate State: {vehicle.licensePlateState}</p>
                      <p>Status: {vehicle.status}</p>
                    </div>
                  </div>
                </li>
              ))}
              {vehiclesInProduction.length === 0 && (
                <div className="no-vehicles-message">
                  No vehicles assigned to this production
                </div>
              )}
            </ul>
          </section>

          <section className="unassigned-vehicles">
            <h2>Available Vehicles</h2>
            <div className="unassigned-vehicles-list">
              {availableVehicles.map(vehicle => (
                <div 
                  key={vehicle.id} 
                  className={`unassigned-vehicle-card ${expandedVehicle?.id === vehicle.id ? 'expanded' : ''}`}
                  onClick={(e) => handleVehicleClick(vehicle, e)}
                >
                  <div className="vehicle-info">
                    {vehicle.imageUrls && vehicle.imageUrls.length > 0 && (
                      <div className="vehicle-preview-image">
                        <img src={vehicle.imageUrls[0]} alt={`${vehicle.make} ${vehicle.model}`} />
                      </div>
                    )}
                    <h3>{vehicle.make} {vehicle.model} ({vehicle.year})</h3>
                    <p>Unit Number: {vehicle.unitNumber || 'N/A'}</p>
                    <p>License Plate: {vehicle.licensePlate}</p>
                    <p>Driver: {crewMembers.find(crew => crew.id === vehicle.assignedTo)?.name || 'Not assigned'}</p>
                    {vehicle.vendorId && (
                      <p>Vendor: {vendors.find(v => v.id === vehicle.vendorId)?.name || 'Unknown'}</p>
                    )}
                    {vehicle.rentalRate && (
                      <p>Rental Rate: ${vehicle.rentalRate}/{vehicle.rentalPeriod}</p>
                    )}
                  </div>
                  
                  <div className={`vehicle-details ${expandedVehicle?.id === vehicle.id ? 'visible' : ''}`}>
                    <h4>Vehicle Details</h4>
                    <div className="vehicle-images-grid">
                      {vehicle.imageUrls?.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`${vehicle.make} ${vehicle.model} - Image ${index + 1}`}
                          className="vehicle-image"
                        />
                      ))}
                    </div>
                    {(!vehicle.imageUrls || vehicle.imageUrls.length === 0) && (
                      <p>No images available for this vehicle</p>
                    )}
                    
                    <div className="additional-details">
                      <h4>Additional Information</h4>
                      <p>Make: {vehicle.make}</p>
                      <p>Model: {vehicle.model}</p>
                      <p>Year: {vehicle.year}</p>
                      <p>License Plate State: {vehicle.licensePlateState}</p>
                      <p>Status: {vehicle.status}</p>
                      {/* Add any other vehicle details you want to show */}
                    </div>
                  </div>

                  <div className="vehicle-actions" onClick={e => e.stopPropagation()}>
                    <button 
                      className="edit-btn"
                      onClick={() => handleEditClick(vehicle)}
                    >
                      Edit Vehicle
                    </button>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedVehicle(vehicle);
                        const attachControls = document.getElementById('attach-controls');
                        if (attachControls) {
                          attachControls.scrollIntoView({ behavior: 'smooth' });
                        }
                      }}
                      className="select-vehicle-btn"
                    >
                      Select for Production
                    </button>
                  </div>
                </div>
              ))}
              {availableVehicles.length === 0 && (
                <div className="no-vehicles-message">
                  No unassigned vehicles available
                </div>
              )}
            </div>
          </section>

          <section className="attach-controls" id="attach-controls">
            {selectedVehicle && (
              <div className="attach-vehicle">
                <h2>Attach Vehicle to Production</h2>
                <div className="attach-controls">
                  <select
                    value={selectedProduction}
                    onChange={(e) => setSelectedProduction(e.target.value)}
                  >
                    <option value="">Select Production</option>
                    {productions.map(production => (
                      <option key={production.id} value={production.id}>
                        {production.name}
                      </option>
                    ))}
                  </select>
                  <button onClick={handleAttachToProduction}>
                    Attach to Production
                  </button>
                </div>
              </div>
            )}
          </section>
        </main>
      </div>
      {error && <div className="error-message">Error: {error}</div>}
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
      {hasAdminAccess(user.role) && (
        <button 
          onClick={updateAllVehiclesWithDepartment}
          style={{ marginBottom: '20px' }}
        >
          Update All Vehicles with Department
        </button>
      )}
      {editingVehicle && (
        <div className="edit-vehicle-modal" onClick={handleModalClick}>
          <div className="edit-vehicle-form">
            <h3>Edit Vehicle</h3>
            
            {/* Basic Info */}
            <div className="form-group license-plate-group">
              <label>License Plate:</label>
              <div className="license-plate-inputs">
                <input
                  type="text"
                  name="licensePlate"
                  value={editForm.licensePlate}
                  onChange={handleEditFormChange}
                  className="license-plate-input"
                />
                <select
                  name="licensePlateState"
                  value={editForm.licensePlateState}
                  onChange={handleEditFormChange}
                  className="state-select"
                >
                  <option value="">State</option>
                  {stateAbbreviations.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>Unit Number:</label>
              <input
                type="text"
                name="unitNumber"
                value={editForm.unitNumber}
                onChange={handleEditFormChange}
              />
            </div>
            
            {/* Vehicle Images Section */}
            <div className="vehicle-images-section">
              <h4>Vehicle Images</h4>
              <div className="images-grid">
                {editingVehicle.imageUrls?.map((imageUrl, index) => (
                  <div 
                    key={index} 
                    className="image-container"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, 'vehicle')}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index, 'vehicle')}
                  >
                    <img src={imageUrl} alt="Vehicle" className="vehicle-thumbnail" />
                    <button 
                      className="remove-image-btn"
                      onClick={() => handleRemoveImage(editingVehicle.id, imageUrl, index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="file"
                name="newImages"
                multiple
                accept="image/*"
                onChange={handleEditFormChange}
              />
            </div>

            {/* Damage Images Section */}
            <div className="vehicle-damage-section">
              <h4>Damage Images</h4>
              <div className="images-grid">
                {editingVehicle.damageImageUrls?.map((imageUrl, index) => (
                  <div 
                    key={index} 
                    className="image-container"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, 'damage')}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index, 'damage')}
                  >
                    <img src={imageUrl} alt="Damage" className="vehicle-thumbnail" />
                    <button 
                      className="remove-image-btn"
                      onClick={() => handleRemoveDamageImage(editingVehicle.id, imageUrl, index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="file"
                name="newDamageImages"
                multiple
                accept="image/*"
                onChange={handleEditFormChange}
              />
            </div>

            {/* Documents Section */}
            <div className="vehicle-documents-section">
              <h4>Documents</h4>
              <div className="documents-grid">
                {editingVehicle.documentUrls?.map((docUrl, index) => (
                  <div key={index} className="document-container">
                    <a href={docUrl} target="_blank" rel="noopener noreferrer">
                      Document {index + 1}
                    </a>
                    <button 
                      className="remove-document-btn"
                      onClick={() => handleRemoveDocument(editingVehicle.id, docUrl, index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="file"
                name="newDocuments"
                multiple
                accept="image/*,.pdf"
                onChange={handleEditFormChange}
              />
            </div>

            {/* Vendor and Rental Info */}
            <div className="form-group">
              <label>Vendor:</label>
              <select
                name="vendorId"
                value={editForm.vendorId}
                onChange={handleEditFormChange}
              >
                <option value="">Select Vendor</option>
                {vendors.map(vendor => (
                  <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                ))}
              </select>
            </div>
            <div className="rental-rate-container">
              <label>Rental Rate:</label>
              <div className="rental-rate-inputs">
                <span className="dollar-sign">$</span>
                <input
                  type="number"
                  name="rentalRate"
                  value={editForm.rentalRate}
                  onChange={handleEditFormChange}
                  className="rental-rate-input"
                />
                <select
                  name="rentalPeriod"
                  value={editForm.rentalPeriod}
                  onChange={handleEditFormChange}
                  className="rental-period-select"
                >
                  <option value="daily">Per Day</option>
                  <option value="weekly">Per Week</option>
                  <option value="monthly">Per Month</option>
                </select>
              </div>
            </div>

            {/* Driver Assignment */}
            <div className="form-group">
              <label>Assigned Driver:</label>
              <select
                name="assignedTo"
                value={editForm.assignedTo}
                onChange={handleEditFormChange}
              >
                <option value="">Select Driver</option>
                
                {/* Supervisors Group */}
                <optgroup label="Supervisors">
                  {crewMembers
                    .filter(crew => crew.role === 'supervisor' || crew.role === 'Supervisor')
                    .map(crew => (
                      <option key={crew.id} value={crew.id}>
                        {crew.name || 'Unknown'}
                      </option>
                    ))}
                </optgroup>

                {/* Crew Members Group */}
                <optgroup label="Crew Members">
                  {crewMembers
                    .filter(crew => crew.role === 'crewMember' || crew.role === 'Crew Member')
                    .map(crew => (
                      <option key={crew.id} value={crew.id}>
                        {crew.name || 'Unknown'}
                      </option>
                    ))}
                </optgroup>
              </select>
            </div>

            {/* Rental Dates */}
            <div className="rental-dates-container">
              <div className="form-group">
                <label>Rental Start Date:</label>
                <DatePicker
                  selected={editForm.rentalStart ? new Date(editForm.rentalStart) : null}
                  onChange={(date) => handleDateChange(date, 'rentalStart')}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  placeholderText="Select start date"
                  className="date-input"
                />
              </div>

              <div className="form-group">
                <label>Rental End Date:</label>
                <DatePicker
                  selected={editForm.rentalEnd ? new Date(editForm.rentalEnd) : null}
                  onChange={(date) => handleDateChange(date, 'rentalEnd')}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  placeholderText="Select end date"
                  className="date-input"
                />
              </div>
            </div>

            {/* Production Status */}
            <div className="production-status-container">
              <button 
                type="button"
                className="remove-production-button"
                onClick={() => {
                  handleRemoveFromProduction(editingVehicle.id);
                  setEditingVehicle(null);
                }}
              >
                Remove from Production
              </button>
            </div>

            {/* Action Buttons */}
            <div className="edit-form-buttons">
              <button onClick={handleUpdateVehicle}>Save Changes</button>
              <button onClick={() => setEditingVehicle(null)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VehiclesPage;