import React, { useState, useContext } from 'react';
import { createProduction } from '../services/productions';
import { AuthContext } from '../contexts/AuthContext';
import { PERMISSIONS, ROLE_PERMISSIONS } from '../constants/roles';
import { db } from '../firebase';
import '../styles/Management.css';

const ProductionManagement = () => {
    const { user } = useContext(AuthContext);
    const [productionData, setProductionData] = useState({
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        budget: '',
        location: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    // Check if user has permission to create productions
    const canCreateProduction = user?.role && 
        ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.CREATE_PRODUCTION);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        try {
            if (!canCreateProduction) {
                throw new Error('You do not have permission to create productions');
            }

            const productionId = await createProduction(user.uid, productionData);
            setSuccess(`Production created successfully! ID: ${productionId}`);
            setProductionData({
                title: '',
                description: '',
                startDate: '',
                endDate: '',
                budget: '',
                location: ''
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (!canCreateProduction) {
        return <div className="alert alert-warning">You do not have permission to create productions.</div>;
    }

    return (
        <div className="production-management">
            <h2>Create New Production</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            
            <form onSubmit={handleSubmit} className="production-form">
                <div className="form-group">
                    <label>Title</label>
                    <input
                        type="text"
                        className="form-control"
                        value={productionData.title}
                        onChange={(e) => setProductionData({...productionData, title: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Description</label>
                    <textarea
                        className="form-control"
                        value={productionData.description}
                        onChange={(e) => setProductionData({...productionData, description: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Start Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={productionData.startDate}
                        onChange={(e) => setProductionData({...productionData, startDate: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>End Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={productionData.endDate}
                        onChange={(e) => setProductionData({...productionData, endDate: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Budget</label>
                    <input
                        type="number"
                        className="form-control"
                        value={productionData.budget}
                        onChange={(e) => setProductionData({...productionData, budget: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Location</label>
                    <input
                        type="text"
                        className="form-control"
                        value={productionData.location}
                        onChange={(e) => setProductionData({...productionData, location: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Production'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProductionManagement;
