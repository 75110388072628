import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, query, where } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import Sidebar from '../components/Sidebar';
import './Vehicles.css';
import '../styles/SharedStyles.css';

function Vehicles() {
  const [vehicles, setVehicles] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const { user } = useAuth();
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    licensePlate: '',
    status: 'available',
    assignedTo: '',
    productionId: ''
  });
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedProduction, setSelectedProduction] = useState('');

  useEffect(() => {
    if (user) {
      console.log("Current user:", user);
      fetchVehicles();
      fetchVendors();
      fetchCrewMembers();
      fetchProductions();
    } else {
      console.log("No user logged in");
      setIsLoading(false);
    }
  }, [user]);

  const fetchVehicles = async () => {
    setIsLoading(true);
    try {
      console.log("Fetching vehicles...");
      const db = getFirestore();
      const vehiclesCollection = collection(db, 'vehicles');
      const querySnapshot = await getDocs(vehiclesCollection);
      console.log("Vehicles snapshot:", querySnapshot);
      const vehiclesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Parsed vehicles data:", vehiclesData);
      setVehicles(vehiclesData);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVendors = async () => {
    try {
      const db = getFirestore();
      const vendorsCollection = collection(db, 'vendors');
      const querySnapshot = await getDocs(vendorsCollection);
      const vendorsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorsData);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError(error.message);
    }
  };

  const fetchCrewMembers = async () => {
    try {
      const db = getFirestore();
      const crewMembersCollection = collection(db, 'users');
      const querySnapshot = await getDocs(crewMembersCollection);
      const crewMembersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCrewMembers(crewMembersData);
    } catch (error) {
      console.error('Error fetching crew members:', error);
      setError(error.message);
    }
  };

  const fetchProductions = async () => {
    try {
      const db = getFirestore();
      const productionsCollection = collection(db, 'productions');
      const querySnapshot = await getDocs(productionsCollection);
      const productionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProductions(productionsData);
    } catch (error) {
      console.error('Error fetching productions:', error);
      setError(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle({ ...newVehicle, [name]: value });
  };

  const handleAddVehicle = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      const vehicleData = { ...newVehicle };
      
      // If the user is a crew member, assign the vehicle to their production
      if (user.role === 'crewMember') {
        const crewMember = crewMembers.find(cm => cm.id === user.uid);
        if (crewMember && crewMember.productionId) {
          vehicleData.productionId = crewMember.productionId;
        }
      }
      
      await addDoc(collection(db, 'vehicles'), vehicleData);
      setNewVehicle({
        make: '',
        model: '',
        year: '',
        licensePlate: '',
        status: 'available',
        assignedTo: '',
        productionId: ''
      });
      fetchVehicles(); // Refresh the vehicle list
    } catch (error) {
      console.error("Error adding vehicle: ", error);
      setError(error.message);
    }
  };

  const handleAssignVehicle = async (vehicleId, crewMemberId, productionId) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: crewMemberId,
        productionId: productionId,
        status: 'in_use'
      });
      fetchVehicles();
    } catch (error) {
      console.error("Error assigning vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveFromProduction = async (vehicleId) => {
    if (user.role !== 'supervisor' && user.role !== 'productionAdmin' && user.role !== 'admin') {
      setError("Only supervisors, production admins, or admins can remove vehicles from production.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: '',
        productionId: '',
        status: 'available'
      });
      fetchVehicles();
    } catch (error) {
      console.error("Error removing vehicle from production: ", error);
      setError(error.message);
    }
  };

  const handleAttachToProduction = async () => {
    if (!selectedVehicle || !selectedProduction) {
      setError("Please select both a vehicle and a production.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', selectedVehicle), {
        productionId: selectedProduction,
        status: 'in_use'
      });
      fetchVehicles();
      setSelectedVehicle(null);
      setSelectedProduction('');
    } catch (error) {
      console.error("Error attaching vehicle to production: ", error);
      setError(error.message);
    }
  };

  if (!user) {
    return <div className="error-message">Please log in to view this page.</div>;
  }

  return (
    <div className="vehicles-container">
      <Sidebar />
      <div className="vehicles-content">
        <header className="page-header">
          <h1>Crew Vehicles</h1>
          <p>Manage production vehicles</p>
        </header>
        <main>
          {(user.role === 'crewMember' || user.role === 'supervisor' || user.role === 'admin' || user.role === 'productionAdmin') && (
            <section className="add-vehicle">
              <h2>Add New Vehicle</h2>
              <form onSubmit={handleAddVehicle}>
                <input
                  type="text"
                  name="make"
                  value={newVehicle.make}
                  onChange={handleInputChange}
                  placeholder="Make"
                  required
                />
                <input
                  type="text"
                  name="model"
                  value={newVehicle.model}
                  onChange={handleInputChange}
                  placeholder="Model"
                  required
                />
                <input
                  type="number"
                  name="year"
                  value={newVehicle.year}
                  onChange={handleInputChange}
                  placeholder="Year"
                  required
                />
                <input
                  type="text"
                  name="licensePlate"
                  value={newVehicle.licensePlate}
                  onChange={handleInputChange}
                  placeholder="License Plate"
                  required
                />
                <button type="submit">Add Vehicle</button>
              </form>
            </section>
          )}
          {(user.role === 'supervisor' || user.role === 'productionAdmin' || user.role === 'admin') && (
            <section className="attach-vehicle">
              <h2>Attach Vehicle to Production</h2>
              <select
                value={selectedVehicle}
                onChange={(e) => setSelectedVehicle(e.target.value)}
              >
                <option value="">Select Vehicle</option>
                {vehicles.filter(v => !v.productionId).map((vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.make} {vehicle.model} ({vehicle.licensePlate})
                  </option>
                ))}
              </select>
              <select
                value={selectedProduction}
                onChange={(e) => setSelectedProduction(e.target.value)}
              >
                <option value="">Select Production</option>
                {productions.map((production) => (
                  <option key={production.id} value={production.id}>
                    {production.name}
                  </option>
                ))}
              </select>
              <button onClick={handleAttachToProduction}>Attach to Production</button>
            </section>
          )}
          <section className="vehicle-list">
            <h2>Vehicle List</h2>
            {vehicles.length > 0 ? (
              <ul>
                {vehicles.map((vehicle) => (
                  <li key={vehicle.id}>
                    <h3>{vehicle.make} {vehicle.model} ({vehicle.year})</h3>
                    <p>License Plate: {vehicle.licensePlate}</p>
                    <p>Status: {vehicle.status}</p>
                    <p>Production: {productions.find(p => p.id === vehicle.productionId)?.name || 'Not assigned'}</p>
                    {vehicle.status === 'in_use' && (
                      <>
                        <p>Assigned to: {crewMembers.find(m => m.id === vehicle.assignedTo)?.name}</p>
                        {(user.role === 'supervisor' || user.role === 'productionAdmin' || user.role === 'admin') && (
                          <button onClick={() => handleRemoveFromProduction(vehicle.id)}>
                            Remove from Production
                          </button>
                        )}
                      </>
                    )}
                    {vehicle.status === 'available' && (user.role === 'supervisor' || user.role === 'productionAdmin' || user.role === 'admin') && (
                      <select
                        onChange={(e) => handleAssignVehicle(vehicle.id, e.target.value.split(',')[0], e.target.value.split(',')[1])}
                      >
                        <option value="">Assign to Crew Member</option>
                        {crewMembers.map((member) => (
                          productions.map((production) => (
                            <option key={`${member.id}-${production.id}`} value={`${member.id},${production.id}`}>
                              {member.name} - {production.name}
                            </option>
                          ))
                        ))}
                      </select>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No vehicles available.</p>
            )}
          </section>
        </main>
      </div>
      {error && <div className="error-message">Error: {error}</div>}
    </div>
  );
}

export default Vehicles;