import { collection, doc, setDoc, getDoc, getDocs, query, where, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Production-centric database structure
const COLLECTIONS = {
  PRODUCTIONS: 'productions',
  USERS: 'users',
  PRODUCTION_USERS: 'productionUsers',
  DEPARTMENTS: 'departments',
  VEHICLES: 'vehicles',
  TIME_ENTRIES: 'timeEntries',
  DISPATCH: 'dispatch',
  NOTIFICATIONS: 'notifications',
};

// Standard departments for all productions
const STANDARD_DEPARTMENTS = [
  'Script Supervisor',
  'Sound',
  'Costume',
  'Medic',
  'Craft Services',
  'Stunts',
  'Casting',
  'Electric',
  'Studio Teacher',
  'Production',
  'Assistant Directors',
  'Transportation',
  'Construction',
  'Make-up',
  'Directors',
  'Video',
  'Production company',
  'Visual Effects',
  'Producers & Writers',
  'Special Effects',
  'Studio',
  'Camera',
  'Grip',
  'Locations',
  'Accounting',
  'Facilities',
  'Property',
  'Set Decoration',
  'Post Production',
  'Catering',
  'Greens',
  'Hair',
  'Art Department'
];

/**
 * Creates a new production with initial structure
 */
export const createProduction = async (productionData) => {
  try {
    // Create the main production document
    const productionRef = doc(collection(db, COLLECTIONS.PRODUCTIONS));
    const productionId = productionRef.id;
    
    // Create standard departments structure
    const departments = STANDARD_DEPARTMENTS.map(name => ({
      name,
      description: `${name} Department`,
      headOfDepartment: '',
      leaders: [],
      members: [],
      tasks: [],
      notes: '',
      status: 'pending',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }));

    // Prepare production data
    const production = {
      ...productionData,
      id: productionId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: productionData.status || 'pre-production',
      crew: [],
      departments: departments // Ensure departments are included
    };

    // Save the production document
    await setDoc(productionRef, production);

    // Initialize subcollections
    const subCollections = [
      'crew',
      'vehicles',
      'dispatch',
      'timeEntries',
      'budget',
      'notifications'
    ];

    // Initialize each subcollection with a metadata document
    const initPromises = subCollections.map(subCollection => {
      const metadataRef = doc(collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/${subCollection}`), 'metadata');
      return setDoc(metadataRef, {
        initialized: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
    });

    await Promise.all(initPromises);

    // Return the created production
    return production;
  } catch (error) {
    console.error('Error creating production:', error);
    throw new Error(`Failed to create production: ${error.message}`);
  }
};

/**
 * Assigns a user to a production with specific role
 */
export const assignUserToProduction = async (userId, productionId, role) => {
  try {
    const assignmentRef = doc(collection(db, COLLECTIONS.PRODUCTION_USERS));
    await setDoc(assignmentRef, {
      userId,
      productionId,
      role,
      assignedAt: new Date()
    });
    return assignmentRef.id;
  } catch (error) {
    console.error('Error assigning user to production:', error);
    throw error;
  }
};

/**
 * Gets all productions a user has access to
 * Admins can see all productions, regular users only see their assigned ones
 */
export const getUserProductions = async (userId, userRole) => {
  try {
    // Admins can see all productions
    if (userRole === 'admin' || userRole === 'superAdmin' || userRole === 'productionAdmin') {
      const snapshot = await getDocs(collection(db, COLLECTIONS.PRODUCTIONS));
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }

    // Regular users only see their assigned productions
    const q = query(
      collection(db, COLLECTIONS.PRODUCTION_USERS),
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    const productionIds = snapshot.docs.map(doc => doc.data().productionId);
    
    const productions = [];
    for (const pid of productionIds) {
      const prodDoc = await getDoc(doc(db, COLLECTIONS.PRODUCTIONS, pid));
      if (prodDoc.exists()) {
        productions.push({ id: prodDoc.id, ...prodDoc.data() });
      }
    }
    return productions;
  } catch (error) {
    console.error('Error getting user productions:', error);
    throw error;
  }
};

/**
 * Creates a time entry within a specific production
 */
export const createTimeEntry = async (productionId, timeData) => {
  try {
    const timeEntryRef = doc(collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/timeEntries`));
    await setDoc(timeEntryRef, {
      ...timeData,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    return timeEntryRef.id;
  } catch (error) {
    console.error('Error creating time entry:', error);
    throw error;
  }
};

/**
 * Gets all time entries for a user within a production
 */
export const getProductionTimeEntries = async (productionId, userId) => {
  try {
    const q = query(
      collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/timeEntries`),
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting production time entries:', error);
    throw error;
  }
};

/**
 * Creates a department within a production
 */
export const createDepartment = async (productionId, departmentData) => {
  try {
    const deptRef = doc(collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/departments`));
    await setDoc(deptRef, {
      ...departmentData,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    return deptRef.id;
  } catch (error) {
    console.error('Error creating department:', error);
    throw error;
  }
};

/**
 * Gets all departments within a production
 */
export const getProductionDepartments = async (productionId) => {
  try {
    const snapshot = await getDocs(collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/departments`));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting production departments:', error);
    throw error;
  }
};

/**
 * Checks if a user has access to a specific production
 */
export const checkProductionAccess = async (userId, productionId) => {
  try {
    const q = query(
      collection(db, COLLECTIONS.PRODUCTION_USERS),
      where('userId', '==', userId),
      where('productionId', '==', productionId)
    );
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  } catch (error) {
    console.error('Error checking production access:', error);
    throw error;
  }
};

/**
 * Gets a user's role within a specific production
 */
export const getUserProductionRole = async (userId, productionId) => {
  try {
    const q = query(
      collection(db, COLLECTIONS.PRODUCTION_USERS),
      where('userId', '==', userId),
      where('productionId', '==', productionId)
    );
    const snapshot = await getDocs(q);
    if (snapshot.empty) return null;
    return snapshot.docs[0].data().role;
  } catch (error) {
    console.error('Error getting user production role:', error);
    throw error;
  }
};

/**
 * Gets all crew members for a specific production
 */
export const getProductionCrew = async (productionId) => {
  try {
    const q = query(
      collection(db, COLLECTIONS.PRODUCTION_USERS),
      where('productionId', '==', productionId)
    );
    const snapshot = await getDocs(q);
    const crewIds = snapshot.docs.map(doc => doc.data().userId);
    
    const crew = [];
    for (const uid of crewIds) {
      const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, uid));
      if (userDoc.exists()) {
        crew.push({ id: userDoc.id, ...userDoc.data() });
      }
    }
    return crew;
  } catch (error) {
    console.error('Error getting production crew:', error);
    throw error;
  }
};

/**
 * Updates a user's role in a production
 */
export const updateProductionRole = async (userId, productionId, newRole) => {
  try {
    const q = query(
      collection(db, COLLECTIONS.PRODUCTION_USERS),
      where('userId', '==', userId),
      where('productionId', '==', productionId)
    );
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      await updateDoc(doc(db, COLLECTIONS.PRODUCTION_USERS, snapshot.docs[0].id), {
        role: newRole,
        updatedAt: new Date()
      });
    }
  } catch (error) {
    console.error('Error updating production role:', error);
    throw error;
  }
};

/**
 * Creates a production-specific notification
 */
export const createProductionNotification = async (productionId, notification) => {
  try {
    const notificationRef = doc(collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/notifications`));
    await setDoc(notificationRef, {
      ...notification,
      createdAt: new Date(),
      read: false
    });
    return notificationRef.id;
  } catch (error) {
    console.error('Error creating production notification:', error);
    throw error;
  }
};

/**
 * Gets unread notifications for a user in a production
 */
export const getProductionNotifications = async (productionId, userId) => {
  try {
    const q = query(
      collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/notifications`),
      where('userId', '==', userId),
      where('read', '==', false)
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting production notifications:', error);
    throw error;
  }
};

/**
 * Updates production details
 */
export const updateProduction = async (productionId, updates) => {
  try {
    const productionRef = doc(db, COLLECTIONS.PRODUCTIONS, productionId);
    await updateDoc(productionRef, {
      ...updates,
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error updating production:', error);
    throw error;
  }
};

/**
 * Updates an existing production with standard departments if they don't exist
 */
export const updateProductionWithStandardDepartments = async (productionId) => {
  try {
    const productionRef = doc(db, COLLECTIONS.PRODUCTIONS, productionId);
    const productionDoc = await getDoc(productionRef);
    
    if (!productionDoc.exists()) {
      throw new Error('Production not found');
    }

    const productionData = productionDoc.data();
    const existingDepartments = productionData.departments || [];
    const existingDepartmentNames = new Set(existingDepartments.map(d => d.name));

    // Create any missing standard departments
    const newDepartments = STANDARD_DEPARTMENTS
      .filter(name => !existingDepartmentNames.has(name))
      .map(name => ({
        name,
        description: `${name} Department`,
        headOfDepartment: '',
        leaders: [],
        members: [],
        tasks: [],
        notes: '',
        status: 'pending',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }));

    // Combine existing and new departments
    const updatedDepartments = [...existingDepartments, ...newDepartments];

    // Update the production
    await updateDoc(productionRef, {
      departments: updatedDepartments,
      updatedAt: new Date().toISOString()
    });

    return updatedDepartments;
  } catch (error) {
    console.error('Error updating production with standard departments:', error);
    throw error;
  }
};

/**
 * Gets production statistics
 */
export const getProductionStats = async (productionId) => {
  try {
    // First ensure the production has standard departments
    await updateProductionWithStandardDepartments(productionId);

    // Get all users
    const usersSnapshot = await getDocs(collection(db, COLLECTIONS.USERS));
    
    // Filter and count crew members for this production
    const crewCount = usersSnapshot.docs.filter(doc => {
      const data = doc.data();
      // Check both production and productionId fields
      const isInProduction = (
        data.production === productionId || 
        data.productionId === productionId
      );
      
      // Check all possible role variations
      const isCrewOrSupervisor = (
        data.role === 'Crew Member' || 
        data.role === 'crew_member' || 
        data.role === 'Supervisor' ||
        data.role?.toLowerCase()?.includes('crew') ||
        data.role?.toLowerCase()?.includes('supervisor')
      );
      
      // Exclude admin roles and waitlisted users
      const isNotAdmin = (
        data.role !== 'admin' && 
        data.role !== 'production_admin' && 
        !data.waitlisted
      );

      return isInProduction && isCrewOrSupervisor && isNotAdmin;
    }).length;

    // Get departments count from production document
    const productionDoc = await getDoc(doc(db, COLLECTIONS.PRODUCTIONS, productionId));
    let departmentsCount = 0;
    
    if (productionDoc.exists()) {
      const productionData = productionDoc.data();
      departmentsCount = productionData.departments?.length || 0;
    }

    // Get time entries for the last week
    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    
    // Get all time entries for this production
    const timeEntriesQuery = query(
      collection(db, `${COLLECTIONS.PRODUCTIONS}/${productionId}/timeEntries`),
      where('date', '>=', weekAgo)
    );
    const timeEntriesSnapshot = await getDocs(timeEntriesQuery);
    
    // Get time entries count
    const timeEntriesCount = timeEntriesSnapshot.docs.length;

    return {
      crewCount,
      departmentsCount,
      recentTimeEntries: timeEntriesCount,
      lastUpdated: new Date()
    };
  } catch (error) {
    console.error('Error getting production stats:', error);
    return {
      crewCount: 0,
      departmentsCount: 0,
      recentTimeEntries: 0,
      lastUpdated: new Date()
    };
  }
};
