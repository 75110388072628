import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

async function updateVehicleDepartments() {
  const db = getFirestore();
  
  try {
    // Get all vehicles
    const vehiclesRef = collection(db, 'vehicles');
    const vehiclesSnapshot = await getDocs(vehiclesRef);
    
    let updateCount = 0;
    const updatePromises = [];

    vehiclesSnapshot.forEach((vehicleDoc) => {
      const updatePromise = updateDoc(doc(db, 'vehicles', vehicleDoc.id), {
        department: 'Transportation'
      });
      updatePromises.push(updatePromise);
      updateCount++;
    });

    await Promise.all(updatePromises);
    console.log(`Successfully updated ${updateCount} vehicles to Transportation department`);
    
  } catch (error) {
    console.error('Error updating vehicles:', error);
  }
}

export default updateVehicleDepartments;
