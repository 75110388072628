import React, { useState, useEffect, useContext, useCallback } from 'react';
import { collection, query, where, getDocs, orderBy, limit, Timestamp, getDoc, doc, updateDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import './Notifications.css';
import '../styles/SharedStyles.css';
import Toast from '../components/Toast';
import { debounce } from 'lodash';

// Add this helper function near the top of the file
const formatNotificationType = (type) => {
  switch (type) {
    case 'sentDispatchedTime':
      return 'Dispatched Time';
    case 'sentDispatchedRun':
      return 'Dispatched Run';
    case 'dispatchedTime':
      return 'Time Entry';
    case 'dispatchedRun':
      return 'Run Request';
    default:
      return type.charAt(0).toUpperCase() + type.slice(1); // Capitalize first letter
  }
};

// Move formatDate outside of NotificationItem to be accessible by both components
const formatDate = (timestamp) => {
  if (!timestamp || !timestamp.toDate) return 'No date';
  const date = timestamp.toDate();
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(date);
};

// Helper function to parse our formatted date string back to Date object
const parseFormattedDate = (dateString) => {
  if (!dateString) return null;
  const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
  const date = new Date(year, month - 1, day);  // month is 0-based in Date constructor
  return date;
};

// Helper function to create a date with local timezone
const createLocalDate = (year, month, day, hours = 0, minutes = 0, seconds = 0, ms = 0) => {
  const date = new Date(year, month - 1, day, hours, minutes, seconds, ms);
  return date;
};

// Helper function for formatting time
const formatTime = (time) => {
  if (!time) return 'N/A';
  
  // If time is a number (float)
  if (typeof time === 'number') {
    const isNextDay = time >= 24;
    const adjustedHours = isNextDay ? time - 24 : time;
    const hours = Math.floor(adjustedHours);
    const minutes = Math.round((adjustedHours - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${isNextDay ? ' (Next Day)' : ''}`;
  }
  
  // If time is a string in HH:MM format
  if (typeof time === 'string' && time.includes(':')) {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const isNextDay = hour >= 24;
    const adjustedHour = isNextDay ? hour - 24 : hour;
    return `${adjustedHour.toString().padStart(2, '0')}:${minutes}${isNextDay ? ' (Next Day)' : ''}`;
  }
  
  return 'Invalid Time';
};

const NotificationItem = React.memo(({ notification, onAcknowledge, isSentNotification }) => {
  const [recipientName, setRecipientName] = useState('Unknown recipient');
  const [senderName, setSenderName] = useState('Unknown sender');
  const [acknowledged, setAcknowledged] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(notification.status || 'Pending');

  useEffect(() => {
    const fetchNames = async () => {
      try {
        console.log('Fetching names for notification:', {
          id: notification.id,
          type: notification.type,
          createdBy: notification.createdBy,
          supervisorId: notification.supervisorId,
          userId: notification.userId,
          assignedTo: notification.assignedTo,
          date: notification.date?.toDate?.()
        });
        
        // For time entries
        if (notification.type === 'dispatchedTime' || notification.type === 'sentDispatchedTime') {
          // Fetch creator/sender name
          const creatorId = notification.createdBy || notification.supervisorId;
          console.log('Time Entry - Creator ID:', creatorId);
          
          if (creatorId) {
            const creatorDoc = await getDoc(doc(db, 'users', creatorId));
            if (creatorDoc.exists()) {
              const creatorData = creatorDoc.data();
              console.log('Creator data found:', {
                id: creatorId,
                displayName: creatorData.displayName,
                name: creatorData.name,
                email: creatorData.email
              });
              setSenderName(creatorData.displayName || creatorData.name || creatorData.email || 'Unknown sender');
            } else {
              console.log('Creator document does not exist for ID:', creatorId);
            }
          } else {
            console.log('No creator ID found for time entry:', notification.id);
          }

          // Fetch recipient name
          const recipientId = notification.userId;
          console.log('Time Entry - Recipient ID:', recipientId);
          
          if (recipientId) {
            const recipientDoc = await getDoc(doc(db, 'users', recipientId));
            if (recipientDoc.exists()) {
              const recipientData = recipientDoc.data();
              console.log('Recipient data found:', {
                id: recipientId,
                displayName: recipientData.displayName,
                name: recipientData.name,
                email: recipientData.email
              });
              setRecipientName(recipientData.displayName || recipientData.name || recipientData.email || 'Unknown recipient');
            } else {
              console.log('Recipient document does not exist for ID:', recipientId);
            }
          } else {
            console.log('No recipient ID found for time entry:', notification.id);
          }
        }
        
        // For dispatch runs
        if (notification.type === 'dispatchedRun' || notification.type === 'sentDispatchedRun') {
          // Fetch creator/sender name
          const creatorId = notification.createdBy;
          console.log('Dispatch Run - Creator ID:', creatorId);
          
          if (creatorId) {
            const creatorDoc = await getDoc(doc(db, 'users', creatorId));
            if (creatorDoc.exists()) {
              const creatorData = creatorDoc.data();
              console.log('Creator data found:', {
                id: creatorId,
                displayName: creatorData.displayName,
                name: creatorData.name,
                email: creatorData.email
              });
              setSenderName(creatorData.displayName || creatorData.name || creatorData.email || 'Unknown sender');
            } else {
              console.log('Creator document does not exist for ID:', creatorId);
            }
          } else {
            console.log('No creator ID found for dispatch run:', notification.id);
          }

          // Fetch recipient name
          const recipientId = notification.assignedTo;
          console.log('Dispatch Run - Recipient ID:', recipientId);
          
          if (recipientId) {
            const recipientDoc = await getDoc(doc(db, 'users', recipientId));
            if (recipientDoc.exists()) {
              const recipientData = recipientDoc.data();
              console.log('Recipient data found:', {
                id: recipientId,
                displayName: recipientData.displayName,
                name: recipientData.name,
                email: recipientData.email
              });
              setRecipientName(recipientData.displayName || recipientData.name || recipientData.email || 'Unknown recipient');
            } else {
              console.log('Recipient document does not exist for ID:', recipientId);
            }
          } else {
            console.log('No recipient ID found for dispatch run:', notification.id);
          }
        }
      } catch (err) {
        console.error('Error fetching user names:', err);
        setError('Failed to fetch user names');
      }
    };

    fetchNames();
  }, [notification]);

  // Update currentStatus when notification changes
  useEffect(() => {
    setCurrentStatus(notification.status || 'Pending');
  }, [notification.status]);

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleString();
    }
    return 'Unknown date';
  };

  const handleStatusChange = async (newStatus) => {
    try {
      await updateDoc(doc(db, 'dispatchedRuns', notification.id), {
        status: newStatus,
        updatedAt: serverTimestamp()
      });
      setCurrentStatus(newStatus);
      setToast({ 
        message: `Status updated to ${newStatus}`, 
        type: 'success'  // Green toast for success
      });
    } catch (error) {
      console.error('Error updating status:', error);
      setToast({ 
        message: `Failed to update status: ${error.message}`, 
        type: 'error'  // Red toast for error
      });
      // Revert to previous status if update fails
      setCurrentStatus(notification.status);
    }
  };

  const handleAcknowledge = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setError(null);

    try {
      let docRef;
      
      // Determine which collection to update based on notification type
      if (notification.type.includes('Run')) {
        docRef = doc(db, 'dispatchedRuns', notification.id);
      } else if (notification.type.includes('Time')) {
        docRef = doc(db, 'timeEntries', notification.id);
      } else {
        docRef = doc(db, 'notifications', notification.id);
      }

      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error(`${notification.type} document not found`);
      }

      await updateDoc(docRef, {
        acknowledged: true,
        status: 'Acknowledged',
        acknowledgedAt: serverTimestamp()
      });

      setAcknowledged(true);
      setCurrentStatus('Acknowledged');
      setToast({ 
        message: `${notification.type} acknowledged successfully`, 
        type: 'success' 
      });
      
      if (onAcknowledge) {
        onAcknowledge(notification.id);
      }
    } catch (error) {
      console.error('Error acknowledging notification:', error);
      setToast({ 
        message: `Failed to acknowledge: ${error.message}`, 
        type: 'error' 
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // Add error message display
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // Clear error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <tr 
        className={`notification-row ${notification.type} ${acknowledged ? 'acknowledged' : ''}`}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <td>{notification.type.includes('Run') ? 'Assigned Run' : 'Time Entry'}</td>
        <td>{notification.details}</td>
        <td>
          {isSentNotification ? recipientName : senderName}
        </td>
        <td>{formatDate(notification.date)}</td>
        <td>
          {notification.type.includes('Time') ? (
            !isSentNotification ? (
              notification.acknowledged || acknowledged ? (
                'Acknowledged'
              ) : (
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAcknowledge();
                  }}
                  className={`acknowledge-button ${acknowledged ? 'acknowledged' : ''}`}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Acknowledge'}
                </button>
              )
            ) : (
              notification.acknowledged ? 'Acknowledged' : 'Pending'
            )
          ) : (
            isSentNotification ? (
              notification.status === 'Pending' || !notification.status ? 'Pending' : notification.status
            ) : (
              notification.acknowledged || acknowledged ? (
                <select
                  value={currentStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  className="status-select"
                >
                  <option value="Acknowledged">Acknowledged</option>
                  <option value="In Route">In Route</option>
                  <option value="Picked Up">Picked Up</option>
                  <option value="Dropped Off">Dropped Off</option>
                </select>
              ) : (
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAcknowledge();
                  }}
                  className={`acknowledge-button ${acknowledged ? 'acknowledged' : ''}`}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Acknowledge'}
                </button>
              )
            )
          )}
        </td>
      </tr>
      {isExpanded && (
        <tr className="expanded-notification">
          <td colSpan="5">
            <div className="notification-details">
              {notification.type.includes('Time') && (
                <>
                  <p><strong>Call Time:</strong> {formatTime(notification.startTime)}</p>
                  <p><strong>Date:</strong> {formatDate(notification.date)}</p>
                </>
              )}
              {notification.type.includes('Run') && (
                <>
                  <p><strong>Run Type:</strong> {notification.runType}</p>
                  <p><strong>Address:</strong> {notification.address}</p>
                  <p><strong>Description:</strong> {notification.itemDescription}</p>
                  <p><strong>Status:</strong> {notification.status}</p>
                  {notification.pictureUrl && (
                    <div className="notification-image">
                      <img src={notification.pictureUrl} alt="Run" />
                    </div>
                  )}
                </>
              )}
              {notification.message && (
                <p><strong>Message:</strong> {notification.message}</p>
              )}
            </div>
          </td>
        </tr>
      )}
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </>
  );
});

function Notifications() {
  const { user } = useContext(AuthContext);
  const [receivedNotifications, setReceivedNotifications] = useState([]);
  const [sentNotifications, setSentNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredNotifications, setFilteredNotifications] = useState({
    received: [],
    sent: []
  });
  const [showAllNotifications, setShowAllNotifications] = useState(true);
  const [notificationType, setNotificationType] = useState('all');
  const [toast, setToast] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState({});
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setPage(1);
      fetchNotifications();
    }
  }, [user, notificationType]);

  useEffect(() => {
    const updateExistingTimeEntries = async () => {
      try {
        // Query all time entry notifications
        const timeEntriesQuery = query(
          collection(db, 'notifications'),
          where('type', '==', 'Time Entry')
        );
        
        const timeEntriesSnapshot = await getDocs(timeEntriesQuery);
        
        // Update each time entry to have status 'Pending' if not already set
        const batch = [];
        timeEntriesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === 'Completed' || !data.status) {
            batch.push(updateDoc(doc.ref, {
              status: 'Pending'
            }));
          }
        });
        
        await Promise.all(batch);
        console.log('Successfully updated existing time entries');
      } catch (error) {
        console.error('Error updating time entries:', error);
      }
    };

    updateExistingTimeEntries();
  }, []); // Run once when component mounts

  const refreshNotifications = () => {
    setPage(1);
    setHasMore(true);
    fetchNotifications();
  };

  const handleDateFilter = useCallback(() => {
    if (!dateRange.startDate || !dateRange.endDate) {
      console.log('No date range selected');
      return;
    }

    try {
      // Fetch all notifications when using date filter
      fetchNotifications(true).then(() => {
        console.log('Applying date filter:', dateRange);

        // Parse the input dates and set to start/end of day
        const parsedStart = parseFormattedDate(dateRange.startDate);
        const parsedEnd = parseFormattedDate(dateRange.endDate);
        
        // Create start and end dates in local timezone
        const startDate = createLocalDate(
          parsedStart.getFullYear(),
          parsedStart.getMonth() + 1,
          parsedStart.getDate(),
          0, 0, 0, 0
        );
        
        const endDate = createLocalDate(
          parsedEnd.getFullYear(),
          parsedEnd.getMonth() + 1,
          parsedEnd.getDate(),
          23, 59, 59, 999
        );

        console.log('Date range:', {
          startDate: formatDate(Timestamp.fromDate(startDate)),
          endDate: formatDate(Timestamp.fromDate(endDate)),
          startDateObj: startDate,
          endDateObj: endDate
        });

        const dateFilteredResults = {
          received: receivedNotifications.filter(notification => {
            if (!notification.date) return false;
            const notifDate = notification.date.toDate();
            const isInRange = notifDate >= startDate && notifDate <= endDate;
            console.log('Checking received notification:', {
              id: notification.id,
              notifDate: formatDate(notification.date),
              notifDateObj: notifDate,
              isInRange,
              comparison: {
                isAfterStart: notifDate >= startDate,
                isBeforeEnd: notifDate <= endDate
              }
            });
            return isInRange;
          }),
          sent: sentNotifications.filter(notification => {
            if (!notification.date) return false;
            const notifDate = notification.date.toDate();
            const isInRange = notifDate >= startDate && notifDate <= endDate;
            console.log('Checking sent notification:', {
              id: notification.id,
              notifDate: formatDate(notification.date),
              notifDateObj: notifDate,
              isInRange,
              comparison: {
                isAfterStart: notifDate >= startDate,
                isBeforeEnd: notifDate <= endDate
              }
            });
            return isInRange;
          })
        };

        console.log('Filtered results:', {
          receivedCount: dateFilteredResults.received.length,
          sentCount: dateFilteredResults.sent.length
        });

        setFilteredNotifications(dateFilteredResults);
        setShowAllNotifications(false);
      });
    } catch (error) {
      console.error('Error applying date filter:', error);
      setToast({ message: 'Error applying date filter', type: 'error' });
    }
  }, [dateRange, receivedNotifications, sentNotifications]);

  const clearDateFilter = () => {
    setDateRange({ startDate: '', endDate: '' });
    setShowAllNotifications(true);
    // Refresh with default 7-day filter
    fetchNotifications(false);
  };

  const handleNotificationTypeChange = (type) => {
    console.log('Changing notification type to:', type);
    setNotificationType(type);

    let filteredReceived = receivedNotifications;
    let filteredSent = sentNotifications;

    // First apply date filter if dates are selected
    if (dateRange.startDate && dateRange.endDate) {
      const startDate = parseFormattedDate(dateRange.startDate);
      startDate.setHours(0, 0, 0, 0);
      
      const endDate = parseFormattedDate(dateRange.endDate);
      endDate.setHours(23, 59, 59, 999);

      filteredReceived = filteredReceived.filter(notification => {
        if (!notification.date) return false;
        const notifDate = notification.date.toDate();
        return notifDate >= startDate && notifDate <= endDate;
      });

      filteredSent = filteredSent.filter(notification => {
        if (!notification.date) return false;
        const notifDate = notification.date.toDate();
        return notifDate >= startDate && notifDate <= endDate;
      });
    }

    // Then apply type filter
    if (type !== 'all') {
      console.log('Applying Type Filter:', {
        type,
        beforeFilter: {
          received: filteredReceived.map(n => n.type),
          sent: filteredSent.map(n => n.type)
        }
      });

      filteredReceived = filteredReceived.filter(notification => {
        const isTimeEntry = type === 'times' && notification.type === 'dispatchedTime';
        const isRun = type === 'runs' && notification.type === 'dispatchedRun';
        return isTimeEntry || isRun;
      });

      filteredSent = filteredSent.filter(notification => {
        const isTimeEntry = type === 'times' && notification.type === 'sentDispatchedTime';
        const isRun = type === 'runs' && notification.type === 'sentDispatchedRun';
        return isTimeEntry || isRun;
      });

      console.log('After Type Filter:', {
        type,
        afterFilter: {
          received: filteredReceived.map(n => n.type),
          sent: filteredSent.map(n => n.type)
        }
      });
    }

    setFilteredNotifications({
      received: filteredReceived,
      sent: filteredSent
    });
    setShowAllNotifications(false);
  };

  const fetchNotifications = async (fetchAll = false) => {
    setLoading(true);
    setError(null);
    
    try {
      if (!user || !user.uid) {
        throw new Error('User not authenticated');
      }

      console.log('Starting fetch for user:', user.uid);
      const sevenDaysAgo = Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
      
      // Base queries
      let dispatchedTimesQuery;
      let dispatchedRunsQuery;

      if (fetchAll) {
        // If fetching all, don't apply date filter
        dispatchedTimesQuery = query(
          collection(db, 'timeEntries'),
          orderBy('date', 'desc')
        );

        dispatchedRunsQuery = query(
          collection(db, 'dispatchedRuns'),
          orderBy('date', 'desc')
        );
      } else {
        // Default 7-day filter
        dispatchedTimesQuery = query(
          collection(db, 'timeEntries'),
          where('date', '>=', sevenDaysAgo),
          orderBy('date', 'desc')
        );

        dispatchedRunsQuery = query(
          collection(db, 'dispatchedRuns'),
          where('date', '>=', sevenDaysAgo),
          orderBy('date', 'desc')
        );
      }

      console.log('Queries created, fetchAll:', fetchAll);
      
      // Fetch dispatched times
      const dispatchedTimesSnapshot = await getDocs(dispatchedTimesQuery);
      
      // Fetch dispatched runs
      const dispatchedRunsSnapshot = await getDocs(dispatchedRunsQuery);

      console.log('RAW DATA', {
        timeEntries: dispatchedTimesSnapshot.size,
        dispatchRuns: dispatchedRunsSnapshot.size
      });

      // Process time entries
      console.group('TIME ENTRIES PROCESSING');
      const dispatchedTimesData = dispatchedTimesSnapshot.docs
        .filter(doc => {
          const data = doc.data();
          const isRelevant = data && (data.userId === user.uid || data.createdBy === user.uid || data.supervisorId === user.uid);
          console.log('Time Entry:', {
            id: doc.id,
            isRelevant,
            rawData: data,
            currentUser: user.uid
          });
          return isRelevant;
        })
        .map(doc => {
          const data = doc.data();
          const startTime = typeof data.startTime !== 'undefined' ? data.startTime : null;
          const endTime = typeof data.endTime !== 'undefined' ? data.endTime : null;
          const isReceived = data.userId === user.uid;
          const type = isReceived ? 'dispatchedTime' : 'sentDispatchedTime';
          
          const processedEntry = {
            id: doc.id,
            ...data,
            type,
            timestamp: data.date || Timestamp.now(),
            details: `Call Time: ${formatTime(startTime)}`,
            status: 'Completed',
            createdBy: data.createdBy || data.supervisorId,
            userId: data.userId || null
          };

          console.log('Processed Time Entry:', {
            id: doc.id,
            type,
            isReceived,
            startTime,
            endTime,
            userId: processedEntry.userId,
            createdBy: processedEntry.createdBy
          });

          return processedEntry;
        });
      console.groupEnd();

      // Process runs
      console.group('DISPATCH RUNS PROCESSING');
      const dispatchedRunsData = dispatchedRunsSnapshot.docs
        .filter(doc => {
          const data = doc.data();
          const isRelevant = data && (data.assignedTo === user.uid || data.createdBy === user.uid);
          console.log('Dispatch Run:', {
            id: doc.id,
            isRelevant,
            rawData: data,
            currentUser: user.uid
          });
          return isRelevant;
        })
        .map(doc => {
          const data = doc.data();
          const isReceived = data.assignedTo === user.uid;
          const type = isReceived ? 'dispatchedRun' : 'sentDispatchedRun';
          
          const processedRun = {
            id: doc.id,
            ...data,
            type,
            timestamp: data.date || Timestamp.now(),
            details: `${data.title || 'Untitled'} - ${data.itemDescription || 'No description'}`,
            status: data.status || 'Pending',
            createdBy: data.createdBy || null,
            assignedTo: data.assignedTo || null
          };

          console.log('Processed Dispatch Run:', {
            id: doc.id,
            type,
            isReceived,
            title: data.title,
            status: processedRun.status,
            assignedTo: processedRun.assignedTo,
            createdBy: processedRun.createdBy
          });

          return processedRun;
        });
      console.groupEnd();

      // Combine and sort all notifications by createdAt timestamp
      const allNotifications = [...dispatchedTimesData, ...dispatchedRunsData]
        .sort((a, b) => {
          const bTime = b.createdAt?.seconds || b.timestamp?.seconds || 0;
          const aTime = a.createdAt?.seconds || a.timestamp?.seconds || 0;
          return bTime - aTime;
        });

      // Split into received and sent based on type
      const received = allNotifications.filter(n => 
        n.type === 'dispatchedTime' || n.type === 'dispatchedRun'
      );
      
      const sent = allNotifications.filter(n => 
        n.type === 'sentDispatchedTime' || n.type === 'sentDispatchedRun'
      );

      console.log('FINAL RESULTS', {
        total: allNotifications.length,
        received: {
          count: received.length,
          types: received.map(n => n.type)
        },
        sent: {
          count: sent.length,
          types: sent.map(n => n.type)
        }
      });

      setReceivedNotifications(received);
      setSentNotifications(sent);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError('Failed to fetch notifications. Please try again.');
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
    fetchNotifications();
  };

  const handleAcknowledge = (notificationId, createdBy, userId, timestamp) => {
    // Update received notifications
    setReceivedNotifications(prevNotifications =>
      prevNotifications.map(notif =>
        notif.id === notificationId ? { ...notif, acknowledged: true } : notif
      )
    );

    // Update sent notifications - match by createdBy and userId
    setSentNotifications(prevNotifications =>
      prevNotifications.map(notif => {
        // Check if this is the matching sent notification
        const isMatch = 
          notif.createdBy === createdBy && 
          notif.userId === userId && 
          notif.date?.seconds === timestamp?.seconds;

        console.log('Checking sent notification:', {
          notifCreatedBy: notif.createdBy,
          createdBy,
          notifUserId: notif.userId,
          userId,
          notifTimestamp: notif.date?.seconds,
          timestamp: timestamp?.seconds,
          isMatch
        });

        return isMatch ? { ...notif, acknowledged: true } : notif;
      })
    );
  };

  const getUserName = async (userId) => {
    if (!userId) return '';
    
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return `${userData.firstName} ${userData.lastName}` || '';
      }
      return '';
    } catch (error) {
      console.error('Error getting user name:', error);
      return '';
    }
  };

  // Helper function to get user info - moved outside handleSearch
  const getUserInfo = async (userId) => {
    if (!userId) return null;
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim(),
          email: userData.email
        };
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
    return null;
  };

  const handleSearch = async (event) => {
    const searchValue = event.target.value || '';
    console.log('Searching for:', searchValue);
    setSearchTerm(searchValue);

    const searchLower = searchValue.toLowerCase().trim();

    if (!searchLower) {
      setFilteredNotifications({
        received: receivedNotifications,
        sent: sentNotifications
      });
      return;
    }

    // Filter notifications
    const filterNotifications = async () => {
      console.log('Filtering notifications with search term:', searchLower);
      console.log('Total sent notifications:', sentNotifications.length);

      const processedIds = new Set();
      const matchedNotifications = [];
      
      for (const notification of sentNotifications) {
        // Skip if we've already processed this notification
        if (processedIds.has(notification.id)) continue;
        processedIds.add(notification.id);

        const userId = notification.type === 'sentDispatchedRun' ? 
          notification.assignedTo : notification.userId;
        
        const userInfo = await getUserInfo(userId);
        
        // Check name match
        const nameMatch = userInfo?.name?.toLowerCase().includes(searchLower);
        
        // Check details match
        let detailsMatch = false;
        if (notification.type === 'sentDispatchedRun') {
          const details = [
            notification.title || '',
            notification.itemDescription || '',
            notification.address || ''
          ].join(' ').toLowerCase();
          detailsMatch = details.includes(searchLower);
        }

        if (nameMatch || detailsMatch) {
          console.log('Found match:', {
            id: notification.id,
            name: userInfo?.name,
            type: notification.type,
            matchType: nameMatch ? 'name' : 'details'
          });
          matchedNotifications.push(notification);
        }
      }

      console.log('Search complete:', {
        matches: matchedNotifications.length,
        matchedIds: matchedNotifications.map(n => n.id)
      });

      setFilteredNotifications({
        received: receivedNotifications,
        sent: matchedNotifications
      });
    };

    await filterNotifications();
  };

  // Immediate radial/type filter handler (no button needed)
  const handleTypeFilter = (type) => {
    const updatedTypes = selectedTypes.includes(type)
      ? selectedTypes.filter(t => t !== type)
      : [...selectedTypes, type];
    
    setSelectedTypes(updatedTypes);

    const typeFilteredResults = {
      received: receivedNotifications.filter(notification => {
        const matchesType = updatedTypes.length === 0 || 
                           updatedTypes.includes(notification.type);
        
        // Include search term in type filter
        const matchesSearch = !searchTerm || (
          notification.details?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.senderName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.type?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return matchesType && matchesSearch;
      }),

      sent: sentNotifications.filter(notification => {
        const matchesType = updatedTypes.length === 0 || 
                           updatedTypes.includes(notification.type);
        
        // Include search term in type filter
        const matchesSearch = !searchTerm || (
          notification.details?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.recipientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.type?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return matchesType && matchesSearch;
      })
    };

    setFilteredNotifications(typeFilteredResults);
    setShowAllNotifications(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersQuery = query(collection(db, 'users'));
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [user]);

  useEffect(() => {
    console.log('Current notifications:', {
      received: receivedNotifications,
      sent: sentNotifications
    });
  }, [receivedNotifications, sentNotifications]);

  // Handle date input changes
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Add error handling for connection issues
  useEffect(() => {
    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('userId', '==', user?.uid),
      orderBy('timestamp', 'desc'),
      limit(20)
    );

    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      // ... handle snapshot updates ...
    }, (error) => {
      console.error('Firestore connection error:', error);
      setToast({
        message: 'Connection issue detected. Trying to reconnect...',
        type: 'warning'
      });
      
      setTimeout(() => {
        fetchNotifications();
      }, 5000);
    });

    return () => unsubscribe();
  }, [user]);

  const getDisplayedNotifications = () => {
    if (showAllNotifications) {
      return {
        received: receivedNotifications.sort((a, b) => {
          const bTime = b.createdAt?.seconds || b.timestamp?.seconds || 0;
          const aTime = a.createdAt?.seconds || a.timestamp?.seconds || 0;
          return bTime - aTime;
        }),
        sent: sentNotifications.sort((a, b) => {
          const bTime = b.createdAt?.seconds || b.timestamp?.seconds || 0;
          const aTime = a.createdAt?.seconds || a.timestamp?.seconds || 0;
          return bTime - aTime;
        })
      };
    }
    return {
      received: filteredNotifications.received.sort((a, b) => {
        const bTime = b.createdAt?.seconds || b.timestamp?.seconds || 0;
        const aTime = a.createdAt?.seconds || a.timestamp?.seconds || 0;
        return bTime - aTime;
      }),
      sent: filteredNotifications.sent.sort((a, b) => {
        const bTime = b.createdAt?.seconds || b.timestamp?.seconds || 0;
        const aTime = a.createdAt?.seconds || a.timestamp?.seconds || 0;
        return bTime - aTime;
      })
    };
  };

  return (
    <div className="notifications-container">
      <Sidebar />
      <div className="main-content">
        <h1>Notifications</h1>
        <div className="filter-section">
          <div className="notification-type-filter">
            <label className="radio-label">
              <input
                type="radio"
                name="notificationType"
                value="all"
                checked={notificationType === 'all'}
                onChange={(e) => handleNotificationTypeChange(e.target.value)}
              />
              All
            </label>
            <label className="radio-label">
              <input
                type="radio"
                name="notificationType"
                value="times"
                checked={notificationType === 'times'}
                onChange={(e) => handleNotificationTypeChange(e.target.value)}
              />
              Time Entries
            </label>
            <label className="radio-label">
              <input
                type="radio"
                name="notificationType"
                value="runs"
                checked={notificationType === 'runs'}
                onChange={(e) => handleNotificationTypeChange(e.target.value)}
              />
              Dispatch Runs
            </label>
          </div>

          <div className="date-filter">
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) => setDateRange(prev => ({ ...prev, startDate: e.target.value }))}
              placeholder="mm/dd/yyyy"
            />
            <span>to</span>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) => setDateRange(prev => ({ ...prev, endDate: e.target.value }))}
              placeholder="mm/dd/yyyy"
            />
            <button onClick={handleDateFilter}>Apply Filter</button>
            <button onClick={clearDateFilter}>Clear Filters</button>
          </div>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <>
            <div className="notifications-section">
              <h2>Received Notifications</h2>
              {getDisplayedNotifications().received.length === 0 ? (
                <div className="no-notifications">
                  <p>No notifications found for the selected filters.</p>
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Details</th>
                      <th>From</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDisplayedNotifications().received
                      .filter(notification => {
                        if (notificationType === 'all') return true;
                        if (notificationType === 'times') return notification.type === 'dispatchedTime';
                        if (notificationType === 'runs') return notification.type === 'dispatchedRun';
                        return false;
                      })
                      .map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        onAcknowledge={handleAcknowledge}
                        isSentNotification={false}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            <div className="notifications-section">
              <h2>Sent Notifications</h2>
              {getDisplayedNotifications().sent.length === 0 ? (
                <div className="no-notifications">
                  <p>No notifications found for the selected filters.</p>
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Details</th>
                      <th>To</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDisplayedNotifications().sent
                      .filter(notification => {
                        if (notificationType === 'all') return true;
                        if (notificationType === 'times') return notification.type === 'sentDispatchedTime';
                        if (notificationType === 'runs') return notification.type === 'sentDispatchedRun';
                        return false;
                      })
                      .map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        onAcknowledge={handleAcknowledge}
                        isSentNotification={true}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
        {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
      </div>
    </div>
  );
}

export default Notifications;
