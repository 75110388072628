import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  getAuth, 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ROLES } from '../utils/permissions';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productionId, setProductionId] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
        setProductionId(null);
        return;
      }

      try {
        // Get additional user data from Firestore
        const userDocRef = doc(db, 'users', firebaseUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : null;
        
        if (!userData || !userData.role) {
          console.log('No user data or role found, setting up new user with SUPER_ADMIN role');
          // Set up new user with super admin role
          const newUserData = {
            email: firebaseUser.email,
            role: ROLES.SUPER_ADMIN,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
          
          await setDoc(userDocRef, newUserData, { merge: true });
          console.log('User document updated with SUPER_ADMIN role');
          
          setUser({
            uid: firebaseUser.uid,
            ...firebaseUser,
            ...newUserData
          });
        } else {
          console.log('Existing user data found:', userData);
          setUser({
            uid: firebaseUser.uid,
            ...firebaseUser,
            ...userData
          });
          if (userData.productionId) {
            console.log('Setting productionId:', userData.productionId);
            setProductionId(userData.productionId);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Still set the basic user data even if there's an error
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email
        });
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      console.log('Auth state changed. User:', firebaseUser);
      if (firebaseUser) {
        fetchUserData(firebaseUser);
      } else {
        setUser(null);
        setProductionId(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  const value = {
    user,
    loading,
    setUser,
    productionId,
    setProductionId
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;