import { doc, setDoc, getDoc, updateDoc, collection, getCountFromServer, query, where, and } from 'firebase/firestore';
import { db } from './firebase';
import { ROLES } from '../utils/permissions';

const logError = (functionName, error) => {
  console.error(`Error in ${functionName}:`, error);
  throw error;
};

export const createUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);

  try {
    // Check if user document already exists
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      // Create new user document with SUPER_ADMIN role
      const userData = {
        ...additionalData,  
        email: user.email,
        role: ROLES.SUPER_ADMIN,  
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };
      
      console.log('Creating new user document with data:', userData);
      await setDoc(userRef, userData);
      console.log("User document created successfully with role:", ROLES.SUPER_ADMIN);
    } else {
      // Update existing document
      const userData = {
        ...userDoc.data(),
        ...additionalData,
        email: user.email,
        updatedAt: new Date().toISOString()
      };
      
      // Ensure role is set
      if (!userData.role) {
        userData.role = ROLES.SUPER_ADMIN;
      }
      
      console.log('Updating user document with data:', userData);
      await setDoc(userRef, userData, { merge: true });
      console.log("User document updated successfully");
    }
  } catch (error) {
    console.error("Error creating/updating user document:", error);
    throw error;
  }
};

export const getUserDocument = async (uid) => {
  if (!uid) return null;

  try {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);

    return {
      uid,
      ...userDoc.data()
    };
  } catch (error) {
    console.error('Error fetching user', error);
  }
};

export const updateUserDocument = async (uid, data) => {
  console.log("Updating user document for uid:", uid, "with data:", data);
  
  try {
    // First get the current user document to preserve role
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    
    if (userSnap.exists()) {
      const currentData = userSnap.data();
      console.log("Current user data:", currentData);
      console.log("Current role:", currentData.role);
      
      // Preserve the role and merge with new data
      const updatedData = {
        ...data,
        role: currentData.role, // Preserve existing role
        updatedAt: new Date().toISOString()
      };
      
      console.log("Updating document with:", updatedData);
      await updateDoc(userRef, updatedData);
      console.log("Document successfully updated with role preserved");
      
      return updatedData;
    } else {
      console.error("No user document found for uid:", uid);
      throw new Error("No user document found");
    }
  } catch (error) {
    console.error("Error updating user document:", error);
    throw error;
  }
};

export const getTotalUserCount = async () => {
  try {
    const usersCollection = collection(db, 'users');
    const snapshot = await getCountFromServer(usersCollection);
    return snapshot.data().count;
  } catch (error) {
    logError('getTotalUserCount', error);
  }
};

export const getTotalDepartmentCount = async () => {
  const departmentsCollection = collection(db, 'departments');
  const snapshot = await getCountFromServer(departmentsCollection);
  return snapshot.data().count;
};

export const getWaitlistCount = async () => {
  const waitlistCollection = collection(db, 'waitlist');
  const snapshot = await getCountFromServer(waitlistCollection);
  return snapshot.data().count;
};

export const getTrialProductionsCount = async () => {
  const productionsCollection = collection(db, 'productions');
  const trialQuery = query(productionsCollection, where("status", "==", "trial"));
  const snapshot = await getCountFromServer(trialQuery);
  return snapshot.data().count;
};

export const getPaidProductionsCount = async () => {
  const productionsCollection = collection(db, 'productions');
  const paidQuery = query(productionsCollection, where("status", "==", "paid"));
  const snapshot = await getCountFromServer(paidQuery);
  return snapshot.data().count;
};

export const getActiveTrialProductionsCount = async () => {
  try {
    const productionsCollection = collection(db, 'productions');
    const activeTrialQuery = query(productionsCollection, 
      and(
        where("status", "==", "trial"),
        where("isActive", "==", true)
      )
    );
    const snapshot = await getCountFromServer(activeTrialQuery);
    return snapshot.data().count;
  } catch (error) {
    logError('getActiveTrialProductionsCount', error);
  }
};

export const getActivePaidProductionsCount = async () => {
  const productionsCollection = collection(db, 'productions');
  const activePaidQuery = query(productionsCollection, 
    and(
      where("status", "==", "paid"),
      where("isActive", "==", true)
    )
  );
  const snapshot = await getCountFromServer(activePaidQuery);
  return snapshot.data().count;
};