import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import './Departments.css';
import '../styles/SharedStyles.css';
import { db, storage } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, getDoc, query, where, writeBatch } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAccessControl } from '../hooks/useAccessControl';
import { baseDepartments } from '../data/baseDepartments';
import RunRequestForm from '../components/RunRequestForm';

function Departments() {
  const { productionId } = useParams();
  const [departments, setDepartments] = useState([]);
  const [productionDepartments, setProductionDepartments] = useState({});
  const [loading, setLoading] = useState(true);
  const [production, setProduction] = useState(null);
  const [newDepartment, setNewDepartment] = useState({
    name: '',
    headOfDepartment: '',
    description: '',
    leaders: [],
    status: 'pending'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [editingDepartment, setEditingDepartment] = useState(null);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [expandedDepartmentId, setExpandedDepartmentId] = useState(null);
  const [isRunRequestOpen, setIsRunRequestOpen] = useState(false);

  const { hasAccess, isLoading: accessLoading, error: accessError, productionAccess, departmentAccess } = useAccessControl();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        setLoading(true);
        
        // Fetch production-specific department data
        const departmentsSnapshot = await getDocs(collection(db, 'departments'));
        const productionDeptData = {};
        
        departmentsSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (data.production === productionAccess) {
            productionDeptData[data.name] = {
              id: doc.id,
              ...data
            };
          }
        });
        
        console.log('Fetched Departments:', departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        console.log('Current User:', user);
        console.log('Current Production:', productionAccess);
        
        setProductionDepartments(productionDeptData);
        
        // Combine base departments with production data
        const combinedDepartments = baseDepartments.map(baseDept => {
          const productionData = productionDeptData[baseDept.name] || {};
          return {
            ...baseDept,
            ...productionData
          };
        });
        
        console.log('All departments:', {
          baseDepartments: baseDepartments.map(d => d.name),
          productionDepartments: Object.keys(productionDeptData),
          combined: combinedDepartments.map(d => d.name)
        });
        
        setDepartments(combinedDepartments);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching departments:', error);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, [productionAccess]);

  useEffect(() => {
    if (user && productionId) {
      fetchProductionAndDepartments();
    }
  }, [user, productionId]);

  const fetchProductionAndDepartments = async () => {
    try {
      // Fetch production details
      const productionDoc = await getDoc(doc(db, 'productions', productionId));
      if (!productionDoc.exists()) {
        throw new Error('Production not found');
      }
      
      const productionData = productionDoc.data();
      setProduction({ id: productionDoc.id, ...productionData });
      
      // Get departments from the production
      if (productionData.departments) {
        setDepartments(productionData.departments);
      }
    } catch (error) {
      console.error('Error fetching production and departments:', error);
      setError(`Error: ${error.message}`);
    }
  };

  const handleAddDepartment = async (e) => {
    e.preventDefault();
    if (!newDepartment.name.trim()) {
      setSubmitError('Department name is required');
      return;
    }

    try {
      setIsSubmitting(true);
      setSubmitError(null);

      // Create new department document
      const departmentData = {
        name: newDepartment.name.trim(),
        headOfDepartment: newDepartment.headOfDepartment.trim(),
        description: newDepartment.description.trim(),
        leaders: newDepartment.leaders,
        status: newDepartment.status,
        production: productionAccess,
        createdAt: new Date(),
        updatedAt: new Date()
      };

      await addDoc(collection(db, 'departments'), departmentData);

      // Reset form
      setNewDepartment({
        name: '',
        headOfDepartment: '',
        description: '',
        leaders: [],
        status: 'pending'
      });

      setSubmitSuccess(true);
      
      // Refresh departments list
      const departmentsRef = collection(db, 'departments');
      const querySnapshot = await getDocs(departmentsRef);
      const departmentsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

      setDepartments(departmentsData);
    } catch (error) {
      console.error('Error adding department:', error);
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateDepartment = async (updatedDepartment) => {
    if (!updatedDepartment.id) {
      setSubmitError('Cannot update department without ID');
      return;
    }

    try {
      setIsSubmitting(true);
      setSubmitError(null);

      const departmentRef = doc(db, 'departments', updatedDepartment.id);
      await updateDoc(departmentRef, {
        ...updatedDepartment,
        updatedAt: new Date()
      });

      setEditingDepartment(null);
      setSubmitSuccess(true);
      setTimeout(() => setSubmitSuccess(false), 3000);

      // Refresh departments list
      const departmentsRef = collection(db, 'departments');
      const querySnapshot = await getDocs(departmentsRef);
      const departmentsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

      const filteredDepartments = departmentAccess === 'all' 
        ? departmentsData 
        : departmentsData.filter(dept => {
          const isDeptMatch = 
            dept.id === departmentAccess || 
            (dept.name || '').toLowerCase() === (departmentAccess || '').toLowerCase();
          return isDeptMatch;
        });

      setDepartments(filteredDepartments);
    } catch (error) {
      console.error('Error updating department:', error);
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment({ ...newDepartment, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingDepartment({ ...editingDepartment, [name]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterDepartments = (departments) => {
    return departments.filter(dept => {
      // Include department if:
      // 1. It has no production field (base department)
      // 2. OR its production matches current production
      return !dept.production || dept.production === productionAccess;
    });
  };

  const filteredDepartments = filterDepartments(departments).filter(dept =>
    dept.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const canEditDepartment = (departmentId) => {
    if (!user) return false;
    
    // Allow all admins to edit
    if (user.role === 'AppAdmin' || 
        user.role === 'SuperAdmin' || 
        user.role === 'ProductionAdmin') {
      return true;
    }
    
    // Allow supervisors of the specific department
    return user.role === 'Supervisor' && user.department === departmentId;
  };

  const EditDepartmentForm = ({ department, onSubmit, onCancel }) => {
    const [editForm, setEditForm] = useState({
      ...department,
      leaders: department.leaders || [],
      about: department.about || 'The Transportation Department handles all vehicle logistics, crew transportation, and equipment movement for productions. We manage a fleet of vehicles including passenger vans, trucks, and specialty vehicles.',
      services: department.services || [
        'Equipment pickup and delivery',
        'Crew transportation',
        'Location scouting support',
        'Emergency transportation',
        'Vehicle maintenance and management'
      ]
    });
    const [newLeader, setNewLeader] = useState({
      name: '',
      position: '',
      phone: '',
      email: ''
    });
    const [newService, setNewService] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(editForm);
    };

    const handleLeaderAdd = (e) => {
      e.preventDefault();
      if (newLeader.name && newLeader.position) {
        setEditForm(prev => ({
          ...prev,
          leaders: [...prev.leaders, newLeader]
        }));
        setNewLeader({ name: '', position: '', phone: '', email: '' });
      }
    };

    const removeLeader = (index) => {
      setEditForm(prev => ({
        ...prev,
        leaders: prev.leaders.filter((_, i) => i !== index)
      }));
    };

    const handleAddService = (e) => {
      e.preventDefault();
      if (newService.trim()) {
        setEditForm(prev => ({
          ...prev,
          services: [...prev.services, newService.trim()]
        }));
        setNewService('');
      }
    };

    const handleRemoveService = (indexToRemove) => {
      setEditForm(prev => ({
        ...prev,
        services: prev.services.filter((_, index) => index !== indexToRemove)
      }));
    };

    return (
      <div className="edit-department-popup" onClick={e => e.stopPropagation()}>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(editForm);
        }}>
          <input
            type="text"
            name="name"
            value={editForm.name}
            disabled
            className="department-name-readonly"
          />
          <input
            type="text"
            name="headOfDepartment"
            value={editForm.headOfDepartment}
            onChange={(e) => setEditForm(prev => ({ ...prev, headOfDepartment: e.target.value }))}
            placeholder="Head of Department"
          />
          
          <div className="department-leaders-section">
            <h4>Department Leaders</h4>
            <div className="add-leader-form">
              <input
                type="text"
                value={newLeader.name}
                onChange={(e) => setNewLeader(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Leader Name"
              />
              <input
                type="text"
                value={newLeader.position}
                onChange={(e) => setNewLeader(prev => ({ ...prev, position: e.target.value }))}
                placeholder="Position"
              />
              <input
                type="tel"
                value={newLeader.phone}
                onChange={(e) => setNewLeader(prev => ({ ...prev, phone: e.target.value }))}
                placeholder="Phone Number"
              />
              <input
                type="email"
                value={newLeader.email}
                onChange={(e) => setNewLeader(prev => ({ ...prev, email: e.target.value }))}
                placeholder="Email Address"
              />
              <div className="leader-form-buttons">
                <button type="button" onClick={handleLeaderAdd} className="add-leader-btn">
                  Add Leader
                </button>
                <button type="submit">Update</button>
                <button type="button" onClick={onCancel}>Cancel</button>
              </div>
            </div>

            <div className="leaders-list">
              {editForm.leaders.map((leader, index) => (
                <div key={index} className="leader-item">
                  <div className="leader-info">
                    <div className="leader-main">
                      <strong>{leader.name}</strong> - {leader.position}
                    </div>
                    <div className="leader-contact">
                      <span>{leader.phone}</span>
                      <span>{leader.email}</span>
                    </div>
                  </div>
                  <div className="leader-actions">
                    <button 
                      type="button" 
                      onClick={() => {
                        setNewLeader(leader);
                        removeLeader(index);
                      }}
                      className="edit-leader-btn"
                    >
                      Edit
                    </button>
                    <button 
                      type="button" 
                      onClick={() => removeLeader(index)}
                      className="remove-leader-btn"
                    >
                      ×
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="form-section">
            <h4>About</h4>
            <textarea
              name="about"
              value={editForm.about}
              onChange={(e) => setEditForm(prev => ({ ...prev, about: e.target.value }))}
              placeholder="Department description"
              rows="4"
            />
          </div>

          <div className="form-section">
            <h4>Services</h4>
            <div className="services-list">
              {editForm.services.map((service, index) => (
                <div key={index} className="service-item">
                  <span>{service}</span>
                  <button 
                    type="button" 
                    onClick={() => handleRemoveService(index)}
                    className="remove-service-btn"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div className="add-service">
              <input
                type="text"
                value={newService}
                onChange={(e) => setNewService(e.target.value)}
                placeholder="Add new service"
              />
              <button 
                type="button" 
                onClick={handleAddService}
                className="add-service-btn"
              >
                Add Service
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const DepartmentItem = ({ dept }) => {
    console.log('Rendering Department:', dept);
    console.log('User Role:', user?.role);
    console.log('User Department ID:', user?.departmentId);
    console.log('Department ID:', dept.id);
    console.log('Is Expanded:', expandedDepartmentId === dept.name);
    console.log('Can Edit:', user?.role === 'admin' || (user?.role === 'Supervisor' && user?.departmentId === dept.id));

    const isExpanded = expandedDepartmentId === dept.name;
    const canEdit = canEditDepartment(dept.name);
    const [showRunRequest, setShowRunRequest] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [runRequest, setRunRequest] = useState({
      name: '',
      department: '',
      requestType: 'pickup',
      address: {
        streetNumber: '',
        streetName: '',
        suite: '',
        city: '',
        state: '',
        zip: ''
      },
      description: '',
      date: '',
      time: '',
      photos: []
    });

    const handleFileSelect = (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const removeFile = (indexToRemove) => {
      setSelectedFiles(prevFiles => 
        prevFiles.filter((_, index) => index !== indexToRemove)
      );
    };

    const handleRunRequestSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setSubmitError(null);

      try {
        // Upload photos first if any exist
        const photoUrls = [];
        if (selectedFiles.length > 0) {
          for (const file of selectedFiles) {
            const storageRef = ref(storage, `runRequests/${Date.now()}_${file.name}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            photoUrls.push(url);
          }
        }

        // Create the run request document
        const runRequestData = {
          ...runRequest,
          photos: photoUrls,
          status: 'Pending',
          createdAt: new Date(),
          createdBy: user.uid,
          createdByName: user.displayName || user.email,
          updatedAt: new Date(),
          completedAt: null,
          assignedTo: null,
          acknowledgement: {
            received: false,
            receivedAt: null,
            started: false,
            startedAt: null,
            completed: false,
            completedAt: null
          }
        };

        // Add run request to Firestore
        const runRequestRef = await addDoc(collection(db, 'runRequests'), runRequestData);

        // Create notifications
        const notificationsData = [
          // Notification for supervisors
          {
            type: 'runRequest',
            status: 'pending',
            title: 'New Run Request',
            message: `New run request from ${runRequestData.createdByName}`,
            runRequestId: runRequestRef.id,
            createdAt: new Date(),
            forRole: 'Supervisor',
            forDepartment: 'Transportation',
            read: false,
            action: 'assign',
            metadata: {
              requestType: runRequest.requestType,
              date: runRequest.date,
              time: runRequest.time
            }
          },
          // Confirmation notification for requester
          {
            type: 'runRequest',
            status: 'sent',
            title: 'Run Request Submitted',
            message: 'Your run request has been submitted and is pending assignment',
            runRequestId: runRequestRef.id,
            createdAt: new Date(),
            forUserId: user.uid,
            read: false,
            action: 'view',
            metadata: {
              requestType: runRequest.requestType,
              date: runRequest.date,
              time: runRequest.time
            }
          }
        ];

        // Add notifications to Firestore
        const batch = writeBatch(db);
        notificationsData.forEach((notification) => {
          const notificationRef = doc(collection(db, 'notifications'));
          batch.set(notificationRef, notification);
        });
        await batch.commit();

        setSubmitSuccess(true);
        
        // Reset form after 2 seconds
        setTimeout(() => {
          setShowRunRequest(false);
          setRunRequest({
            name: '',
            department: '',
            requestType: 'pickup',
            address: {
              streetNumber: '',
              streetName: '',
              suite: '',
              city: '',
              state: '',
              zip: ''
            },
            description: '',
            date: '',
            time: '',
            photos: []
          });
          setSelectedFiles([]);
          setSubmitSuccess(false);
        }, 2000);

      } catch (error) {
        console.error('Error submitting run request:', error);
        setSubmitError('Failed to submit run request. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    };

    const handleRunRequestChange = (e) => {
      const { name, value } = e.target;
      if (name.startsWith('address.')) {
        const addressField = name.split('.')[1];
        setRunRequest(prev => ({
          ...prev,
          address: {
            ...prev.address,
            [addressField]: value
          }
        }));
      } else {
        setRunRequest(prev => ({
          ...prev,
          [name]: value
        }));
      }
    };

    const handleDepartmentClick = (e) => {
      if (!e.target.closest('button') && !e.target.closest('form')) {
        if (expandedDepartmentId === dept.name) {
          setEditingDepartment(null);
        }
        setExpandedDepartmentId(isExpanded ? null : dept.name);
      }
    };

    return (
      <div 
        className={`department-item ${isExpanded ? 'expanded' : ''}`}
        onClick={handleDepartmentClick}
      >
        <div className="department-header">
          <h3>{dept.name}</h3>
          {(user?.role === 'admin' || 
            (user?.role === 'Supervisor' && user?.departmentId === dept.id)) && (
            <button 
              className="edit-button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent tile from expanding when clicking edit
                setEditingDepartment(dept);
              }}
            >
              Edit
            </button>
          )}
        </div>
        <div className="department-details">
          <p>
            <strong>Head of Department:</strong>{' '}
            {dept.headOfDepartment || 'Not assigned'}
          </p>
          {expandedDepartmentId === dept.name && (
            <>
              {(user?.role === 'admin' || user?.role === 'Supervisor') && (
                <button 
                  className="edit-button expanded"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingDepartment(dept);
                  }}
                >
                  Edit Department
                </button>
              )}
              {dept.leaders && dept.leaders.length > 0 && (
                <div className="department-leaders">
                  <h4>Department Leaders</h4>
                  <div className="leaders-grid">
                    {dept.leaders.map((leader, index) => (
                      <div key={index} className="leader-card">
                        <h5>{leader.name || leader}</h5>
                        {leader.position && (
                          <p className="leader-position">{leader.position}</p>
                        )}
                        <div className="leader-contact-info">
                          {leader.phone && (
                            <p className="leader-phone">
                              <i className="fas fa-phone"></i> {leader.phone}
                            </p>
                          )}
                          {leader.email && (
                            <p className="leader-email">
                              <i className="fas fa-envelope"></i> {leader.email}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              <div className="department-info">
                <div className="info-section">
                  <h4>About</h4>
                  <p>{dept.about || dept.description || 'No description available.'}</p>
                </div>
                
                <div className="info-section">
                  <h4>Services</h4>
                  {dept.services && dept.services.length > 0 ? (
                    <ul className="services-list">
                      {dept.services.map((service, index) => (
                        <li key={index}>{service}</li>
                      ))}
                    </ul>
                  ) : (
                    <ul className="services-list">
                      <li>Service information not available</li>
                    </ul>
                  )}
                </div>
              </div>
              
              {dept.name === 'Transportation' && (
                <div className="department-actions">
                  <button 
                    className="submit-run-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsRunRequestOpen(true);
                    }}
                  >
                    Submit Run Request
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const createTestDepartment = async () => {
    try {
      setIsSubmitting(true);
      setSubmitError(null);

      const departmentData = {
        name: 'Transportation',
        headOfDepartment: 'Test Head',
        description: 'Transportation department',
        production: productionAccess,
        status: 'active',
        createdAt: new Date(),
        updatedAt: new Date()
      };

      await addDoc(collection(db, 'departments'), departmentData);
      console.log('Created test department');
      
      // Refresh the departments list
      const departmentsRef = collection(db, 'departments');
      const querySnapshot = await getDocs(departmentsRef);
      const departmentsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

      setDepartments(departmentsData);
    } catch (error) {
      console.error('Error creating test department:', error);
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add function to update existing departments
  const updateExistingDepartments = async () => {
    try {
      setIsSubmitting(true);
      setSubmitError(null);

      const departmentsRef = collection(db, 'departments');
      const querySnapshot = await getDocs(departmentsRef);
      
      // Update each department with the production name
      const updatePromises = querySnapshot.docs.map(doc => {
        const dept = doc.data();
        if (!dept.production) {
          console.log('Updating department:', doc.id, 'with production:', productionAccess);
          return updateDoc(doc.ref, {
            production: productionAccess,
            updatedAt: new Date()
          });
        }
        return Promise.resolve();
      });

      await Promise.all(updatePromises);
      console.log('Updated existing departments with production name');

      // Refresh the departments list
      const updatedSnapshot = await getDocs(departmentsRef);
      const departmentsData = updatedSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

      setDepartments(departmentsData);
      setSubmitSuccess(true);
      setTimeout(() => setSubmitSuccess(false), 3000);
    } catch (error) {
      console.error('Error updating departments:', error);
      setSubmitError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Call updateExistingDepartments when the component mounts
  useEffect(() => {
    if (hasAccess && productionAccess) {
      updateExistingDepartments();
    }
  }, [hasAccess, productionAccess]);

  // Only show loading spinner during initial access check
  if (accessLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div className="text-center p-4 text-red-600">
        You don't have access to view departments.
        {accessError && <div className="mt-2">{accessError}</div>}
      </div>
    );
  }

  return (
    <div className="departments-container">
      <div className="departments-header">
        <h1>Departments</h1>
        <p className="departments-subtitle">Organize and manage production departments</p>
      </div>
      <div className="departments-content">
        {loading ? (
          <div className="loading-spinner">
            <div className="spinner" />
          </div>
        ) : (
          <div className="departments-grid">
            {departments.map((department) => (
              <DepartmentItem key={department.name} dept={department} />
            ))}
          </div>
        )}
      </div>
      {editingDepartment && (
        <div className="modal-overlay" onClick={() => setEditingDepartment(null)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>Edit {editingDepartment.name}</h2>
            <form onSubmit={handleUpdateDepartment}>
              <input
                type="text"
                name="headOfDepartment"
                placeholder="Head of Department"
                value={editingDepartment.headOfDepartment || ''}
                onChange={(e) => setEditingDepartment({
                  ...editingDepartment,
                  headOfDepartment: e.target.value
                })}
                className="form-input"
              />
              <textarea
                name="description"
                placeholder="Department Description"
                value={editingDepartment.description || ''}
                onChange={(e) => setEditingDepartment({
                  ...editingDepartment,
                  description: e.target.value
                })}
                className="form-input"
              />
              <div className="form-actions">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setEditingDepartment(null)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <RunRequestForm 
        isOpen={isRunRequestOpen}
        onClose={() => setIsRunRequestOpen(false)}
        departments={departments.filter(dept => dept.productionId === productionId)}
      />
    </div>
  );
}

export default Departments;