import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebase';
import { collection, query, where, getDocs, orderBy, limit, doc, getDoc, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Sidebar from '../components/Sidebar';
import AddVehiclePopup from '../components/AddVehiclePopup';
import './Home.css'; // Optional: for custom styles
import '../styles/SharedStyles.css';
import { Link } from 'react-router-dom';
import ReceiptSubmissionPopup from '../components/ReceiptSubmissionPopup';
import Toast from '../components/Toast';

function Home() {
  const { user } = useAuth();
  const [productionInfo, setProductionInfo] = useState(null);
  const [crewMembers, setCrewMembers] = useState([]);
  const [crewMemberCount, setCrewMemberCount] = useState(0);  // New state for crew member count
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [bulletinBoard, setBulletinBoard] = useState([]);
  const [departments, setDepartments] = useState({});
  const [weather, setWeather] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [shootingDays, setShootingDays] = useState({ current: 0, total: 0 });
  const [locations, setLocations] = useState({ shooting: [], parking: [] });
  const [showAddVehiclePopup, setShowAddVehiclePopup] = useState(false);
  const [showReceiptPopup, setShowReceiptPopup] = useState(false);
  const [receiptType, setReceiptType] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [toast, setToast] = useState(null);

  useEffect(() => {
    if (user) {
      console.log("Current user:", user); // Debug log
      fetchUserProductionInfo();
      fetchShootingDays();
      fetchCrewMembers();
      fetchUpcomingEvents();
      fetchBulletinBoard();
      fetchDepartments();
      fetchWeather();
      fetchLocations();
    }
  }, [user]);

  const fetchUserProductionInfo = async () => {
    try {
      // First, get the latest user data from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      console.log("Latest user data:", userData); // Debug log

      if (!userData.production) {
        console.log("User has no assigned production");
        setProductionInfo(null);
        return;
      }

      const productionRef = collection(db, 'productions');
      const q = query(productionRef, where("name", "==", userData.production));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const productionData = querySnapshot.docs[0].data();
        console.log("Production data:", productionData); // Debug log
        setProductionInfo(productionData);
      } else {
        console.log("No production found with the name:", userData.production);
        setProductionInfo(null);
      }
    } catch (error) {
      console.error("Error fetching production info:", error);
      setProductionInfo(null);
    }
  };

  const fetchShootingDays = async () => {
    try {
      const productionRef = collection(db, 'productions');
      const q = query(productionRef, where("name", "==", user.production));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const productionData = querySnapshot.docs[0].data();
        setShootingDays({
          current: productionData.currentShootingDay || 0,
          total: productionData.totalShootingDays || 0
        });
      }
    } catch (error) {
      console.error("Error fetching shooting days:", error);
    }
  };

  const fetchCrewMembers = async () => {
    try {
      const crewRef = collection(db, 'users');
      const q = query(crewRef, where("production", "==", user.production));
      const querySnapshot = await getDocs(q);
      const crewData = querySnapshot.docs.map(doc => doc.data());
      setCrewMembers(crewData);
      setCrewMemberCount(crewData.length);  // Update the crew member count
    } catch (error) {
      console.error("Error fetching crew members:", error);
    }
  };

  const fetchUpcomingEvents = async () => {
    // This is a placeholder. You'll need to implement this based on your data structure
    setUpcomingEvents([
      { id: 1, title: "Production Meeting", date: "2023-06-15" },
      { id: 2, title: "Location Scouting", date: "2023-06-18" },
    ]);
  };

  const fetchBulletinBoard = async () => {
    try {
      const bulletinRef = collection(db, 'bulletinBoard');
      const q = query(
        bulletinRef, 
        where("production", "==", user.production),
        orderBy("createdAt", "desc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      setBulletinBoard(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching bulletin board:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const departmentsRef = collection(db, 'departments');
      const querySnapshot = await getDocs(departmentsRef);
      const departmentsData = {};
      querySnapshot.forEach(doc => {
        departmentsData[doc.id] = doc.data().name;
      });
      setDepartments(departmentsData);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchWeather = async () => {
    // Replace with your actual API key and location
    const API_KEY = 'your_openweathermap_api_key';
    const CITY = 'Los Angeles';
    try {
      const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${CITY}&appid=${API_KEY}&units=metric`);
      if (!response.ok) {
        throw new Error('Weather data fetch failed');
      }
      const data = await response.json();
      setWeather(data);
    } catch (error) {
      console.error("Error fetching weather:", error);
      setWeather(null); // Set weather to null if there's an error
    }
  };

  const fetchLocations = async () => {
    try {
      const locationsRef = collection(db, 'locations');
      const q = query(locationsRef, where("production", "==", user.production));
      const querySnapshot = await getDocs(q);
      const shootingLocations = [];
      const parkingLocations = [];
      querySnapshot.forEach(doc => {
        const location = doc.data();
        if (location.type === 'shooting') {
          shootingLocations.push(location);
        } else if (location.type === 'parking') {
          parkingLocations.push(location);
        }
      });
      setLocations({ shooting: shootingLocations, parking: parkingLocations });
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // Updated function to get the user's full name
  const getUserFullName = () => {
    if (user) {
      if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      } else if (user.name) {
        // If name is stored as a single field, return it as is
        return user.name;
      } else if (user.displayName) {
        // For users who might have signed up with Google
        return user.displayName;
      }
    }
    return 'User'; // Fallback if no name is available
  };

  const getDepartmentName = (departmentId) => {
    return departments[departmentId] || 'Unknown Department';
  };

  const getCurrentDate = () => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  };

  const handlePreviousDay = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const formatFirestoreTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString();
    }
    return 'Date not available';
  };

  const handleReceiptSubmit = async (receiptData) => {
    try {
      if (!receiptData.image || !receiptData.type || !receiptData.description) {
        setToast('Please provide all required receipt information including description');
        return;
      }

      // Get the latest user data from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      // Validate department and production information
      if (!userData.department || !userData.production) {
        console.error('Missing user data:', { userData });
        setToast('Error: Your profile is missing required information. Please contact your administrator.');
        return;
      }

      console.log('User Data:', {
        department: userData.department,
        production: userData.production
      });

      // Upload image to Firebase Storage with organized folder structure using department name
      const storage = getStorage();
      const imageRef = ref(storage, 
        `receipts/${userData.production}/${userData.department}/${receiptData.type.toLowerCase()}/${user.uid}/${Date.now()}_${receiptData.image.name}`
      );
      
      console.log('Uploading image...');
      await uploadBytes(imageRef, receiptData.image);
      console.log('Image uploaded successfully');
      
      const imageUrl = await getDownloadURL(imageRef);
      console.log('Image URL obtained:', imageUrl);

      // Save receipt data to Firestore with all relevant metadata
      const receiptDoc = {
        // Receipt Type and Basic Info
        type: receiptData.type,
        imageUrl: imageUrl,
        description: receiptData.description.trim(),
        amount: receiptData.amount || 0,
        status: 'Pending',

        // Submitter Information
        submittedBy: user.uid,
        submitterName: `${userData.firstName || ''} ${userData.lastName || ''}`.trim(),
        submitterEmail: userData.email || '',
        
        // Department Information using department name
        department: userData.department,  // This should be "Transportation" not the ID
        departmentId: userData.departmentId, // Store the ID separately if needed
        
        // Production Information
        production: userData.production,

        // Timestamps
        submissionDate: serverTimestamp(),
        createdAt: serverTimestamp(),
        
        // Additional Metadata
        category: receiptData.type.toLowerCase(),
        fiscalYear: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        
        // Receipt Details
        notes: receiptData.notes || '',
        location: receiptData.location || '',
        vendor: receiptData.vendor || ''
      };

      console.log('Saving receipt to Firestore:', receiptDoc);
      const docRef = await addDoc(collection(db, 'receipts'), receiptDoc);
      console.log('Receipt saved with ID:', docRef.id);

      setToast('Receipt submitted successfully');
      setShowReceiptPopup(false);
    } catch (error) {
      console.error('Error submitting receipt:', error);
      setToast(`Error submitting receipt: ${error.message}`);
    }
  };

  const openReceiptPopup = (type) => {
    setReceiptType(type);
    setShowReceiptPopup(true);
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        status: newStatus,
        lastStatusUpdate: serverTimestamp()
      });
      setUserStatus(newStatus);
      setToast(`Status updated to: ${newStatus}`);
    } catch (error) {
      console.error('Error updating status:', error);
      setToast('Failed to update status');
    }
  };

  return (
    <div className="home-container">
      <Sidebar />
      <div className="home-content">
        <header className="page-header">
          <h1>Welcome to the Production Management System</h1>
          <div className="status-selector">
            <select 
              value={userStatus} 
              onChange={handleStatusChange}
              className="status-dropdown"
            >
              <option value="">Set Status</option>
              <option value="10-1">10-1</option>
              <option value="On a run">On a run</option>
              <option value="10-8">10-8 (running late)</option>
            </select>
          </div>
        </header>
        <main>
          <section className="quick-links">
            <h2>Quick Links</h2>
            <ul>
              <li><Link to="/times" className="quick-link-btn">Times</Link></li>
              <li><Link to="/messages" className="quick-link-btn">Messages</Link></li>
              <li><Link to="/notifications" className="quick-link-btn">Notifications</Link></li>
              <li><button onClick={() => setShowAddVehiclePopup(true)} className="quick-link-btn">Add New Vehicle</button></li>
              <li><button onClick={() => openReceiptPopup('Fuel')} className="quick-link-btn">Fuel Receipts</button></li>
              <li><button onClick={() => openReceiptPopup('PCard')} className="quick-link-btn">P-Card Receipts</button></li>
              <li><button onClick={() => openReceiptPopup('Meal')} className="quick-link-btn">Meal Receipts</button></li>
            </ul>
          </section>
          <div className="home-grid">
            <div className="card production-info">
              <h3>Current Production</h3>
              {productionInfo ? (
                <>
                  <p><strong>{productionInfo.name}</strong></p>
                  <p>Status: {productionInfo.status}</p>
                  <p>Start Date: {formatFirestoreTimestamp(productionInfo.startDate)}</p>
                  <p>End Date: {formatFirestoreTimestamp(productionInfo.endDate)}</p>
                </>
              ) : (
                <p>No production assigned</p>
              )}
            </div>

            <div className="card shooting-days">
              <h3>Shooting Days</h3>
              <p className="shooting-days-info">
                Day {shootingDays.current} of {shootingDays.total}
              </p>
              <p className="shooting-days-remaining">
                {shootingDays.total - shootingDays.current} days remaining
              </p>
            </div>

            <div className="card upcoming-events">
              <h3>Upcoming Events</h3>
              <ul>
                {upcomingEvents.map(event => (
                  <li key={event.id}>{event.title} - {event.date}</li>
                ))}
              </ul>
            </div>

            <div className="card bulletin-board">
              <h3>Production Bulletin Board</h3>
              <ul>
                {bulletinBoard.map(post => (
                  <li key={post.id}>
                    <h4>{post.title}</h4>
                    <p>{post.content}</p>
                    <small>Posted on: {formatFirestoreTimestamp(post.createdAt)}</small>
                  </li>
                ))}
              </ul>
            </div>

            <div className="card weather">
              <h3>Weather</h3>
              {weather && weather.main && weather.weather ? (
                <div>
                  <p>{weather.name}</p>
                  <p>{Math.round(weather.main.temp)}°C</p>
                  <p>{weather.weather[0].description}</p>
                </div>
              ) : (
                <p>Loading weather data...</p>
              )}
            </div>

            <div className="card calendar">
              <div className="calendar-navigation">
                <button onClick={handlePreviousDay} className="calendar-nav-button">&#8592;</button>
                <div className="current-date">
                  {getCurrentDate()}
                </div>
                <button onClick={handleNextDay} className="calendar-nav-button">&#8594;</button>
              </div>
              <div className="calendar-info">
                {/* Add information for the selected day here */}
                <p>Events for {currentDate.toLocaleDateString()}:</p>
                <ul>
                  {upcomingEvents
                    .filter(event => new Date(event.date).toDateString() === currentDate.toDateString())
                    .map(event => (
                      <li key={event.id}>{event.title}</li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="card locations">
              <h3>Locations</h3>
              <div className="location-section">
                <h4>Shooting Locations</h4>
                <ul>
                  {locations.shooting.map((location, index) => (
                    <li key={index}>{location.name}</li>
                  ))}
                </ul>
              </div>
              <div className="location-section">
                <h4>Crew Parking</h4>
                <ul>
                  {locations.parking.map((location, index) => (
                    <li key={index}>{location.name}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card crew-info">
              <h3>Crew Information</h3>
            </div>

          </div>
        </main>
      </div>
      {showAddVehiclePopup && (
        <AddVehiclePopup onClose={() => setShowAddVehiclePopup(false)} />
      )}
      {showReceiptPopup && (
        <ReceiptSubmissionPopup
          type={receiptType}
          onClose={() => setShowReceiptPopup(false)}
          onSubmit={handleReceiptSubmit}
        />
      )}
      {toast && (
        <Toast 
          message={toast} 
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </div>
  );
}

export default Home;