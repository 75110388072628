import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import Toast from '../components/Toast';
import './ProductionDetails.css';

const ProductionDetails = () => {
  const { productionId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [production, setProduction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('overview');

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Not set';
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString();
    }
    return new Date(timestamp).toLocaleDateString();
  };

  useEffect(() => {
    if (productionId) {
      fetchProductionDetails();
    }
  }, [productionId]);

  const fetchProductionDetails = async () => {
    try {
      setLoading(true);
      const productionDoc = await getDoc(doc(db, 'productions', productionId));
      
      if (productionDoc.exists()) {
        const prodData = productionDoc.data();
        setProduction({
          id: productionDoc.id,
          ...prodData,
          startDate: formatDate(prodData.startDate),
          endDate: formatDate(prodData.endDate),
          createdAt: formatDate(prodData.createdAt),
          updatedAt: formatDate(prodData.updatedAt)
        });
      } else {
        setError('Production not found');
      }
    } catch (err) {
      console.error('Error fetching production details:', err);
      setError('Failed to load production details');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading production details...</div>;
  }

  if (error) {
    return <Toast message={error} type="error" />;
  }

  if (!production) {
    return <div className="error">Production not found</div>;
  }

  return (
    <div className="production-details-container">
      <Sidebar />
      <div className="production-details-content">
        <div className="production-details-header">
          <h1>{production.name}</h1>
          <div className="production-status">
            <span className={`status-badge ${production.status}`}>
              {production.status}
            </span>
          </div>
        </div>

        <div className="tabs">
          <button
            className={`tab ${activeTab === 'overview' ? 'active' : ''}`}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </button>
          <button
            className={`tab ${activeTab === 'departments' ? 'active' : ''}`}
            onClick={() => setActiveTab('departments')}
          >
            Departments
          </button>
          <button
            className={`tab ${activeTab === 'crew' ? 'active' : ''}`}
            onClick={() => setActiveTab('crew')}
          >
            Crew
          </button>
          <button
            className={`tab ${activeTab === 'schedule' ? 'active' : ''}`}
            onClick={() => setActiveTab('schedule')}
          >
            Schedule
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'overview' && (
            <div className="overview-tab">
              <div className="info-section">
                <h3>Production Details</h3>
                <div className="info-grid">
                  <div className="info-item">
                    <label>Start Date</label>
                    <p>{production.startDate}</p>
                  </div>
                  <div className="info-item">
                    <label>End Date</label>
                    <p>{production.endDate}</p>
                  </div>
                  <div className="info-item">
                    <label>Budget</label>
                    <p>${production.budget || 'Not set'}</p>
                  </div>
                  <div className="info-item">
                    <label>Description</label>
                    <p>{production.description || 'No description provided'}</p>
                  </div>
                  <div className="info-item">
                    <label>Created</label>
                    <p>{production.createdAt}</p>
                  </div>
                  <div className="info-item">
                    <label>Last Updated</label>
                    <p>{production.updatedAt}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'departments' && (
            <div className="departments-tab">
              <div className="departments-grid">
                {production.departments?.map((dept, index) => (
                  <div key={index} className="department-card">
                    <h3>{dept.name}</h3>
                    <p>{dept.description || 'No description provided'}</p>
                    <div className="department-info">
                      <p><strong>Head:</strong> {dept.headOfDepartment || 'Not assigned'}</p>
                      <p><strong>Status:</strong> {dept.status || 'Pending'}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'crew' && (
            <div className="crew-tab">
              <div className="crew-list">
                {production.crew?.map((member, index) => (
                  <div key={index} className="crew-card">
                    <h3>{member.name || 'Unnamed'}</h3>
                    <p>{member.role || 'No role specified'}</p>
                    <p>{member.department || 'No department assigned'}</p>
                  </div>
                ))}
                {(!production.crew || production.crew.length === 0) && (
                  <p className="no-data">No crew members assigned yet</p>
                )}
              </div>
            </div>
          )}

          {activeTab === 'schedule' && (
            <div className="schedule-tab">
              <p className="no-data">Schedule information will be displayed here</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductionDetails;
