import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebase';

const functions = getFunctions(app);

export const setUserRole = async (uid, role, productionId, departmentId) => {
  const setUserRoleFunction = httpsCallable(functions, 'setUserRole');
  try {
    const result = await setUserRoleFunction({ uid, role, productionId, departmentId });
    return result.data;
  } catch (error) {
    console.error('Error setting user role:', error);
    throw error;
  }
};