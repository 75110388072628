import React from 'react';
import Sidebar from '../components/Sidebar';
import './Users.css';
import '../styles/SharedStyles.css';

function Users() {
  return (
    <div className="users-container">
      <Sidebar />
      <div className="users-content">
        <header className="page-header">
          <h1>Users</h1>
          <p>Manage user accounts and permissions</p>
        </header>
        <main>
          <p>View and manage user accounts here.</p>
          {/* Add user management functionality here */}
        </main>
      </div>
    </div>
  );
}

export default Users;