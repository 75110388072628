import React, { useState } from 'react';
import { db } from '../services/firebase';
import { collection, addDoc } from 'firebase/firestore';

function AddVehicle() {
  const [vehicle, setVehicle] = useState({ make: '', model: '', year: '', licensePlate: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "vehicles"), vehicle);
    setVehicle({ make: '', model: '', year: '', licensePlate: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={vehicle.make}
        onChange={(e) => setVehicle({...vehicle, make: e.target.value})}
        placeholder="Make"
        required
      />
      <input
        type="text"
        value={vehicle.model}
        onChange={(e) => setVehicle({...vehicle, model: e.target.value})}
        placeholder="Model"
        required
      />
      <input
        type="number"
        value={vehicle.year}
        onChange={(e) => setVehicle({...vehicle, year: e.target.value})}
        placeholder="Year"
        required
      />
      <input
        type="text"
        value={vehicle.licensePlate}
        onChange={(e) => setVehicle({...vehicle, licensePlate: e.target.value})}
        placeholder="License Plate"
        required
      />
      <button type="submit">Add Vehicle</button>
    </form>
  );
}

export default AddVehicle;