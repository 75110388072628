import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import ProductionManagement from '../components/ProductionManagement';
import UserManagement from '../components/UserManagement';
import { PERMISSIONS, ROLE_PERMISSIONS } from '../constants/roles';
import '../styles/Management.css';

const Management = () => {
    const { user, loading } = useContext(AuthContext);

    // Redirect if not logged in
    if (!loading && !user) {
        return <Navigate to="/login" />;
    }

    // Check permissions
    const canManageProductions = user?.role && 
        ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.CREATE_PRODUCTION);
    const canManageUsers = user?.role && (
        ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.PROMOTE_TO_ADMIN) ||
        ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.PROMOTE_TO_SUPER_ADMIN)
    );

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (!canManageProductions && !canManageUsers) {
        return (
            <div className="management-container">
                <div className="alert alert-warning">
                    You do not have permission to access the management area.
                </div>
            </div>
        );
    }

    return (
        <div className="management-container">
            <h1>Management Dashboard</h1>
            
            <div className="management-grid">
                {canManageProductions && (
                    <div className="management-section">
                        <ProductionManagement />
                    </div>
                )}
                
                {canManageUsers && (
                    <div className="management-section">
                        <UserManagement />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Management;
