import React, { useState } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import './AddVehiclePopup.css';

function AddVehiclePopup({ onClose, onSuccess }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    licensePlate: '',
    licensePlateState: '', 
    unitNumber: '',
    status: 'available',
    department: user?.department || '',
    production: user?.production || '',
    createdAt: null,
    createdBy: user?.uid || '',
  });

  const [vehicleImages, setVehicleImages] = useState([]);
  const [damageImages, setDamageImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e, setFileFunction) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const validFiles = files.filter(file => {
        const isValidType = file.type.startsWith('image/');
        const isValidSize = file.size <= 5 * 1024 * 1024; 
        return isValidType && isValidSize;
      });
      setFileFunction(validFiles);
    }
  };

  const uploadFiles = async (files, folder) => {
    if (!files || files.length === 0) return [];
    
    const storage = getStorage();
    const urls = [];

    for (const file of files) {
      try {
        const timestamp = Date.now();
        const filename = `${timestamp}-${file.name}`;
        const imageRef = ref(storage, `${folder}/${user.uid}/${filename}`);
        
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        urls.push(url);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        throw error;
      }
    }

    return urls;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const [vehicleImageUrls, damageImageUrls, documentUrls] = await Promise.all([
        uploadFiles(vehicleImages, 'vehicles'),
        uploadFiles(damageImages, 'damage'),
        uploadFiles(documents, 'documents')
      ]);

      const db = getFirestore();
      const vehicleData = {
        ...newVehicle,
        imageUrls: vehicleImageUrls,
        damageImageUrls: damageImageUrls,
        documentUrls: documentUrls,
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        department: user.department,
        production: user.production,
        status: 'available'
      };

      const docRef = await addDoc(collection(db, 'vehicles'), vehicleData);
      
      if (onSuccess) {
        onSuccess(docRef.id);
      }
      
      onClose();
    } catch (error) {
      console.error("Error adding vehicle: ", error);
      setError("Failed to add vehicle. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-vehicle-popup">
      <div className="add-vehicle-popup-content">
        <h2>Add New Vehicle</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="license-plate-group">
            <div className="state-select">
              <select
                name="licensePlateState"
                value={newVehicle.licensePlateState}
                onChange={handleInputChange}
                required
              >
                <option value="">State</option>
                {stateAbbreviations.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </div>
            <div className="license-input">
              <input
                type="text"
                name="licensePlate"
                value={newVehicle.licensePlate}
                onChange={handleInputChange}
                placeholder="License Plate"
                required
              />
            </div>
          </div>
          <input
            type="text"
            name="make"
            value={newVehicle.make}
            onChange={handleInputChange}
            placeholder="Make"
            required
          />
          <input
            type="text"
            name="model"
            value={newVehicle.model}
            onChange={handleInputChange}
            placeholder="Model"
            required
          />
          <input
            type="number"
            name="year"
            value={newVehicle.year}
            onChange={handleInputChange}
            placeholder="Year"
            required
          />
          <input
            type="text"
            name="unitNumber"
            value={newVehicle.unitNumber}
            onChange={handleInputChange}
            placeholder="Unit Number"
            required
          />
          <div className="file-input-container">
            <label htmlFor="vehicleImages">Vehicle Pictures:</label>
            <input
              type="file"
              id="vehicleImages"
              onChange={(e) => handleFileChange(e, setVehicleImages)}
              accept="image/*"
              multiple
            />
            {vehicleImages.length > 0 && (
              <div className="selected-files">
                <p>Selected vehicle images:</p>
                <ul>
                  {vehicleImages.map((image, index) => (
                    <li key={index}>{image.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="file-input-container">
            <label htmlFor="damageImages">Damage Pictures:</label>
            <input
              type="file"
              id="damageImages"
              onChange={(e) => handleFileChange(e, setDamageImages)}
              accept="image/*"
              multiple
            />
            {damageImages.length > 0 && (
              <div className="selected-files">
                <p>Selected damage images:</p>
                <ul>
                  {damageImages.map((image, index) => (
                    <li key={index}>{image.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="file-input-container">
            <label htmlFor="documents">Document Pictures:</label>
            <input
              type="file"
              id="documents"
              onChange={(e) => handleFileChange(e, setDocuments)}
              accept="image/*"
              multiple
            />
            {documents.length > 0 && (
              <div className="selected-files">
                <p>Selected documents:</p>
                <ul>
                  {documents.map((doc, index) => (
                    <li key={index}>{doc.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="button-group">
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Adding Vehicle...' : 'Add Vehicle'}
            </button>
            <button type="button" onClick={onClose} disabled={isLoading}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddVehiclePopup;