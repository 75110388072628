import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, query, where, deleteDoc, setDoc, getFirestore, getDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { db, auth } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import { useAccessControl } from '../hooks/useAccessControl';
import Sidebar from '../components/Sidebar';
import './CrewMembers.css';
import '../styles/SharedStyles.css';
import Toast from '../components/Toast';

const LICENSE_OPTIONS = ['License', 'Class C', 'Class B', 'Class A'];

function CrewMembers() {
  const { user } = useAuth();
  const { hasAccess, isLoading: accessLoading, error: accessError, productionAccess, departmentAccess, applyFilters } = useAccessControl();
  const [crewMembers, setCrewMembers] = useState([]);
  const [filteredCrewMembers, setFilteredCrewMembers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [newCrewMember, setNewCrewMember] = useState({
    name: '',
    email: '',
    role: '',
    production: '',
    supervisor: '',
    department: '',
    rate: '',
    union: '' // Add union field
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editingRole, setEditingRole] = useState(null);
  const [departmentMap, setDepartmentMap] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toast, setToast] = useState(null);

  const reAuthenticateAdmin = async (adminEmail) => {
    try {
      // You would need to implement your admin authentication logic here
      // This could be through a separate auth instance or other secure means
      // For now, we'll redirect to the login page
      window.location.href = '/login';
    } catch (error) {
      console.error('Error re-authenticating admin:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchCrewMembers = async () => {
      if (!hasAccess || !user) {
        setCrewMembers([]);
        return;
      }

      try {
        let crewQuery = query(
          collection(db, 'users'),
          where('production', '==', productionAccess)
        );

        if (departmentAccess !== 'all') {
          crewQuery = query(
            collection(db, 'users'),
            where('production', '==', productionAccess),
            where('department', '==', departmentAccess)
          );
        }

        const querySnapshot = await getDocs(crewQuery);
        const crewData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Fetched crew members:', {
          total: crewData.length,
          production: productionAccess,
          department: departmentAccess
        });

        setCrewMembers(crewData);
      } catch (error) {
        console.error('Error fetching crew members:', error);
        setCrewMembers([]);
      }
    };

    fetchCrewMembers();
  }, [hasAccess, productionAccess, departmentAccess, user]);

  useEffect(() => {
    if (user) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchProductions(),
        fetchDepartments()
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'productions'));
      const productionList = querySnapshot.docs.map(doc => doc.data().name);
      setProductions(productionList);
    } catch (error) {
      console.error("Error fetching productions: ", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'departments'));
      const departmentList = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
      setDepartments(departmentList);
      
      // Create a map of department IDs to names
      const deptMap = {};
      departmentList.forEach(dept => {
        deptMap[dept.id] = dept.name;
      });
      setDepartmentMap(deptMap);
    } catch (error) {
      console.error("Error fetching departments:", error);
      setError("Failed to fetch departments. Please try again.");
    }
  };

  useEffect(() => {
    console.log('Filter effect running with:', {
      selectedProduction,
      selectedDepartment,
      searchTerm,
      crewMembers: crewMembers.length
    });

    // Ensure crewMembers is an array
    if (!Array.isArray(crewMembers)) {
      console.warn('crewMembers is not an array:', crewMembers);
      return;
    }

    let result = [...crewMembers];

    if (selectedProduction) {
      result = result.filter(member => {
        console.log('Filtering production for member:', member);
        return member && member.production === selectedProduction;
      });
      console.log('After production filter:', result.length);
    }

    if (selectedDepartment) {
      result = result.filter(member => {
        console.log('Filtering department for member:', member);
        return member && member.department === selectedDepartment;
      });
      console.log('After department filter:', result.length);
    }

    if (searchTerm && searchTerm.trim() !== '') {
      const searchTermLower = searchTerm.trim().toLowerCase();
      result = result.filter(member => {
        // Skip if member is null or undefined
        if (!member) {
          console.warn('Found null/undefined member during search');
          return false;
        }

        // Safely access properties with optional chaining
        const name = member?.name?.toLowerCase() || '';
        const role = member?.role?.toLowerCase() || '';
        const email = member?.email?.toLowerCase() || '';

        console.log('Searching member:', { name, role, email });

        return (
          name.includes(searchTermLower) ||
          role.includes(searchTermLower) ||
          email.includes(searchTermLower)
        );
      });
      console.log('After search filter:', result.length);
    }

    console.log('Setting filtered results:', result.length);
    setFilteredCrewMembers(result);
  }, [selectedProduction, selectedDepartment, searchTerm, crewMembers]);

  const handleProductionChange = (e) => setSelectedProduction(e.target.value);
  const handleDepartmentChange = (e) => setSelectedDepartment(e.target.value);
  const handleSearch = (e) => setSearchTerm(e.target.value);

  const generateTemporaryPassword = () => {
    // Return fixed password instead of generating a random one
    return 'TempPass123!';
  };

  const sendWelcomeEmail = async (email) => {
    // Implement a function to send a welcome email
    // This would typically be handled by a Cloud Function
    console.log(`Sending welcome email to ${email}`);
  };

  const handleAddCrewMember = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      // Validate required fields
      if (!newCrewMember.production || !newCrewMember.department) {
        setError('Production and Department are required fields');
        setIsSubmitting(false);
        return;
      }

      console.log('Starting crew member creation...'); 
      
      // Get production ID from production name
      const productionsQuery = query(
        collection(db, 'productions'),
        where('name', '==', newCrewMember.production)
      );
      const productionSnapshot = await getDocs(productionsQuery);
      
      if (productionSnapshot.empty) {
        throw new Error('Selected production not found');
      }
      
      const productionId = productionSnapshot.docs[0]?.id;

      // Get department name from ID
      const departmentDoc = await getDoc(doc(db, 'departments', newCrewMember.department));
      if (!departmentDoc.exists()) {
        throw new Error('Selected department not found');
      }
      const departmentName = departmentDoc.data().name;

      // Create auth account with temp password
      const tempPassword = 'TempPass123!';
      const userCredential = await createUserWithEmailAndPassword(
        auth, 
        newCrewMember.email, 
        tempPassword
      ).catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          throw new Error('Email is already registered');
        }
        throw error;
      });

      // Create user document
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: newCrewMember.email,
        name: newCrewMember.name,
        role: newCrewMember.role || 'Crew Member',
        production: productionAccess || newCrewMember.production,
        productionId: productionId,
        department: departmentName,
        departmentId: newCrewMember.department,
        rate: Number(newCrewMember.rate) || 0,
        union: newCrewMember.union || '',
        createdAt: Timestamp.now()
      });

      // Reset form
      setNewCrewMember({ 
        name: '', 
        email: '', 
        role: '', 
        production: '', 
        supervisor: '', 
        department: '', 
        rate: '', 
        union: '' 
      });

      setToast({
        type: 'success',
        message: `Successfully added ${newCrewMember.name} to the crew! Their temporary password is: ${tempPassword}`
      });

      // Refresh the crew members list without waiting for auth state
      fetchCrewMembers();

    } catch (error) {
      console.error("Error adding crew member: ", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCrewMembers = async () => {
    if (!hasAccess || !user) {
      setCrewMembers([]);
      return;
    }

    try {
      let crewQuery = query(
        collection(db, 'users'),
        where('production', '==', productionAccess)
      );

      if (departmentAccess !== 'all') {
        crewQuery = query(
          collection(db, 'users'),
          where('production', '==', productionAccess),
          where('department', '==', departmentAccess)
        );
      }

      const querySnapshot = await getDocs(crewQuery);
      const crewData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Fetched crew members:', {
        total: crewData.length,
        production: productionAccess,
        department: departmentAccess
      });

      setCrewMembers(crewData);
    } catch (error) {
      console.error('Error fetching crew members:', error);
      setCrewMembers([]);
    }
  };

  const handleRemoveFromProduction = async (memberId) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { production: null });
      const fetchCrewMembers = async () => {
        if (!hasAccess || !user) {
          setCrewMembers([]);
          return;
        }

        try {
          let crewQuery = query(
            collection(db, 'users'),
            where('production', '==', productionAccess)
          );

          if (departmentAccess !== 'all') {
            crewQuery = query(
              collection(db, 'users'),
              where('production', '==', productionAccess),
              where('department', '==', departmentAccess)
            );
          }

          const querySnapshot = await getDocs(crewQuery);
          const crewData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log('Fetched crew members:', {
            total: crewData.length,
            production: productionAccess,
            department: departmentAccess
          });

          setCrewMembers(crewData);
        } catch (error) {
          console.error('Error fetching crew members:', error);
          setCrewMembers([]);
        }
      };

      fetchCrewMembers();
    } catch (error) {
      console.error("Error removing from production: ", error);
    }
  };

  const handleAssignSupervisor = async (memberId, supervisorId) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { supervisor: supervisorId });
      const fetchCrewMembers = async () => {
        if (!hasAccess || !user) {
          setCrewMembers([]);
          return;
        }

        try {
          let crewQuery = query(
            collection(db, 'users'),
            where('production', '==', productionAccess)
          );

          if (departmentAccess !== 'all') {
            crewQuery = query(
              collection(db, 'users'),
              where('production', '==', productionAccess),
              where('department', '==', departmentAccess)
            );
          }

          const querySnapshot = await getDocs(crewQuery);
          const crewData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log('Fetched crew members:', {
            total: crewData.length,
            production: productionAccess,
            department: departmentAccess
          });

          setCrewMembers(crewData);
        } catch (error) {
          console.error('Error fetching crew members:', error);
          setCrewMembers([]);
        }
      };

      fetchCrewMembers();
    } catch (error) {
      console.error("Error assigning supervisor: ", error);
    }
  };

  const handleAssignProduction = async (memberId, productionName) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { production: productionName });
      const fetchCrewMembers = async () => {
        if (!hasAccess || !user) {
          setCrewMembers([]);
          return;
        }

        try {
          let crewQuery = query(
            collection(db, 'users'),
            where('production', '==', productionAccess)
          );

          if (departmentAccess !== 'all') {
            crewQuery = query(
              collection(db, 'users'),
              where('production', '==', productionAccess),
              where('department', '==', departmentAccess)
            );
          }

          const querySnapshot = await getDocs(crewQuery);
          const crewData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log('Fetched crew members:', {
            total: crewData.length,
            production: productionAccess,
            department: departmentAccess
          });

          setCrewMembers(crewData);
        } catch (error) {
          console.error('Error fetching crew members:', error);
          setCrewMembers([]);
        }
      };

      fetchCrewMembers();
    } catch (error) {
      console.error("Error assigning production: ", error);
      setError("Failed to assign production. Please try again.");
    }
  };

  const handleUpdateRate = async (memberId, newRate) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { rate: parseFloat(newRate) });
      const fetchCrewMembers = async () => {
        if (!hasAccess || !user) {
          setCrewMembers([]);
          return;
        }

        try {
          let crewQuery = query(
            collection(db, 'users'),
            where('production', '==', productionAccess)
          );

          if (departmentAccess !== 'all') {
            crewQuery = query(
              collection(db, 'users'),
              where('production', '==', productionAccess),
              where('department', '==', departmentAccess)
            );
          }

          const querySnapshot = await getDocs(crewQuery);
          const crewData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log('Fetched crew members:', {
            total: crewData.length,
            production: productionAccess,
            department: departmentAccess
          });

          setCrewMembers(crewData);
        } catch (error) {
          console.error('Error fetching crew members:', error);
          setCrewMembers([]);
        }
      };

      fetchCrewMembers();
    } catch (error) {
      console.error("Error updating rate: ", error);
      setError("Failed to update rate. Please try again.");
    }
  };

  const handleUpdateUnion = async (memberId, newUnion) => {
    try {
      await updateDoc(doc(db, 'users', memberId), { union: newUnion });
      const fetchCrewMembers = async () => {
        if (!hasAccess || !user) {
          setCrewMembers([]);
          return;
        }

        try {
          let crewQuery = query(
            collection(db, 'users'),
            where('production', '==', productionAccess)
          );

          if (departmentAccess !== 'all') {
            crewQuery = query(
              collection(db, 'users'),
              where('production', '==', productionAccess),
              where('department', '==', departmentAccess)
            );
          }

          const querySnapshot = await getDocs(crewQuery);
          const crewData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log('Fetched crew members:', {
            total: crewData.length,
            production: productionAccess,
            department: departmentAccess
          });

          setCrewMembers(crewData);
        } catch (error) {
          console.error('Error fetching crew members:', error);
          setCrewMembers([]);
        }
      };

      fetchCrewMembers();
    } catch (error) {
      console.error("Error updating union: ", error);
      setError("Failed to update union. Please try again.");
    }
  };

  const handleRoleUpdate = async (memberId, newRole) => {
    try {
      // Get default permissions for the new role
      let updatedPermissions = {
        canViewProductions: true,
        canEditProductions: false,
        canViewCrewMembers: true,
        canEditCrewMembers: false,
        canViewReports: false,
        canCreateReports: false,
        canViewVehicles: true,
        canEditVehicles: false,
        canManageUsers: false,
        canAssignRoles: false,
        canViewAllProductions: false,
      };

      // Adjust permissions based on role
      if (newRole === 'Supervisor') {
        updatedPermissions = {
          ...updatedPermissions,
          canEditCrewMembers: true,
          canViewReports: true,
          canCreateReports: true,
        };
      } else if (newRole === 'Production Admin') {
        updatedPermissions = {
          ...updatedPermissions,
          canEditProductions: true,
          canEditCrewMembers: true,
          canViewReports: true,
          canCreateReports: true,
          canEditVehicles: true,
          canAssignRoles: true,
        };
      }

      // Update user document with new role and permissions
      const userRef = doc(db, 'users', memberId);
      await updateDoc(userRef, {
        role: newRole,
        permissions: updatedPermissions,
        updatedAt: new Date().toISOString()
      });
      
      // Show success message
      setToast({
        type: 'success',
        message: 'Role updated successfully'
      });
      
      // Update local state
      setCrewMembers(prevMembers =>
        prevMembers.map(member =>
          member.id === memberId 
            ? { 
                ...member, 
                role: newRole,
                permissions: updatedPermissions
              } 
            : member
        )
      );
      setFilteredCrewMembers(prevMembers =>
        prevMembers.map(member =>
          member.id === memberId 
            ? { 
                ...member, 
                role: newRole,
                permissions: updatedPermissions
              } 
            : member
        )
      );
      
      // Close the edit mode
      setEditingRole(null);
    } catch (error) {
      console.error('Error updating role:', error);
      setToast({
        type: 'error',
        message: 'Failed to update role. Please try again.'
      });
    }
  };

  const formatRate = (rate) => {
    if (rate === null || rate === undefined || rate === '') {
      return '0.00';
    }
    const numRate = parseFloat(rate);
    return isNaN(numRate) ? '0.00' : numRate.toFixed(2);
  };

  const handleLicenseChange = async (memberId, newLicense) => {
    try {
      console.log('Updating license for member:', memberId, 'to:', newLicense);
      const userRef = doc(db, 'users', memberId);
      await updateDoc(userRef, {
        license: newLicense
      });
      
      // Update the local state
      setCrewMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === memberId 
            ? { ...member, license: newLicense }
            : member
        )
      );
      
      setToast({
        message: 'License updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating license:', error);
      setToast({
        message: 'Failed to update license',
        type: 'error'
      });
    }
  };

  // Early return if no access
  if (accessLoading) {
    return <div>Loading access permissions...</div>;
  }

  if (accessError) {
    return <div>Error: {accessError}</div>;
  }

  if (!hasAccess) {
    return <div>You don't have permission to view this page.</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <div className="crew-members-container">
      <Sidebar />
      <main className="crew-members-content">
        <header className="page-header">
          <h1>Crew Members</h1>
          <p>Manage and organize your production crew</p>
        </header>
        <section className="add-crew-member">
          <h2>Add New Crew Member</h2>
          <form onSubmit={handleAddCrewMember}>
            <input
              type="text"
              placeholder="Name"
              value={newCrewMember.name}
              onChange={(e) => setNewCrewMember({...newCrewMember, name: e.target.value})}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={newCrewMember.email}
              onChange={(e) => setNewCrewMember({...newCrewMember, email: e.target.value})}
              required
            />
            <select
              value={newCrewMember.role}
              onChange={(e) => setNewCrewMember({...newCrewMember, role: e.target.value})}
              required
            >
              <option value="">Select Role</option>
              <option value="Supervisor">Supervisor</option>
              <option value="Crew Member">Crew Member</option>
            </select>
            <select
              value={newCrewMember.production}
              onChange={(e) => setNewCrewMember({...newCrewMember, production: e.target.value})}
              required
            >
              <option value="">Select Production</option>
              {productions.map(prod => (
                <option key={prod} value={prod}>{prod}</option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Supervisor"
              value={newCrewMember.supervisor}
              onChange={(e) => setNewCrewMember({...newCrewMember, supervisor: e.target.value})}
            />
            {/* Add new department dropdown */}
            <select
              value={newCrewMember.department}
              onChange={(e) => setNewCrewMember({...newCrewMember, department: e.target.value})}
              required
            >
              <option value="">Select Department</option>
              {departments.map(dept => (
                <option key={dept.id} value={dept.id}>{dept.name}</option>
              ))}
            </select>
            <input
              type="number"
              step="0.01"
              placeholder="Hourly Rate"
              value={newCrewMember.rate}
              onChange={(e) => setNewCrewMember({...newCrewMember, rate: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Union"
              value={newCrewMember.union}
              onChange={(e) => setNewCrewMember({...newCrewMember, union: e.target.value})}
            />
            <button type="submit">Add Crew Member</button>
          </form>
        </section>
        <section className="crew-filters">
          <select onChange={handleProductionChange} value={selectedProduction}>
            <option value="">All Productions</option>
            {productions.map(production => (
              <option key={production} value={production}>{production}</option>
            ))}
          </select>
          <select onChange={handleDepartmentChange} value={selectedDepartment}>
            <option value="">All Departments</option>
            {departments.map(department => (
              <option key={department.id} value={department.id}>{department.name}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Search crew members..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </section>
        <section className="crew-member-list">
          <h2>Crew Members</h2>
          <div className="crew-member-grid">
            {filteredCrewMembers.map((member) => (
              <div key={member.id} className="crew-member-item">
                <h3>{member.name}</h3>
                <p>Email: {member.email}</p>
                <p>SSN (last 5): {member.lastFiveSSN}</p> 
                <div className="role-section">
                  {editingRole?.id === member.id ? (
                    <div className="role-edit">
                      <select
                        value={editingRole.role}
                        onChange={(e) => setEditingRole({ ...editingRole, role: e.target.value })}
                      >
                        <option value="Crew Member">Crew Member</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Production Admin">Production Admin</option>
                      </select>
                      <div className="role-actions">
                        <button 
                          onClick={() => handleRoleUpdate(member.id, editingRole.role)}
                          className="save-role"
                        >
                          Save
                        </button>
                        <button 
                          onClick={() => setEditingRole(null)}
                          className="cancel-role"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p 
                      className="role-display" 
                      onClick={() => user?.role === 'admin' && setEditingRole({ id: member.id, role: member.role })}
                    >
                      Role: <span className="role-value">{member.role}</span>
                      {user?.role === 'admin' && <span className="edit-icon">✎</span>}
                    </p>
                  )}
                </div>
                <p>Department: {departmentMap[member.department] || 'Not assigned'}</p>
                <p>Production: {member.production || 'Not assigned'}</p>
                <p>Supervisor: {crewMembers.find(m => m.id === member.supervisor)?.name || 'None'}</p>
                <p>Rate: ${formatRate(member.rate)}/hr</p>
                <p>Union: {member.union || 'Not specified'}</p>
                <input
                  type="text"
                  placeholder="Update Union"
                  onChange={(e) => handleUpdateUnion(member.id, e.target.value)}
                />
                <input
                  type="number"
                  step="0.01"
                  placeholder="Update Rate"
                  onChange={(e) => handleUpdateRate(member.id, e.target.value)}
                />
                <select
                  value={member.supervisor || ''}
                  onChange={(e) => handleAssignSupervisor(member.id, e.target.value)}
                >
                  <option value="">Assign Supervisor</option>
                  {crewMembers.filter(m => m.id !== member.id && m.role === 'Supervisor').map(m => (
                    <option key={m.id} value={m.id}>{m.name}</option>
                  ))}
                </select>
                <select
                  value={member.production || ''}
                  onChange={(e) => handleAssignProduction(member.id, e.target.value)}
                >
                  <option value="">Assign Production</option>
                  {productions.map(prod => (
                    <option key={prod} value={prod}>{prod}</option>
                  ))}
                </select>
                {member.production && (
                  <button onClick={() => handleRemoveFromProduction(member.id)}>
                    Remove from Production
                  </button>
                )}
                <div className="license-select-container">
                  <select
                    value={member.license || 'License'}
                    onChange={(e) => handleLicenseChange(member.id, e.target.value)}
                    className="license-select"
                  >
                    {LICENSE_OPTIONS.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        </section>

        {toast && (
          <Toast 
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </main>
    </div>
  );
}

export default CrewMembers;