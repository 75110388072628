import React, { useState, useRef } from 'react';
import './EditProfilePopup.css';

function EditProfilePopup({ profile, onSave, onCancel }) {
  const [editedProfile, setEditedProfile] = useState({
    ...profile,
    firstName: profile.name ? profile.name.split(' ')[0] : '',
    lastName: profile.name ? profile.name.split(' ').slice(1).join(' ') : '',
    address: profile.address || '',
    state: profile.state || '',
    zipcode: profile.zipcode || '',
    birthDate: profile.birthDate || '',
    lastFiveSSN: profile.lastFiveSSN || '',
    emergencyContacts: profile.emergencyContacts || [{ name: '', phone: '', email: '', address: '' }],
    vehicles: profile.vehicles || [{ make: '', color: '', plateNumber: '', plateState: '' }],
  });
  const [previewImage, setPreviewImage] = useState(profile.photoURL || 'default-avatar.png');
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const digits = value.replace(/\D/g, '');
    let formattedPhone = '';
    if (digits.length <= 3) {
      formattedPhone = `(${digits}`;
    } else if (digits.length <= 6) {
      formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
    setEditedProfile(prev => ({ ...prev, phone: formattedPhone }));
  };

  const handleEmergencyContactChange = (index, field, value) => {
    const updatedContacts = editedProfile.emergencyContacts.map((contact, i) => {
      if (i === index) {
        if (field === 'phone') {
          const digits = value.replace(/\D/g, '');
          let formattedPhone = '';
          if (digits.length <= 3) {
            formattedPhone = `(${digits}`;
          } else if (digits.length <= 6) {
            formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
          } else {
            formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
          }
          return { ...contact, [field]: formattedPhone };
        }
        return { ...contact, [field]: value };
      }
      return contact;
    });
    setEditedProfile(prev => ({ ...prev, emergencyContacts: updatedContacts }));
  };

  const addEmergencyContact = () => {
    setEditedProfile(prev => ({
      ...prev,
      emergencyContacts: [...prev.emergencyContacts, { name: '', phone: '', email: '', address: '' }]
    }));
  };

  const removeEmergencyContact = (index) => {
    setEditedProfile(prev => ({
      ...prev,
      emergencyContacts: prev.emergencyContacts.filter((_, i) => i !== index)
    }));
  };

  const handleSSNChange = (e) => {
    const { value } = e.target;
    // Only allow up to 5 digits
    const ssnValue = value.replace(/\D/g, '').slice(0, 5);
    setEditedProfile(prev => ({ ...prev, lastFiveSSN: ssnValue }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setEditedProfile(prev => ({ ...prev, newPhotoFile: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVehicleChange = (index, field, value) => {
    const updatedVehicles = editedProfile.vehicles.map((vehicle, i) => {
      if (i === index) {
        return { ...vehicle, [field]: value };
      }
      return vehicle;
    });
    setEditedProfile(prev => ({ ...prev, vehicles: updatedVehicles }));
  };

  const addVehicle = () => {
    setEditedProfile(prev => ({
      ...prev,
      vehicles: [...prev.vehicles, { make: '', color: '', plateNumber: '', plateState: '' }]
    }));
  };

  const removeVehicle = (index) => {
    setEditedProfile(prev => ({
      ...prev,
      vehicles: prev.vehicles.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      if (!editedProfile.firstName || !editedProfile.lastName) {
        throw new Error('First name and last name are required.');
      }

      const updatedProfile = {
        ...editedProfile,
        name: `${editedProfile.firstName} ${editedProfile.lastName}`.trim(),
      };

      console.log("Submitting updated profile:", updatedProfile);
      await onSave(updatedProfile);
      console.log("Profile saved successfully");
    } catch (err) {
      console.error('Error saving profile:', err);
      setError(err.message || 'An error occurred while saving the profile. Please try again.');
    }
  };

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  return (
    <div className="edit-profile-popup">
      <div className="edit-profile-popup-content">
        <h2>Edit Profile</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="profile-photo-info-group">
            <div className="profile-photo-editor">
              <img 
                src={previewImage} 
                alt="Profile" 
                className="profile-photo-preview" 
                onClick={handleImageClick}
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <p>Click to change photo</p>
            </div>
            <div className="personal-info-group">
              <div className="name-birth-ssn-group">
                <div className="form-group">
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={editedProfile.firstName || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="birthDate">Birth Date:</label>
                  <input
                    type="date"
                    id="birthDate"
                    name="birthDate"
                    value={editedProfile.birthDate || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name:</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={editedProfile.lastName || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastFiveSSN">Last 5 of SSN:</label>
                  <input
                    type="text"
                    id="lastFiveSSN"
                    name="lastFiveSSN"
                    value={editedProfile.lastFiveSSN || ''}
                    onChange={handleSSNChange}
                    maxLength="5"
                    placeholder="Last 5 digits"
                  />
                </div>
              </div>
              <div className="contact-group">
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editedProfile.email || ''}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone:</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={editedProfile.phone || ''}
                    onChange={handlePhoneChange}
                    placeholder="(___) ___-____"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              name="address"
              value={editedProfile.address || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={editedProfile.city || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group address-group">
            <div>
              <label htmlFor="state">State:</label>
              <input
                type="text"
                id="state"
                name="state"
                value={editedProfile.state || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="zipcode">Zipcode:</label>
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                value={editedProfile.zipcode || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="emergency-contacts">
            <h3>Emergency Contacts</h3>
            {editedProfile.emergencyContacts.map((contact, index) => (
              <div key={index} className="emergency-contact">
                <input
                  type="text"
                  placeholder="Name"
                  value={contact.name}
                  onChange={(e) => handleEmergencyContactChange(index, 'name', e.target.value)}
                />
                <input
                  type="tel"
                  placeholder="(___) ___-____"
                  value={contact.phone}
                  onChange={(e) => handleEmergencyContactChange(index, 'phone', e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={contact.email}
                  onChange={(e) => handleEmergencyContactChange(index, 'email', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={contact.address}
                  onChange={(e) => handleEmergencyContactChange(index, 'address', e.target.value)}
                />
                <button type="button" onClick={() => removeEmergencyContact(index)}>Remove</button>
              </div>
            ))}
            <button type="button" onClick={addEmergencyContact}>Add Emergency Contact</button>
          </div>
          <div className="vehicle-info">
            <h3>Vehicle Information</h3>
            {editedProfile.vehicles.map((vehicle, index) => (
              <div key={index} className="vehicle-fields-group">
                <div className="form-group">
                  <label htmlFor={`vehicleMake-${index}`}>Make:</label>
                  <input
                    type="text"
                    id={`vehicleMake-${index}`}
                    value={vehicle.make}
                    onChange={(e) => handleVehicleChange(index, 'make', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`vehicleColor-${index}`}>Color:</label>
                  <input
                    type="text"
                    id={`vehicleColor-${index}`}
                    value={vehicle.color}
                    onChange={(e) => handleVehicleChange(index, 'color', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`vehiclePlateNumber-${index}`}>Plate Number:</label>
                  <input
                    type="text"
                    id={`vehiclePlateNumber-${index}`}
                    value={vehicle.plateNumber}
                    onChange={(e) => handleVehicleChange(index, 'plateNumber', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`vehiclePlateState-${index}`}>Plate State:</label>
                  <select
                    id={`vehiclePlateState-${index}`}
                    value={vehicle.plateState}
                    onChange={(e) => handleVehicleChange(index, 'plateState', e.target.value)}
                  >
                    <option value="">Select State</option>
                    {states.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
                {index > 0 && (
                  <button type="button" onClick={() => removeVehicle(index)} className="remove-vehicle-button">
                    Remove Vehicle
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={addVehicle} className="add-vehicle-button">
              Add Another Vehicle
            </button>
          </div>
          <div className="button-group">
            <button type="submit" className="save-button">Save</button>
            <button type="button" onClick={onCancel} className="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditProfilePopup;