import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/UnderConstruction.css';

function UnderConstruction() {
  return (
    <div className="under-construction-container">
      <div className="under-construction-content">
        <h1>Under Construction</h1>
        <i className="fas fa-hard-hat construction-icon"></i>
        <p>We're working hard to bring you something amazing. Please check back soon!</p>
        <Link to="/" className="home-button">Back to Home</Link>
      </div>
    </div>
  );
}

export default UnderConstruction;
