import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, orderBy, getDocs, updateDoc, doc, serverTimestamp, addDoc, getDoc, Timestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import './RunRequests.css';

const RunRequests = ({ onSelectRequest, selectedRequest }) => {
  const [runRequests, setRunRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [drivers, setDrivers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [filteredCrewMembers, setFilteredCrewMembers] = useState([]);
  const [selectedCrewMembers, setSelectedCrewMembers] = useState({});
  const [supervisors, setSupervisors] = useState([]);
  const [filteredSupervisors, setFilteredSupervisors] = useState([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState({});
  const [error, setError] = useState(null);
  const [expandedRequest, setExpandedRequest] = useState(null);
  const [assignmentStatus, setAssignmentStatus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        console.error('No user found');
        setError('Please log in to view run requests.');
        setLoading(false);
        return;
      }

      console.log('Current user:', user);

      try {
        console.log('Fetching run requests for production:', 'the big test show');
        // Fetch run requests
        const runRequestsRef = collection(db, 'runRequests');
        const q = query(
          runRequestsRef,
          where('production', '==', 'the big test show'),
          where('status', '==', 'pending'),  
          orderBy('createdAt', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const requests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }));
        
        console.log('Fetched run requests:', requests);
        setRunRequests(requests);

        // Fetch users from the production
        console.log('Fetching users for production:', 'the big test show');
        const usersRef = collection(db, 'users');
        const usersQuery = query(
          usersRef,
          where('production', '==', 'the big test show')
        );
        
        const usersSnapshot = await getDocs(usersQuery);
        const users = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Filter for drivers
        const driversData = users.filter(user => 
          user.role?.toLowerCase().includes('driver') || 
          user.position?.toLowerCase().includes('driver')
        );
        
        // Filter for crew members (non-drivers)
        const crewData = users.filter(user => 
          !user.role?.toLowerCase().includes('driver') && 
          !user.position?.toLowerCase().includes('driver') &&
          user.department
        );

        // Filter for supervisors
        const supervisorsData = users.filter(user => 
          user.role?.toLowerCase().includes('supervisor')
        );

        console.log('Fetched users:', {
          total: users.length,
          drivers: driversData.length,
          crew: crewData.length,
          supervisors: supervisorsData.length
        });
        
        setDrivers(driversData);
        setFilteredDrivers(driversData);
        setCrewMembers(crewData);
        setFilteredCrewMembers(crewData);
        setSupervisors(supervisorsData);
        setFilteredSupervisors(supervisorsData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error loading data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const filtered = drivers.filter(driver =>
      driver.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      driver.position?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      driver.vehicle?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDrivers(filtered);

    const filteredCrew = crewMembers.filter(member =>
      member.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.position?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.department?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCrewMembers(filteredCrew);

    const filteredSupervisors = supervisors.filter(supervisor =>
      supervisor.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supervisor.position?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supervisor.department?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSupervisors(filteredSupervisors);
  }, [searchTerm, drivers, crewMembers, supervisors]);

  const handleStatusChange = async (requestId, newStatus) => {
    try {
      const selectedDriver = selectedDrivers[requestId];
      const selectedCrew = selectedCrewMembers[requestId] || [];
      const selectedSupervisor = selectedSupervisors[requestId];

      if (newStatus === 'accepted' && !selectedDriver) {
        alert('Please select a driver before accepting the request');
        return;
      }

      // Get the original request data
      const requestDoc = await getDoc(doc(db, 'runRequests', requestId));
      if (!requestDoc.exists()) {
        throw new Error('Run request not found');
      }
      const requestData = requestDoc.data();

      // Update the run request status
      const requestRef = doc(db, 'runRequests', requestId);
      await updateDoc(requestRef, {
        status: 'assigned', // Always set to 'assigned' when accepted
        updatedAt: serverTimestamp(),
        assignedDriver: newStatus === 'accepted' ? selectedDriver : null,
        assignedCrew: newStatus === 'accepted' ? selectedCrew : [],
        assignedSupervisor: newStatus === 'accepted' ? selectedSupervisor : null
      });

      // If the request is accepted, create a dispatched run
      if (newStatus === 'accepted') {
        // Create the base run data
        const baseRunData = {
          title: `${requestData.requestType.toUpperCase()} - ${requestData.description}`,
          date: Timestamp.fromDate(new Date(requestData[requestData.requestType].date)),
          status: 'Pending',
          runType: requestData.requestType,
          address: `${requestData[requestData.requestType].address.streetNumber} ${requestData[requestData.requestType].address.streetName}${requestData[requestData.requestType].address.suite ? ` Suite ${requestData[requestData.requestType].address.suite}` : ''}, ${requestData[requestData.requestType].address.city}, ${requestData[requestData.requestType].address.state} ${requestData[requestData.requestType].address.zipCode}`,
          itemDescription: requestData.description,
          pictureUrl: requestData.photoUrls?.[0] || null,
          createdAt: serverTimestamp(),
          createdBy: requestData.createdBy,
          production: requestData.production,
          department: requestData.department,
          assignedAt: serverTimestamp(),
          originalRequestId: requestId
        };

        // Create a dispatched run for the driver and each crew member
        const allAssignees = [selectedDriver, ...selectedCrew];
        await Promise.all(allAssignees.map(async (assigneeId) => {
          const runData = {
            ...baseRunData,
            assignedTo: assigneeId
          };
          await addDoc(collection(db, 'dispatchedRuns'), runData);
        }));

        // Create notifications for all assigned members
        const notificationsRef = collection(db, 'notifications');
        await Promise.all(allAssignees.map(async (assigneeId) => {
          await addDoc(notificationsRef, {
            forUserId: assigneeId,
            type: 'dispatchedRun',
            title: 'New Run Assignment',
            message: `You have been assigned to a ${requestData.requestType} run: ${requestData.description}`,
            status: 'unread',
            createdAt: serverTimestamp(),
            production: requestData.production,
            department: requestData.department
          });
        }));
      }

      // Remove the assigned request from local state
      setRunRequests(prev => prev.filter(request => request.id !== requestId));

      // Clear selected driver, crew and supervisor for this request
      setSelectedDrivers(prev => {
        const updated = { ...prev };
        delete updated[requestId];
        return updated;
      });
      setSelectedCrewMembers(prev => {
        const updated = { ...prev };
        delete updated[requestId];
        return updated;
      });
      setSelectedSupervisors(prev => {
        const updated = { ...prev };
        delete updated[requestId];
        return updated;
      });
    } catch (error) {
      console.error('Error updating run request status:', error);
    }
  };

  const handleDriverSelection = (requestId, driverId) => {
    setSelectedDrivers(prev => ({
      ...prev,
      [requestId]: driverId
    }));
  };

  const handleCrewMemberSelection = (requestId, memberId) => {
    setSelectedCrewMembers(prev => {
      const currentSelected = prev[requestId] || [];
      const updated = currentSelected.includes(memberId)
        ? currentSelected.filter(id => id !== memberId)
        : [...currentSelected, memberId];
      
      return {
        ...prev,
        [requestId]: updated
      };
    });
  };

  const handleSupervisorSelection = (requestId, supervisorId) => {
    setSelectedSupervisors(prev => ({
      ...prev,
      [requestId]: supervisorId
    }));
  };

  const handleSelectAllCrew = (requestId) => {
    setSelectedCrewMembers(prev => ({
      ...prev,
      [requestId]: filteredCrewMembers.map(member => member.id)
    }));
  };

  const handleDeselectAllCrew = (requestId) => {
    setSelectedCrewMembers(prev => ({
      ...prev,
      [requestId]: []
    }));
  };

  const handleSelectAllSupervisors = (requestId) => {
    setSelectedSupervisors(prev => ({
      ...prev,
      [requestId]: filteredSupervisors.map(supervisor => supervisor.id)
    }));
  };

  const handleDeselectAllSupervisors = (requestId) => {
    setSelectedSupervisors(prev => ({
      ...prev,
      [requestId]: []
    }));
  };

  const handleAssignAndSend = async (requestId) => {
    try {
      setAssignmentStatus(prev => ({ ...prev, [requestId]: 'processing' }));
      
      // Get the selected crew members for this request
      const selectedMembers = selectedCrewMembers[requestId] || [];
      
      if (selectedMembers.length === 0) {
        alert('Please select at least one crew member');
        return;
      }

      // Get the crew member details
      const selectedMemberDetails = crewMembers.filter(member => 
        selectedMembers.includes(member.id)
      );

      // Update the run request in Firestore
      const requestRef = doc(db, 'runRequests', requestId);
      await updateDoc(requestRef, {
        status: 'assigned',
        assignedTo: selectedMembers,
        assignedAt: serverTimestamp(),
        assignedBy: user.uid,
        notificationSent: true
      });

      // Create notifications for each assigned crew member
      const notificationsRef = collection(db, 'notifications');
      const request = runRequests.find(r => r.id === requestId);

      await Promise.all(selectedMemberDetails.map(member => 
        addDoc(notificationsRef, {
          userId: member.id,
          type: 'run_request_assignment',
          title: 'New Run Request Assignment',
          message: `You have been assigned a new run request: ${request.name}`,
          requestId: requestId,
          createdAt: serverTimestamp(),
          read: false,
          production: request.production
        })
      ));

      // Update local state
      setRunRequests(prev => prev.map(request => 
        request.id === requestId 
          ? { 
              ...request, 
              status: 'assigned',
              assignedTo: selectedMembers,
              assignedAt: new Date(),
              assignedBy: user.uid
            }
          : request
      ));

      setAssignmentStatus(prev => ({ ...prev, [requestId]: 'success' }));
      
      // Clear selection after successful assignment
      setSelectedCrewMembers(prev => {
        const updated = { ...prev };
        delete updated[requestId];
        return updated;
      });

      setTimeout(() => {
        setAssignmentStatus(prev => {
          const updated = { ...prev };
          delete updated[requestId];
          return updated;
        });
      }, 2000);

    } catch (error) {
      console.error('Error assigning run request:', error);
      setAssignmentStatus(prev => ({ ...prev, [requestId]: 'error' }));
    }
  };

  const handleRequestClick = (request) => {
    setExpandedRequest(expandedRequest === request.id ? null : request.id);
  };

  const handleCheckboxClick = (e, request) => {
    e.stopPropagation();
    onSelectRequest(selectedRequest?.id === request.id ? null : request);
  };

  return (
    <div className="run-requests">
      {loading ? (
        <div className="loading-state">Loading run requests...</div>
      ) : error ? (
        <div className="error-state">{error}</div>
      ) : (
        <>
          <h2>Run Requests</h2>
          <div className="run-requests-container">
            {runRequests.length === 0 ? (
              <p className="run-requests-no-requests">No pending run requests</p>
            ) : (
              runRequests.map(request => (
                <div
                  key={request.id}
                  className={`run-request-item ${expandedRequest === request.id ? 'expanded' : ''}`}
                  onClick={() => handleRequestClick(request)}
                >
                  <div className="run-request-header" onClick={(e) => e.stopPropagation()}>
                    <div className="header-content" onClick={() => handleRequestClick(request)}>
                      <h3>{request.name} - {request.department}</h3>
                      <span className={`status ${request.status || 'pending'}`}>
                        {request.status || 'Pending'}
                      </span>
                    </div>
                    <div className="select-checkbox-container">
                      <input
                        type="checkbox"
                        id={`select-request-${request.id}`}
                        className="select-checkbox"
                        checked={selectedRequest?.id === request.id}
                        onChange={(e) => handleCheckboxClick(e, request)}
                      />
                      <label 
                        htmlFor={`select-request-${request.id}`}
                        className="select-checkbox-label"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Select Request
                      </label>
                    </div>
                  </div>
                  <div className="run-request-info">
                    <p><strong>Type:</strong> {request.requestType}</p>
                    <p><strong>Date:</strong> {request[request.requestType]?.date || 'Not specified'}</p>
                    <p><strong>Time:</strong> {request[request.requestType]?.time || 'Not specified'}</p>
                    <p><strong>Address:</strong> {
                      request[request.requestType]?.address ? (
                        `${request[request.requestType].address.streetNumber} ${request[request.requestType].address.streetName}${
                          request[request.requestType].address.suite ? `, Suite ${request[request.requestType].address.suite}` : ''
                        }, ${request[request.requestType].address.city}, ${request[request.requestType].address.state} ${request[request.requestType].address.zipCode}`
                      ) : 'Not specified'
                    }</p>
                  </div>
                  {request.description && (
                    <div className="run-request-description">
                      <p>{request.description}</p>
                    </div>
                  )}
                  {request.photoUrls && request.photoUrls.length > 0 && (
                    <div className="run-request-photos">
                      <div className="photo-count">
                        {request.photoUrls.length} photo{request.photoUrls.length !== 1 ? 's' : ''} attached
                      </div>
                      {expandedRequest === request.id && (
                        <div className="photo-grid">
                          {request.photoUrls.map((url, index) => (
                            <div key={index} className="photo-item">
                              <img src={url} alt={`Run request photo ${index + 1}`} />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {expandedRequest === request.id && (
                    <div className="expanded-content">
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RunRequests;
