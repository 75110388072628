import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from '../hooks/useAuth';
import { ROLES, PERMISSIONS, ROLE_PERMISSIONS } from '../constants/roles';
import './Sidebar.css';
import './ResponsiveSidebar.css';
import logo from '../Assets/Slate-Logo.png';
import profilePlaceholder from '../Assets/scph/Profile placeholder.png';
import { collection, query, where, getDocs, onSnapshot, Timestamp, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

function Sidebar({ isOpen }) {
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [error, setError] = useState(null);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState({});

  useEffect(() => {
    if (!user) return;

    console.log('Setting up notification listeners for user:', user.uid);

    // Query for notifications
    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('forUserId', '==', user.uid),
      orderBy('createdAt', 'desc')
    );

    // Subscribe to notifications
    const unsubscribeNotifications = onSnapshot(notificationsQuery, (snapshot) => {
      try {
        const unreadNotifications = snapshot.docs
          .filter(doc => doc.data().status === 'unread')
          .length;
          
        console.log('Notifications found:', {
          total: snapshot.docs.length,
          unread: unreadNotifications,
          notifications: snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate()?.toISOString()
          }))
        });
        setUnreadCount(prev => ({ ...prev, notifications: unreadNotifications }));
      } catch (error) {
        console.error('Error processing notifications:', error);
        setError('Error loading notifications. Please try refreshing the page.');
      }
    }, (error) => {
      console.error('Error in notifications listener:', error);
      // Check if error is related to indexes
      if (error.code === 'failed-precondition') {
        console.log('Index error detected, using simpler query');
        // Try simpler query without ordering
        const simpleQuery = query(
          collection(db, 'notifications'),
          where('forUserId', '==', user.uid)
        );
        return onSnapshot(simpleQuery, (snapshot) => {
          try {
            const unreadNotifications = snapshot.docs
              .filter(doc => doc.data().status === 'unread')
              .length;
            setUnreadCount(prev => ({ ...prev, notifications: unreadNotifications }));
          } catch (innerError) {
            console.error('Error in simple query:', innerError);
            setError('Error loading notifications. Please contact support.');
          }
        });
      }
      setError('Failed to fetch notifications. Please try refreshing the page.');
    });

    // Query for unacknowledged time entries
    const timeEntriesQuery = query(
      collection(db, 'timeEntries'),
      where('userId', '==', user.uid),
      where('acknowledged', '==', false)
    );

    // Query for unacknowledged runs
    const runsQuery = query(
      collection(db, 'dispatchedRuns'),
      where('assignedTo', 'array-contains', user.uid),
      where('acknowledged', '==', false)
    );

    const unsubscribeTime = onSnapshot(timeEntriesQuery, (snapshot) => {
      try {
        const unreadCount = snapshot.docs.length;
        console.log('Time entries found:', {
          total: snapshot.docs.length,
          unread: unreadCount,
          entries: snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        });
        setUnreadCount(prev => ({ ...prev, times: unreadCount }));
      } catch (error) {
        console.error('Error processing time entries:', error);
        setError('Error loading time entries. Please try refreshing the page.');
      }
    }, (error) => {
      console.error('Error in time entries listener:', error);
      setError('Failed to fetch time entries. Please try refreshing the page.');
    });

    const unsubscribeRuns = onSnapshot(runsQuery, (snapshot) => {
      try {
        const unreadCount = snapshot.docs.length;
        console.log('Runs found:', {
          total: snapshot.docs.length,
          unread: unreadCount,
          runs: snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        });
        setUnreadCount(prev => ({ ...prev, runs: unreadCount }));
      } catch (error) {
        console.error('Error processing runs:', error);
        setError('Error loading runs. Please try refreshing the page.');
      }
    }, (error) => {
      console.error('Error in runs listener:', error);
      setError('Failed to fetch runs. Please try refreshing the page.');
    });

    return () => {
      console.log('Cleaning up notification listeners');
      unsubscribeNotifications();
      unsubscribeTime();
      unsubscribeRuns();
    };
  }, [user]);

  // Calculate total unread count
  const totalUnread = Object.values(unreadCount).reduce((a, b) => a + b, 0);

  const fetchNewNotificationsCount = async () => {
    // Implement this function to fetch new notifications count
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
      setError("Failed to log out. Please try again.");
    }
  };

  if (!isOpen) return null;

  if (!user) {
    return null; // or return a loading indicator
  }

  const isAdmin = user && ['admin', 'productionAdmin', 'superAdmin', 'production_admin', 'app_admin'].includes(user.role);
  const isSupervisor = user && (user.role === 'supervisor' || user.role === 'Supervisor');

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src={logo} alt="Slate Fusion Logo" className="sidebar-logo" />
        <h1 className="sidebar-title">Slate Fusion</h1>
      </div>
      <ul className="sidebar-menu">
        <li><Link to="/welcome">👋 Welcome</Link></li>
        <li><Link to="/home">🏠 Home</Link></li>
        <li><Link to="/times">⏱️ Times</Link></li>
        <li><Link to="/messages">✉️ Messages</Link></li>
        <li>
          <Link to="/notifications">
            🔔 Notifications
            {totalUnread > 0 && (
              <span className="notification-count">{totalUnread}</span>
            )}
          </Link>
        </li>
        {(isAdmin || isSupervisor) && (
          <>
            <li><Link to="/dashboard">🎛️ Dashboard</Link></li>
            {isAdmin && <li><Link to="/productions">🎬 Productions</Link></li>}
            <li><Link to="/dispatch">📟 Dispatch</Link></li>
            <li><Link to="/reports">📊 Reports</Link></li>
            <li><Link to="/crew-members">👥 Crew Members</Link></li>
            <li><Link to="/departments">🎭 Departments</Link></li>
            <li><Link to="/vehicles-page">🚛 Production Vehicles</Link></li>
            <li><Link to="/budget">🧮 Budget</Link></li>
          </>
        )}
        {user?.role && ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.PROMOTE_TO_ADMIN) ||
            ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.PROMOTE_TO_SUPER_ADMIN) && (
          <li>
            <Link to="/management">
              <i className="fas fa-cogs"></i>
              <span>Management</span>
            </Link>
          </li>
        )}
      </ul>
      <div className="sidebar-bottom">
        <ul className="sidebar-menu">
          <li><Link to="/profile">🪪 Profile</Link></li>
          <li><Link to="/settings">⚙️ Settings</Link></li>
        </ul>
      </div>
      <div className="sidebar-footer">
        <div className="user-info">
          <img src={user?.photoURL || profilePlaceholder} alt="User Avatar" className="user-avatar" />
          <div className="user-details">
            <span className="user-role">Role: {user?.role}</span>
            <span className="user-email">{user?.email}</span>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default Sidebar;
