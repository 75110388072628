import React, { useState } from 'react';
import { db } from '../services/firebase';
import { collection, addDoc } from 'firebase/firestore';

function AddNotification() {
  const [notification, setNotification] = useState({ title: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "notifications"), {
      ...notification,
      createdAt: new Date(),
      status: 'unread'
    });
    setNotification({ title: '', message: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={notification.title}
        onChange={(e) => setNotification({...notification, title: e.target.value})}
        placeholder="Notification Title"
        required
      />
      <textarea
        value={notification.message}
        onChange={(e) => setNotification({...notification, message: e.target.value})}
        placeholder="Notification Message"
        required
      />
      <button type="submit">Add Notification</button>
    </form>
  );
}

export default AddNotification;