import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, increment } from 'firebase/firestore';

const calculateExpirationTime = (duration) => {
  const now = new Date();
  switch(duration) {
    case '6h':
      return now.getTime() + (6 * 60 * 60 * 1000);
    case '12h':
      return now.getTime() + (12 * 60 * 60 * 1000);
    case '24h':
      return now.getTime() + (24 * 60 * 60 * 1000);
    case '48h':
      return now.getTime() + (48 * 60 * 60 * 1000);
    case '5d':
      return now.getTime() + (5 * 24 * 60 * 60 * 1000);
    case '7d':
      return now.getTime() + (7 * 24 * 60 * 60 * 1000);
    default:
      return now.getTime() + (6 * 60 * 60 * 1000); // Default to 6 hours
  }
};

export function useAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        
        // Set session expiration based on user preferences
        const sessionDuration = userData?.sessionDuration || '6h';
        const expirationTime = calculateExpirationTime(sessionDuration);
        localStorage.setItem('sessionExpiration', expirationTime.toString());
        
        setUser({ ...user, ...userData });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // Check session expiration periodically
    const checkSession = setInterval(() => {
      const expiration = localStorage.getItem('sessionExpiration');
      if (expiration && new Date().getTime() > parseInt(expiration)) {
        auth.signOut();
        localStorage.removeItem('sessionExpiration');
      }
    }, 60000); // Check every minute

    return () => {
      unsubscribe();
      clearInterval(checkSession);
    };
  }, []);

  return { user, setUser, loading };
}