import React, { useContext, useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { collection, addDoc, updateDoc, doc, query, where, getDocs } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';

function TimeSheet() {
  const [times, setTimes] = useState([]);
  const [newTime, setNewTime] = useState({ date: '', startTime: '', endTime: '', description: '' });
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchTimes();
  }, [user.uid]);

  const fetchTimes = async () => {
    const q = query(collection(db, "times"), where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);
    setTimes(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const addTime = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "times"), {
      ...newTime,
      userId: user.uid,
    });
    setNewTime({ date: '', startTime: '', endTime: '', description: '' });
    fetchTimes();
  };

  const editTime = async (id, updatedTime) => {
    await updateDoc(doc(db, "times", id), updatedTime);
    fetchTimes();
  };

  return (
    <div>
      <h2>Time Sheet</h2>
      <form onSubmit={addTime}>
        {/* Add input fields for date, start time, end time, and description */}
        <button type="submit">Add Time</button>
      </form>
      <ul>
        {times.map(time => (
          <li key={time.id}>
            {/* Display time details and add edit functionality */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TimeSheet;