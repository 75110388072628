import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const updateUserProductionAccess = async (userId, productionId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      production: productionId
    });
    return true;
  } catch (error) {
    console.error('Error updating user production access:', error);
    throw error;
  }
};
