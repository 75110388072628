import React, { useState, useEffect } from 'react';
import '../styles/Toast.css';

function Toast({ message, duration = 3000, onClose, type }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!isVisible) return null;

  return (
    <div className={`toast ${type}`}>
      <p>{message}</p>
    </div>
  );
}

export default Toast;
