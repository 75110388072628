// Role definitions
export const ROLES = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  USER: 'user',
  APP_ADMIN: 'appAdmin',
  PRODUCTION_ADMIN: 'productionAdmin',
  PRODUCTION_SUPERVISOR: 'productionSupervisor',
  DEPARTMENT_HEAD: 'departmentHead',
  CREW_MEMBER: 'crewMember'
};

// Permission definitions
export const PERMISSIONS = {
  // System-wide permissions
  MANAGE_APP_SETTINGS: 'manageAppSettings',
  VIEW_ALL_PRODUCTIONS: 'viewAllProductions',
  MANAGE_ALL_PRODUCTIONS: 'manageAllProductions',
  MANAGE_APP_USERS: 'manageAppUsers',
  CREATE_PRODUCTION: 'createProduction',
  
  // Production-specific permissions
  MANAGE_PRODUCTION: 'manageProduction',
  MANAGE_PRODUCTION_USERS: 'manageProductionUsers',
  MANAGE_DEPARTMENTS: 'manageDepartments',
  MANAGE_CREW: 'manageCrew',
  VIEW_PRODUCTION_DATA: 'viewProductionData',
  EDIT_PRODUCTION_DATA: 'editProductionData',
  
  // Department-specific permissions
  MANAGE_DEPARTMENT: 'manageDepartment',
  VIEW_DEPARTMENT_DATA: 'viewDepartmentData',
  EDIT_DEPARTMENT_DATA: 'editDepartmentData'
};

// Role-based permissions mapping
export const ROLE_PERMISSIONS = {
  [ROLES.SUPER_ADMIN]: [
    ...Object.values(PERMISSIONS) // Super admin has all permissions
  ],
  
  [ROLES.ADMIN]: [
    PERMISSIONS.VIEW_ALL_PRODUCTIONS,
    PERMISSIONS.MANAGE_ALL_PRODUCTIONS,
    PERMISSIONS.CREATE_PRODUCTION,
    PERMISSIONS.MANAGE_PRODUCTION,
    PERMISSIONS.MANAGE_PRODUCTION_USERS,
    PERMISSIONS.MANAGE_DEPARTMENTS,
    PERMISSIONS.MANAGE_CREW,
    PERMISSIONS.VIEW_PRODUCTION_DATA,
    PERMISSIONS.EDIT_PRODUCTION_DATA
  ],
  
  [ROLES.USER]: [
    PERMISSIONS.VIEW_PRODUCTION_DATA
  ],
  
  [ROLES.APP_ADMIN]: [
    PERMISSIONS.VIEW_ALL_PRODUCTIONS,
    PERMISSIONS.MANAGE_APP_SETTINGS,
    PERMISSIONS.MANAGE_APP_USERS,
    PERMISSIONS.VIEW_PRODUCTION_DATA,
    PERMISSIONS.EDIT_PRODUCTION_DATA
  ],
  
  [ROLES.PRODUCTION_ADMIN]: [
    PERMISSIONS.MANAGE_PRODUCTION,
    PERMISSIONS.MANAGE_PRODUCTION_USERS,
    PERMISSIONS.MANAGE_DEPARTMENTS,
    PERMISSIONS.MANAGE_CREW,
    PERMISSIONS.VIEW_PRODUCTION_DATA,
    PERMISSIONS.EDIT_PRODUCTION_DATA
  ],
  
  [ROLES.PRODUCTION_SUPERVISOR]: [
    PERMISSIONS.MANAGE_DEPARTMENT,
    PERMISSIONS.VIEW_DEPARTMENT_DATA,
    PERMISSIONS.EDIT_DEPARTMENT_DATA,
    PERMISSIONS.VIEW_PRODUCTION_DATA
  ]
};

/**
 * Check if a user has a specific permission
 * @param {string} userRole - The user's role
 * @param {string} permission - The permission to check
 * @param {string} [productionId] - Optional production ID for production-specific checks
 * @param {string} [departmentId] - Optional department ID for department-specific checks
 * @returns {boolean}
 */
export const hasPermission = (userRole, permission, productionId = null, departmentId = null) => {
  if (!userRole) return false;
  
  // Super admin always has all permissions
  if (userRole === ROLES.SUPER_ADMIN) return true;

  const rolePermissions = ROLE_PERMISSIONS[userRole] || [];
  return rolePermissions.includes(permission);
};

export const canCreateProduction = (userRole) => {
  return hasPermission(userRole, PERMISSIONS.CREATE_PRODUCTION);
};

/**
 * Check if a user has access to a specific production
 */
export const hasProductionAccess = (userRole, userId, productionId) => {
  // Super admin and app admin have access to all productions
  if (userRole === ROLES.SUPER_ADMIN || userRole === ROLES.APP_ADMIN) return true;

  // For production admin and supervisors, check their production assignment
  return checkProductionAssignment(userId, productionId);
};

/**
 * Check if a user can modify production settings
 */
export const canModifyProduction = (userRole, userId, productionId) => {
  // Super admin can modify any production
  if (userRole === ROLES.SUPER_ADMIN) return true;

  // App admin can modify for support purposes
  if (userRole === ROLES.APP_ADMIN) return true;

  // Production admin can only modify their own production
  if (userRole === ROLES.PRODUCTION_ADMIN) {
    return checkProductionAssignment(userId, productionId);
  }

  return false;
};

/**
 * Check if a user can manage departments
 */
export const canManageDepartments = (userRole, userId, productionId) => {
  // Super admin and production admin can manage departments
  if (userRole === ROLES.SUPER_ADMIN) return true;
  
  if (userRole === ROLES.PRODUCTION_ADMIN) {
    return checkProductionAssignment(userId, productionId);
  }

  return false;
};

/**
 * Helper function to check if a user is assigned to a production
 */
const checkProductionAssignment = async (userId, productionId) => {
  // Implementation would check the productionUsers collection
  // This is a placeholder - implement actual check based on your data structure
  return true; // Temporary return
};

/**
 * Get list of productions accessible to a user
 */
export const getAccessibleProductions = async (userRole, userId) => {
  if (userRole === ROLES.SUPER_ADMIN || userRole === ROLES.APP_ADMIN) {
    // Return all productions
    return getAllProductions();
  }

  // For other roles, return only assigned productions
  return getUserAssignedProductions(userId);
};

// Helper functions to be implemented based on your data structure
const getAllProductions = async () => {
  // Implementation to get all productions
};

const getUserAssignedProductions = async (userId) => {
  // Implementation to get user's assigned productions
};
