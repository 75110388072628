import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import ReceiptSubmissionPopup from '../components/ReceiptSubmissionPopup';
import './MealReceipts.css';

function MealReceipts() {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    fetchMealReceipts();
  }, [user]);

  const fetchMealReceipts = async () => {
    if (!user || !user.productionId) return;

    setLoading(true);
    try {
      const receiptsRef = collection(db, 'receipts');
      const q = query(
        receiptsRef, 
        where('productionId', '==', user.productionId),
        where('type', '==', 'Meal')
      );
      const querySnapshot = await getDocs(q);
      
      const receiptData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setReceipts(receiptData);
    } catch (err) {
      console.error("Error fetching meal receipts:", err);
      setError("Failed to load meal receipts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitReceipt = async (receiptData) => {
    // Implement the logic to submit a new receipt
    console.log("Submitting receipt:", receiptData);
    // After submission, refresh the receipts list
    await fetchMealReceipts();
  };

  if (loading) return <div>Loading meal receipts...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="meal-receipts">
      <h2>Meal Receipts</h2>
      <button onClick={() => setShowSubmitPopup(true)}>Submit New Receipt</button>
      {receipts.length === 0 ? (
        <p>No meal receipts submitted yet.</p>
      ) : (
        <div className="receipt-list">
          {receipts.map(receipt => (
            <div key={receipt.id} className="receipt-item">
              <img src={receipt.imageUrl} alt="Receipt" className="receipt-image" />
              <p><strong>Description:</strong> {receipt.description}</p>
              <p><strong>Submitted by:</strong> {receipt.submittedBy}</p>
              <p><strong>Date:</strong> {receipt.submissionDate.toDate().toLocaleString()}</p>
            </div>
          ))}
        </div>
      )}
      {showSubmitPopup && (
        <ReceiptSubmissionPopup
          type="Meal"
          onClose={() => setShowSubmitPopup(false)}
          onSubmit={handleSubmitReceipt}
        />
      )}
    </div>
  );
}

export default MealReceipts;
