// Role utility functions for checking user roles and permissions

export const isAdmin = (role) => {
    return role === 'admin';
};

export const isProductionAdmin = (role) => {
    return role === 'production_admin';
};

export const hasAdminAccess = (role) => {
    return isAdmin(role) || isProductionAdmin(role);
};
