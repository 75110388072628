export const baseDepartments = [
  { name: 'Script Supervisor' },
  { name: 'Sound' },
  { name: 'Costume' },
  { name: 'Medic' },
  { name: 'Craft Services' },
  { name: 'Stunts' },
  { name: 'Casting' },
  { name: 'Electric' },
  { name: 'Studio Teacher' },
  { name: 'Production' },
  { name: 'Assistant Directors' },
  { name: 'Transportation' },
  { name: 'Construction' },
  { name: 'Make-up' },
  { name: 'Directors' },
  { name: 'Video' },
  { name: 'Production company' },
  { name: 'Visual Effects' },
  { name: 'Producers & Writers' },
  { name: 'Special Effects' },
  { name: 'Studio' },
  { name: 'Camera' },
  { name: 'Grip' },
  { name: 'Locations' },
  { name: 'Accounting' },
  { name: 'Facilities' },
  { name: 'Property' },
  { name: 'Set Decoration' },
  { name: 'Post Production' },
  { name: 'Catering' },
  { name: 'Greens' },
  { name: 'Hair' },
  { name: 'Art Department' },
  { name: 'Production Design' }
];
