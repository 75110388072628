export const ROLES = {
    SUPER_ADMIN: 'superAdmin',
    ADMIN: 'admin',
    USER: 'user',
};

export const PERMISSIONS = {
    CREATE_PRODUCTION: 'createProduction',
    MANAGE_USERS: 'manageUsers',
    PROMOTE_TO_ADMIN: 'promoteToAdmin',
    PROMOTE_TO_SUPER_ADMIN: 'promoteToSuperAdmin',
};

// Define which roles have which permissions
export const ROLE_PERMISSIONS = {
    [ROLES.SUPER_ADMIN]: [
        PERMISSIONS.CREATE_PRODUCTION,
        PERMISSIONS.MANAGE_USERS,
        PERMISSIONS.PROMOTE_TO_ADMIN,
        PERMISSIONS.PROMOTE_TO_SUPER_ADMIN,
    ],
    [ROLES.ADMIN]: [
        PERMISSIONS.CREATE_PRODUCTION,
        PERMISSIONS.MANAGE_USERS,
        PERMISSIONS.PROMOTE_TO_ADMIN,
    ],
    [ROLES.USER]: [],
};
