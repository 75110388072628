import React, { useState, useEffect } from 'react';
import './RunRequestForm.css';
import { db, storage } from '../firebase';
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';

const RunRequestForm = ({ isOpen, onClose, departments }) => {
  const [formData, setFormData] = useState({
    name: '',
    department: '',
    pickupDate: '',
    pickupTime: '',
    streetNumber: '',
    streetName: '',
    suite: '',
    city: '',
    state: '',
    zipCode: '',
    description: '',
    photos: [],
    requestType: 'pickup' // Default to pickup
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      department: ''
    }));
  }, [departments]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prevState => ({
      ...prevState,
      photos: files
    }));
  };

  const uploadPhotos = async (photos) => {
    const uploadPromises = photos.map(async (photo) => {
      const storageRef = ref(storage, `run-requests/${Date.now()}-${photo.name}`);
      await uploadBytes(storageRef, photo);
      return getDownloadURL(storageRef);
    });
    return Promise.all(uploadPromises);
  };

  const createNotification = async (runRequestId, supervisors) => {
    const notificationsRef = collection(db, 'notifications');
    const notificationPromises = supervisors.map(supervisor => 
      addDoc(notificationsRef, {
        userId: supervisor.id,
        type: 'runRequest',
        title: 'New Run Request',
        message: `New ${formData.requestType} request from ${formData.name} (${formData.department})`,
        runRequestId: runRequestId,
        createdAt: serverTimestamp(),
        read: false
      })
    );
    await Promise.all(notificationPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      // Check for required fields
      if (!user || !user.uid) {
        console.error('No user found:', user);
        throw new Error('Please log in to submit a run request');
      }

      if (!user.production) {
        console.error('No production found for user:', user);
        throw new Error('No production found. Please make sure you are assigned to a production.');
      }

      // Create the run request object
      const runRequest = {
        name: formData.name,
        department: formData.department,
        requestType: formData.requestType,
        [formData.requestType]: {
          date: formData.pickupDate,
          time: formData.pickupTime || null,
          address: {
            streetNumber: formData.streetNumber,
            streetName: formData.streetName,
            suite: formData.suite || null,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode
          }
        },
        description: formData.description || null,
        status: 'pending',
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        production: user.production,
        photoUrls: []
      };

      console.log('Run request object:', runRequest);

      // Upload photos if any
      if (formData.photos.length > 0) {
        try {
          const photoUrls = await uploadPhotos(formData.photos);
          runRequest.photoUrls = photoUrls;
        } catch (photoError) {
          console.error('Error uploading photos:', photoError);
          // Continue with submission even if photos fail
          runRequest.photoUrls = [];
        }
      }

      // Save to Firestore
      const runRequestRef = await addDoc(collection(db, 'runRequests'), runRequest);
      console.log('Run request created with ID:', runRequestRef.id);

      // Get supervisors
      const usersRef = collection(db, 'users');
      const supervisorQuery = query(
        usersRef, 
        where('role', '==', 'Supervisor')
      );
      const supervisorSnapshot = await getDocs(supervisorQuery);
      const supervisors = supervisorSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Create notifications for supervisors
      if (supervisors.length > 0) {
        try {
          await createNotification(runRequestRef.id, supervisors);
        } catch (notificationError) {
          console.error('Error creating notifications:', notificationError);
          // Continue even if notifications fail
        }
      }

      // Reset form and close modal
      setFormData({
        name: '',
        department: '',
        pickupDate: '',
        pickupTime: '',
        streetNumber: '',
        streetName: '',
        suite: '',
        city: '',
        state: '',
        zipCode: '',
        description: '',
        photos: [],
        requestType: 'pickup'
      });
      onClose();
    } catch (error) {
      console.error('Error submitting run request:', error);
      setSubmitError(error.message || 'Failed to submit run request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Submit Run Request</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        {submitError && (
          <div className="error-message">
            {submitError}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name*</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="department">Select Department*</label>
            <select
              id="department"
              name="department"
              value={formData.department}
              onChange={handleChange}
              required
            >
              <option value="">Select a department</option>
              {departments && departments.sort((a, b) => a.name.localeCompare(b.name)).map((dept) => (
                <option key={dept.id} value={dept.name}>
                  {dept.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-section">
            <div className="form-group">
              <label htmlFor="requestType">Request Type*</label>
              <select
                id="requestType"
                name="requestType"
                value={formData.requestType}
                onChange={handleChange}
                required
              >
                <option value="pickup">Pickup</option>
                <option value="dropoff">Drop Off</option>
              </select>
            </div>
            <h3>{formData.requestType === 'pickup' ? 'Pickup' : 'Drop Off'} Details</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="pickupDate">Date (MM/DD/YYYY)*</label>
                <input
                  type="date"
                  id="pickupDate"
                  name="pickupDate"
                  value={formData.pickupDate}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="pickupTime">Time (HH:MM AM/PM) (Optional)</label>
                <input
                  type="time"
                  id="pickupTime"
                  name="pickupTime"
                  value={formData.pickupTime}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Address*</label>
              <div className="address-fields">
                <input
                  type="text"
                  name="streetNumber"
                  placeholder="Street Number*"
                  value={formData.streetNumber}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="streetName"
                  placeholder="Street Name*"
                  value={formData.streetName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="suite"
                  placeholder="Suite (optional)"
                  value={formData.suite}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City*"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="state"
                  placeholder="State*"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="zipCode"
                  placeholder="ZIP Code*"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description">Description of Items (Optional)</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter description here..."
            />
          </div>

          <div className="form-group">
            <label htmlFor="photos">Add Photos (Optional)</label>
            <input
              type="file"
              id="photos"
              name="photos"
              accept="image/*"
              multiple
              onChange={handlePhotoChange}
            />
          </div>

          <div className="form-actions">
            <button 
              type="button" 
              className="cancel-button" 
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Request'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RunRequestForm;
