import { db } from '../firebase';
import { collection, addDoc, updateDoc, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { ROLES, PERMISSIONS, ROLE_PERMISSIONS } from '../constants/roles';

// Check if user has permission
const hasPermission = (userRole, permission) => {
    const permissions = ROLE_PERMISSIONS[userRole] || [];
    return permissions.includes(permission);
};

export const createProduction = async (userId, productionData) => {
    try {
        // Get user's role
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        
        if (!userSnap.exists()) {
            throw new Error('User not found');
        }

        const userData = userSnap.data();
        
        // Check if user has permission to create production
        if (!hasPermission(userData.role, PERMISSIONS.CREATE_PRODUCTION)) {
            throw new Error('User does not have permission to create productions');
        }

        // Add production to Firestore
        const productionRef = await addDoc(collection(db, 'productions'), {
            ...productionData,
            createdBy: userId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            status: 'active'
        });

        console.log('Production created successfully:', productionRef.id);
        return productionRef.id;
    } catch (error) {
        console.error('Error creating production:', error);
        throw error;
    }
};

export const promoteUser = async (adminId, targetUserId, newRole) => {
    try {
        // Get admin's role
        const adminRef = doc(db, 'users', adminId);
        const adminSnap = await getDoc(adminRef);
        
        if (!adminSnap.exists()) {
            throw new Error('Admin user not found');
        }

        const adminData = adminSnap.data();
        
        // Check permissions based on the new role
        const requiredPermission = newRole === ROLES.SUPER_ADMIN 
            ? PERMISSIONS.PROMOTE_TO_SUPER_ADMIN 
            : PERMISSIONS.PROMOTE_TO_ADMIN;

        if (!hasPermission(adminData.role, requiredPermission)) {
            throw new Error(`User does not have permission to promote to ${newRole}`);
        }

        // Update target user's role
        const targetUserRef = doc(db, 'users', targetUserId);
        const targetUserSnap = await getDoc(targetUserRef);
        
        if (!targetUserSnap.exists()) {
            throw new Error('Target user not found');
        }

        await updateDoc(targetUserRef, {
            role: newRole,
            updatedAt: new Date().toISOString(),
            promotedBy: adminId,
            promotedAt: new Date().toISOString()
        });

        console.log(`User ${targetUserId} promoted to ${newRole} by ${adminId}`);
        return true;
    } catch (error) {
        console.error('Error promoting user:', error);
        throw error;
    }
};
