import React, { useState, useEffect, useContext } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import './Messages.css';
import '../styles/UnderConstruction.css';

function Messages() {
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('allChats'); // allChats, groups, contacts
  const [conversations, setConversations] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showOverlay, setShowOverlay] = useState(true);

  return (
    <div className="messages-container dark-theme">
      {/* Under construction overlay with close button */}
      {showOverlay && (
        <div className="under-construction-container" style={{
          position: 'absolute',
          top: 0,
          left: '240px',
          right: 0,
          bottom: 0,
          zIndex: 1000,
          backgroundColor: 'rgba(240, 240, 240, 0.95)'
        }}>
          <div className="under-construction-content">
            <button 
              className="close-button"
              onClick={() => setShowOverlay(false)}
              aria-label="Close"
            >
              <i className="fas fa-times"></i>
            </button>
            <h1>Under Construction</h1>
            <i className="fas fa-hard-hat construction-icon"></i>
            <p>We're working hard to bring you our messaging feature. Please check back soon!</p>
          </div>
        </div>
      )}

      {/* Left Navigation Bar */}
      <div className="nav-sidebar">
        <div className="app-logo">
          <img src="/your-logo.png" alt="Logo" />
        </div>
        <nav>
          <button className="nav-button active">
            <i className="fas fa-home"></i>
          </button>
          <button className="nav-button">
            <i className="fas fa-search"></i>
          </button>
          <button className="nav-button">
            <i className="fas fa-bookmark"></i>
          </button>
          <button className="nav-button">
            <i className="fas fa-share-alt"></i>
          </button>
          <button className="nav-button">
            <i className="fas fa-cog"></i>
          </button>
        </nav>
        <div className="theme-toggle">
          <button className="nav-button">
            <i className="fas fa-moon"></i>
          </button>
        </div>
      </div>

      {/* Chat List Sidebar */}
      <div className="chat-sidebar">
        <div className="chat-header">
          <h2>Message</h2>
          <div className="search-bar">
            <i className="fas fa-search"></i>
            <input type="text" placeholder="Search..." />
          </div>
          <div className="chat-tabs">
            <button 
              className={activeTab === 'allChats' ? 'active' : ''}
              onClick={() => setActiveTab('allChats')}
            >
              All Chats
            </button>
            <button 
              className={activeTab === 'groups' ? 'active' : ''}
              onClick={() => setActiveTab('groups')}
            >
              Groups
            </button>
            <button 
              className={activeTab === 'contacts' ? 'active' : ''}
              onClick={() => setActiveTab('contacts')}
            >
              Contacts
            </button>
          </div>
        </div>

        {/* Calls Section */}
        <div className="calls-section">
          <div className="section-header">
            <h3>Calls</h3>
            <button className="new-meet-btn">
              <i className="fas fa-plus"></i> New Meet
            </button>
          </div>
          <div className="call-list">
            {/* Call items go here */}
          </div>
        </div>

        {/* Chat List */}
        <div className="chat-list">
          {conversations.map(chat => (
            <div 
              key={chat.id} 
              className={`chat-item ${selectedChat?.id === chat.id ? 'active' : ''}`}
              onClick={() => setSelectedChat(chat)}
            >
              <img src={chat.avatar} alt={chat.name} className="chat-avatar" />
              <div className="chat-info">
                <h4>{chat.name}</h4>
                <p className="last-message">
                  {chat.typing ? (
                    <span className="typing">Typing...</span>
                  ) : (
                    chat.lastMessage
                  )}
                </p>
              </div>
              <div className="chat-meta">
                <span className="time">{chat.lastMessageTime}</span>
                {chat.unreadCount > 0 && (
                  <span className="unread-count">{chat.unreadCount}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="chat-main">
        {selectedChat ? (
          <>
            <div className="chat-header">
              <div className="chat-info">
                <img src={selectedChat.avatar} alt={selectedChat.name} />
                <div>
                  <h3>{selectedChat.name}</h3>
                  <span className="online-status">Online</span>
                </div>
              </div>
              <div className="chat-actions">
                <button><i className="fas fa-video"></i></button>
                <button><i className="fas fa-phone"></i></button>
              </div>
            </div>

            <div className="messages-area">
              {messages.map(message => (
                <div 
                  key={message.id}
                  className={`message ${message.senderId === user.uid ? 'sent' : 'received'}`}
                >
                  <div className="message-content">
                    <p>{message.text}</p>
                    <span className="message-time">
                      {new Date(message.timestamp?.toDate()).toLocaleTimeString([], { 
                        hour: '2-digit', 
                        minute: '2-digit' 
                      })}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="message-input-area">
              <button className="emoji-btn">
                <i className="far fa-smile"></i>
              </button>
              <input
                type="text"
                placeholder="Message........"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div className="input-actions">
                <button><i className="fas fa-paperclip"></i></button>
                <button><i className="fas fa-image"></i></button>
                <button><i className="fas fa-microphone"></i></button>
              </div>
            </div>
          </>
        ) : (
          <div className="no-chat-selected">
            <h3>Select a conversation to start messaging</h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default Messages;