import React, { useState } from 'react';
import '../styles/WaitlistPopup.css';

function WaitlistPopup({ isOpen, onClose, onSubmit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [production, setProduction] = useState('');
  const [company, setCompany] = useState('');
  const [department, setDepartment] = useState('');
  const [isPreviewUser, setIsPreviewUser] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, email, production, company, department, isPreviewUser });
  };

  if (!isOpen) return null;

  return (
    <div className="waitlist-popup-overlay">
      <div className="waitlist-popup">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Join Our Waitlist</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name *"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Production Name (Optional)"
            value={production}
            onChange={(e) => setProduction(e.target.value)}
          />
          <input
            type="text"
            placeholder="Production Company/Studio"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <input
            type="text"
            placeholder="Department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />
          <label>
            <input
              type="checkbox"
              checked={isPreviewUser}
              onChange={(e) => setIsPreviewUser(e.target.checked)}
            />
            Request 2-day preview access
          </label>
          <button type="submit">Join Waitlist</button>
        </form>
      </div>
    </div>
  );
}

export default WaitlistPopup;