import React, { useState } from 'react';
import './ReceiptSubmissionPopup.css';

function ReceiptSubmissionPopup({ type, onClose, onSubmit }) {
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ type, image, description });
    onClose();
  };

  return (
    <div className="receipt-popup-overlay">
      <div className="receipt-popup">
        <h2>{type} Receipt Submission</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="receipt-image">Upload Receipt Image:</label>
            <input
              type="file"
              id="receipt-image"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="receipt-description">Description:</label>
            <textarea
              id="receipt-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="button-group">
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReceiptSubmissionPopup;
