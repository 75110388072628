import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const SuperAdminRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  if (!user || user.role !== 'superAdmin') {
    return <Navigate to="/access-denied" replace />;
  }

  return children;
};

export default SuperAdminRoute;