import React, { useState } from 'react';
import { setUserRole } from '../services/userRoles';

function UserRoleManager() {
  const [uid, setUid] = useState('');
  const [role, setRole] = useState('');
  const [productionId, setProductionId] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await setUserRole(uid, role, productionId, departmentId);
      setMessage(result.message);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Manage User Roles</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={uid}
          onChange={(e) => setUid(e.target.value)}
          placeholder="User ID"
          required
        />
        <select value={role} onChange={(e) => setRole(e.target.value)} required>
          <option value="">Select Role</option>
          <option value="user">User</option>
          <option value="supervisor">Supervisor</option>
          <option value="admin">Admin</option>
          <option value="superAdmin">Super Admin</option>
        </select>
        {(role === 'admin' || role === 'supervisor') && (
          <input
            type="text"
            value={productionId}
            onChange={(e) => setProductionId(e.target.value)}
            placeholder="Production ID"
            required
          />
        )}
        {role === 'supervisor' && (
          <input
            type="text"
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}
            placeholder="Department ID"
            required
          />
        )}
        <button type="submit">Set Role</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default UserRoleManager;