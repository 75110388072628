import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Budget.css';

function Budget() {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalCost, setTotalCost] = useState(0);
  const [departmentMap, setDepartmentMap] = useState({});

  useEffect(() => {
    if (user) {
      fetchVehicles();
      fetchDepartments();
    }
  }, [user, startDate, endDate]);

  const fetchDepartments = async () => {
    try {
      const departmentsSnapshot = await getDocs(collection(db, 'departments'));
      const deptMap = {};
      departmentsSnapshot.forEach(doc => {
        deptMap[doc.id] = doc.data().name;
      });
      setDepartmentMap(deptMap);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchVehicles = async () => {
    try {
      // Query vehicles for current production
      const vehiclesQuery = query(
        collection(db, 'vehicles'),
        where('productionId', '==', user.production)
      );
      const vehiclesSnapshot = await getDocs(vehiclesQuery);
      const vehiclesData = vehiclesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Get vendors to display vendor names
      const vendorsQuery = query(collection(db, 'vendors'));
      const vendorsSnapshot = await getDocs(vendorsQuery);
      const vendorsMap = {};
      vendorsSnapshot.docs.forEach(doc => {
        vendorsMap[doc.id] = doc.data().name;
      });

      // Calculate costs for each vehicle and add vendor names
      const vehiclesWithCosts = vehiclesData.map(vehicle => {
        const cost = calculateVehicleCost(vehicle);
        return {
          ...vehicle,
          cost,
          vendor: vehicle.vendorId ? vendorsMap[vehicle.vendorId] : 'Not assigned'
        };
      });

      console.log('Fetched vehicles:', vehiclesWithCosts); // Debug log
      setVehicles(vehiclesWithCosts);
      calculateTotalCost(vehiclesWithCosts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setLoading(false);
    }
  };

  const calculateVehicleCost = (vehicle) => {
    if (!vehicle.rentalRate || !vehicle.rentalStart || !vehicle.rentalEnd) {
      return 0;
    }

    const start = vehicle.rentalStart.toDate();
    const end = vehicle.rentalEnd.toDate();
    const rate = parseFloat(vehicle.rentalRate);
    const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    switch (vehicle.rentalPeriod) {
      case 'daily':
        return rate * days;
      case 'weekly':
        return rate * Math.ceil(days / 7);
      case 'monthly':
        return rate * Math.ceil(days / 30);
      default:
        return 0;
    }
  };

  const calculateTotalCost = (vehiclesList) => {
    const total = vehiclesList.reduce((sum, vehicle) => sum + vehicle.cost, 0);
    setTotalCost(total);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="budget-container">
      <Sidebar />
      <div className="budget-content">
        <header className="page-header">
          <h1>Budget Overview</h1>
          <div className="report-header">
            <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
          </div>
        </header>

        <div className="date-range-selector">
          <div className="date-picker-group">
            <label>Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="date-picker-group">
            <label>End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>

        <div className="budget-summary">
          <div className="summary-card">
            <h3>Total Vehicle Costs</h3>
            <p className="total-amount">${totalCost.toFixed(2)}</p>
          </div>
        </div>

        <div className="budget-details">
          <h2>Vehicle Rental Details</h2>
          <table className="budget-table">
            <thead>
              <tr>
                <th>Vehicle</th>
                <th>Vendor</th>
                <th>Rate</th>
                <th>Period</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              {vehicles.map(vehicle => (
                <tr key={vehicle.id}>
                  <td>{`${vehicle.make} ${vehicle.model} (${vehicle.year})`}</td>
                  <td>{vehicle.vendor || 'Not assigned'}</td>
                  <td>${vehicle.rentalRate || '0'}</td>
                  <td>{vehicle.rentalPeriod || 'N/A'}</td>
                  <td>{vehicle.rentalStart ? vehicle.rentalStart.toDate().toLocaleDateString() : 'Not set'}</td>
                  <td>{vehicle.rentalEnd ? vehicle.rentalEnd.toDate().toLocaleDateString() : 'Not set'}</td>
                  <td>${vehicle.cost.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="6" className="total-label">Total</td>
                <td className="total-amount">${totalCost.toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Budget; 