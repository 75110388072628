import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signUp } from '../services/auth';
import { updateUserDocument } from '../services/firestore';
import { AuthContext } from '../contexts/AuthContext';
import '../styles/AuthPages.css';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    firstName: '',
    lastName: '',
    department: '',
    production: '',
    feedback: ''
  });
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // Redirect if user is already logged in
  if (user) {
    navigate('/welcome');
  }

  console.log("User from AuthContext in Signup:", user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      console.log("Step 1: Starting initial signup process...");
      const result = await signUp(email, password, {
        waitlisted: true,
        // Only send minimal data for initial signup
      });
      console.log("Step 1: Initial signup successful, user created with role:", result.user);
      
      // Show additional info popup
      setShowAdditionalInfo(true);
    } catch (error) {
      console.error("Signup error:", error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please use a different email or try logging in.');
      } else if (error.code === 'auth/invalid-email') {
        setError('The email address is not valid.');
      } else if (error.code === 'auth/weak-password') {
        setError('The password is too weak. Please use a stronger password.');
      } else {
        setError('Failed to create an account. Please try again.');
      }
      setLoading(false);
    }
  };

  const handleAdditionalInfo = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Step 2: Updating user document with additional info...");
      await updateUserDocument(user.uid, {
        ...additionalInfo,
        updatedAt: new Date().toISOString()
      });
      console.log("Step 2: User document updated successfully with additional info");
      navigate('/welcome');
    } catch (error) {
      console.error("Error updating additional info:", error);
      setError('Failed to update additional information. Please try again.');
      setLoading(false);
    }
  };

  if (showAdditionalInfo) {
    return (
      <div className="auth-container">
        <div className="auth-form-container">
          <h2>Additional Information</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleAdditionalInfo}>
            <input
              type="text"
              placeholder="First Name"
              value={additionalInfo.firstName}
              onChange={(e) => setAdditionalInfo({...additionalInfo, firstName: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              value={additionalInfo.lastName}
              onChange={(e) => setAdditionalInfo({...additionalInfo, lastName: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Department"
              value={additionalInfo.department}
              onChange={(e) => setAdditionalInfo({...additionalInfo, department: e.target.value})}
              required
            />
            <input
              type="text"
              placeholder="Production"
              value={additionalInfo.production}
              onChange={(e) => setAdditionalInfo({...additionalInfo, production: e.target.value})}
              required
            />
            <textarea
              placeholder="Feedback"
              value={additionalInfo.feedback}
              onChange={(e) => setAdditionalInfo({...additionalInfo, feedback: e.target.value})}
            ></textarea>
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Updating...' : 'Complete Signup'}
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-form-container">
        <Link to="/" className="close-button">&times;</Link>
        <h2>Sign Up</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="auth-button" disabled={loading}>
            {loading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </form>
        <p className="auth-link">
          Already have an account? <Link to="/login">Log in</Link>
        </p>
      </div>
    </div>
  );
}

export default Signup;
